<template>
    <div>
        <HeaderBar />

        <main class="main">
            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">

                            <div class="table-responsive">
                                <template v-if="isLoadingManifest">
                                    Loading manifest...
                                </template>

                                <template v-else-if="!manifest || Object.keys(manifest).length === 0">
                                    No manifest found with code {{ manifestCode }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Manifest</th>
                                                <th>Manifest ID</th>
                                                <th>Destination</th>
                                                <th>Parcels</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ manifest.MANIFEST_CODE }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.ID }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.carrierDestinationLocation?.NAME ?? '-' }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.parcelManifestRows.length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <template v-if="isMergingManifest">
                                                                <tr>
                                                                    <td colspan="6" class="pt-5 pb-3">
                                                                        Merging manifests...
                                                                    </td>
                                                                </tr>
                                                            </template>

                                                            <template v-else>
                                                                <tr>
                                                                    <td colspan="6" class="pt-5 pb-3">
                                                                        <template v-if="manifest.IS_READY_TO_SHIP == 1">
                                                                            This manifest is ready to ship and can't be merged.
                                                                        </template>

                                                                        <template v-else-if="isLoadingAvailableTargetManifests">
                                                                            Loading available manifests...
                                                                        </template>

                                                                        <template v-else-if="availableTargetManifests.length === 0">
                                                                            There are no available manifests to merge with.
                                                                        </template>

                                                                        <template v-else>
                                                                            <p>
                                                                                Select a manifest to merge with
                                                                            </p>

                                                                            <model-list-select
                                                                                    :list="availableTargetManifests"
                                                                                    class="target-manifest-list"
                                                                                    option-value="value"
                                                                                    option-text="label"
                                                                                    v-model="targetManifestId"
                                                                                    required
                                                                            >
                                                                            </model-list-select>
                                                                        </template>
                                                                    </td>
                                                                </tr>

                                                                <template v-if="targetManifestId">
                                                                    <tr>
                                                                        <td colspan="6" class="pt-3 pb-0">
                                                                            If all parcels are merged, this manifest ({{ manifest.MANIFEST_CODE }}) will be deleted.
                                                                        </td>
                                                                    </tr>

                                                                    <template v-if="!manifest.parcelManifestRows || manifest.parcelManifestRows.length === 0">
                                                                        <tr>
                                                                            <td colspan="6" class="pb-3">
                                                                                No parcels added to this manifest.
                                                                            </td>
                                                                        </tr>
                                                                    </template>

                                                                    <template v-else>
                                                                        <tr>
                                                                            <td colspan="6" class="pb-3">
                                                                                <label class="checkbox-container mb-0 ml-4"
                                                                                       @click.prevent="toggleAllCheckboxes()">
                                                                                    Select all

                                                                                    <input type="checkbox" v-model="selectedAll">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td colspan="6" class="pt-0">
                                                                                <table class="table-third-level">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th class="w-20 pr-0"></th>

                                                                                            <th class="w-auto pl-0">
                                                                                                Parcel
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody>
                                                                                        <tr v-for="(manifestRow, key) in manifest.parcelManifestRows"
                                                                                            :key="key"
                                                                                        >
                                                                                            <td class="w-20 pr-0">
                                                                                                <label class="checkbox-container mb-20 mr-1">
                                                                                                    <input type="checkbox"
                                                                                                           v-model="selectedManifestRows[manifestRow.ID]">
                                                                                                    <span class="checkmark"></span>
                                                                                                </label>
                                                                                            </td>

                                                                                            <td class="w-auto pl-0">
                                                                                                {{ manifestRow.PARCEL_REFERENCE }}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td colspan="6" class="pt-0">
                                                                                <InputFieldError attribute="global" :errors="errors"/>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <router-link :to="{name: 'ShippingManifest', query: {code: manifestCode}}" class="btn btn-secondary">
                                    Back
                                </router-link>

                                <button
                                    type="button" class="btn btn-primary" @click.prevent="mergeManifest()"
                                    v-if="targetManifestId && manifest.parcelManifestRows?.length > 0"
                                    :disabled="isMergingManifest"
                                >
                                    Merge
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                errors: {},
                isLoadingManifest: false,
                isLoadingAvailableTargetManifests: false,
                isDeletingManifest: false,
                isMergingManifest: false,
                manifestCode: this.$route.query.code,
                manifest: null,
                targetManifestId: null,
                selectedLocation: null,
                showDeleteManifestPopup: false,
                selectedManifestRows: {},
                availableTargetManifests: [],
            };
        },
        created() {
            this.isLoadingManifest = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;
                }

                this.getManifest();
            });
        },
        methods: {
            getManifest() {
                this.isLoadingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/get-parcel-manifest');
                url.searchParams.append('manifestCode', this.manifestCode);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data?.data;

                    /* Pre-populate selected manifest rows */
                    _.forOwn(this.manifest.parcelManifestRows, (manifestRow) => {
                        this.$set(this.selectedManifestRows, manifestRow.ID, false);
                    });

                    this.getAvailableTargetManifests();
                });
            },
            getAvailableTargetManifests() {
                this.isLoadingAvailableTargetManifests = true;

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/get-all');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingAvailableTargetManifests = false;

                    if (data.data) {
                        _.forOwn(data.data, (manifest) => {
                            if (this.manifest.SORTING_TYPE == manifest.SORTING_TYPE && this.manifest.ID != manifest.ID && manifest.IS_READY_TO_SHIP == 0 && this.manifest.DESTINATION_CARRIER_LOCATION_ID == manifest.DESTINATION_CARRIER_LOCATION_ID) {
                                this.availableTargetManifests.push({value: parseInt(manifest.ID), label: manifest.MANIFEST_CODE});
                            }
                        });
                    }
                });
            },
            deleteManifest() {
                this.errors = {};
                this.isDeletingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/delete-manifest');
                const params = new URLSearchParams();

                params.append('ParcelManifest[ID]', this.manifest.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingManifest = false;
                    this.showDeleteManifestPopup = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestDelete: data.data.errors};
                    } else {
                        this.$router.push({
                            name: 'ShippingManifestOverview',
                        });
                    }
                });
            },
            toggleAllCheckboxes() {
                if (this.manifest.parcelManifestRows) {
                    let selectedAll = this.selectedAll;

                    _.forOwn(this.selectedManifestRows, (value, key) => {
                        this.$set(this.selectedManifestRows, key, !selectedAll);
                    });
                }
            },
            mergeManifest() {
                this.errors = {};
                this.isMergingManifest = true;
                let manifestRowIds = [];

                /* Collect the IDs of the ParcelManifestRows which should be updated */
                _.forOwn(this.selectedManifestRows, (selectedManifestRow, key) => {
                    if (selectedManifestRow) {
                        manifestRowIds.push(key);
                    }
                });

                if (!this.selectedAny) {
                    this.errors = {global: 'Select one or more parcels to merge'};
                    this.isMergingManifest = false;
                    return false;
                }

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/merge-manifest');
                const params = new URLSearchParams();
                params.append('ParcelManifest[CurrentManifestID]', this.manifest.ID);
                params.append('ParcelManifest[TargetManifestID]', this.targetManifestId);
                params.append('ParcelManifest[ParcelManifestRowIDs]', manifestRowIds);

                this.$http.post(url.href, params).then((data) => {
                    this.isMergingManifest = false;

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    } else if (data.data.code === 302) {
                        /* The manifest has been emptied and closed, redirect to the manifest overview */
                        this.$router.push({
                            name: 'ShippingManifestOverview',
                        });
                    } else {
                        /* Refresh the data */
                        this.manifest = data.data.manifest;
                        this.selectedManifestRows = {};

                        /* Pre-populate selected manifest rows */
                        _.forOwn(this.manifest.parcelManifestRows, (manifestRow) => {
                            this.$set(this.selectedManifestRows, manifestRow.ID, false);
                        });
                    }
                });
            }
        },
        computed: {
            /* Make sure the "Select all" checkbox is ticked when all parcels are selected. */
            selectedAll() {
                let count = 0;

                _.forOwn(this.selectedManifestRows, (selectedManifestRow) => {
                    if (selectedManifestRow) {
                        count++;
                    }
                });

                return count === Object.keys(this.selectedManifestRows).length;
            },
            selectedAny() {
                let count = 0;

                _.forOwn(this.selectedManifestRows, (selectedManifestRow) => {
                    if (selectedManifestRow) {
                        count++;
                    }
                });

                return count > 0;
            }
        },
        watch: {
            selectedManifestRows: {
                handler() {
                    /* Reset errors when the selected manifest rows changes. */
                    this.errors = {};
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    h5 {
        font-weight: 600;
    }

    .table-responsive {
        overflow: visible;
    }

    .target-manifest-list {
        max-width: 300px;
    }

    .label-title {
        padding-left: 0;
        font-weight: 600;
        font-size: 14px;
    }
</style>
