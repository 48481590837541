<template>
    <div class="card-item-wrapper">
        <router-link :to="route" class="card-item">
            <div class="card-item-img">
                <img :src="iconPath"
                     class="img-fluid"
                     alt=""
                >
            </div>

            <span class="d-block pt-4">
                {{ label }}
            </span>
        </router-link>
    </div>
</template>

<script>
export default {
    props: [
        'route',
        'iconPath',
        'label'
    ],
}
</script>
