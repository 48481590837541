<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <LoadingOverlay loadingMessage="Completing return" v-if="isSubmitting"/>

        <main class="main">
            <template v-if="isLoadingReturnData">
                <div class="container mt-5">
                    Loading return data...
                </div>
            </template>

            <template v-else-if="!receivedReturn">
                <div class="container mt-5">
                    Unable to retrieve return data.
                </div>
            </template>

            <form v-else action="">
                <div class="scrollable-area">
                    <div class="scrollable-area--header">
                        <div class="my-5" v-if="!manifestId"></div>

                        <div class="container" v-else>
                            <div class="col-12">
                                <div class="scrollable-area--subtitle">
                                    You're working on manifest
                                </div>

                                <div class="scrollable-area--title">
                                    {{ manifestCode }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="scrollable-content">
                        <div class="container">
                            <div class="table-responsive">
                                <table class="table-first-level">
                                    <thead>
                                        <tr>
                                            <th>Return</th>
                                            <th>Account</th>
                                            <th>Channel</th>
                                            <th>Return Policy</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colspan="4">
                                                <table class="table-second-level">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {{ receivedReturn.RETURN_REFERENCE }}
                                                            </th>

                                                            <th>
                                                                {{ receivedReturn.MERCHANT_ACCOUNT_NAME }}
                                                            </th>

                                                            <th>
                                                                {{ receivedReturn.PORTAL_NAME }}
                                                            </th>

                                                            <th>
                                                                {{ receivedReturn.MERCHANT_SETUP_NAME }}
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <template v-if="isLoadingProductData">
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">
                                                                    Loading product data...
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>

                                                    <template v-else-if="Object.keys(orderedProducts).length === 0">
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">
                                                                    There are no expected products.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>

                                                    <template v-else>
                                                        <tbody v-for="(products, orderNumber) in orderedProducts" :key="orderNumber">
                                                            <tr v-if="orderNumber != 'null' && orderNumber != 0">
                                                                <td colspan="5" class="pb-0">
                                                                    <div class="order-number d-flex align-items-center">
                                                                        <span class="img"></span>
                                                                        <div>
                                                                            <span class="label">Order number</span>

                                                                            <strong class="number">
                                                                                {{ orderNumber }}
                                                                            </strong>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="5">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Product</th>

                                                                                <th>Product group</th>

                                                                                <th>Status</th>

                                                                                <th>Expected</th>

                                                                                <th>Received</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody v-if="products && products.length > 0">
                                                                            <tr v-for="product in products" :key="product.PRODUCT_ID">
                                                                                <td>
                                                                                    {{ product.MODEL_NUMBER }}
                                                                                </td>

                                                                                <td>
                                                                                    <span v-if="product.articleGroup">
                                                                                        {{ product.articleGroup.RTN_ARP_NAME }}
                                                                                    </span>
                                                                                </td>

                                                                                <td>
                                                                                    <span class="status-bandge" :class="product.STATUS">
                                                                                        {{ product.STATUS }}
                                                                                    </span>
                                                                                </td>

                                                                                <td>
                                                                                    {{ product.QUANTITY_EXPECTED }}
                                                                                </td>

                                                                                <td>
                                                                                    {{ product.QUANTITY_RECEIVED }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </template>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="confirmCompleteReturn()">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>

        <div class="confirmation-overlay" v-if="showConfirmationModal">
            <div class="overlay-background"></div>

            <div class="modal-wrapper">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h5 class="py-4 my-3" v-html="confirmationModalText"></h5>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click.prevent="showConfirmationModal = false">
                            No
                        </button>

                        <button type="button" class="btn btn-success" @click.prevent="completeReturn()">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import LoadingOverlay from '@/components/_LoadingOverlay.vue';
    import _ from 'lodash';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'LoadingOverlay': LoadingOverlay,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                returnReference: this.$route.query.returnReference,
                manifestId: this.$route.query.manifestId,
                manifestCode: this.$route.query.manifestCode,
                manifestIsReadyToShip: this.$route.query.readyToShip,
                isLoadingReturnData: false,
                isLoadingProductData: false,
                receivedReturn: null,
                isSubmitting: false,
                errors: {},
                showConfirmationModal: false,
                confirmationModalText: null,
                inspectedAssets: null,
                orderedProducts: {},
            };
        },
        created() {
            this.isLoadingReturnData = true;
            this.isLoadingProductData = true;

            this.$store.dispatch('returns/getReceivedReturn', this.returnId).then((data) => {
                this.isLoadingReturnData = false;
                this.receivedReturn = data;
            });

            let url = new URL(this.$store.state.baseUrl + 'products/get-products-on-return');
            url.searchParams.set('returnId', this.returnId);

            this.$http.get(url.href).then((data) => {
                if (data.data.expectedProducts) {
                    _.forOwn(data.data.expectedProducts, (product) => {
                        if (!this.orderedProducts[product.ORDER_NUMBER]) {
                            this.orderedProducts[product.ORDER_NUMBER] = [];
                        }

                        this.orderedProducts[product.ORDER_NUMBER].push(product);
                    });
                }

                if (data.data.unexpectedProducts) {
                    _.forOwn(data.data.unexpectedProducts, (unexpectedProduct) => {
                        if (!this.orderedProducts[unexpectedProduct.ORDER_NUMBER]) {
                            this.orderedProducts[unexpectedProduct.ORDER_NUMBER] = [];
                        }

                        this.orderedProducts[unexpectedProduct.ORDER_NUMBER].push(unexpectedProduct);
                    });
                }

                this.isLoadingProductData = false;
            });
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'CheckReturnAssetOverview',
                    query: {returnId: this.returnId, returnReference: this.returnReference, manifestId: this.manifestId, manifestCode: this.manifestCode, readyToShip: this.manifestIsReadyToShip}
                });
            },
            confirmCompleteReturn() {
                this.confirmationModalText = 'Are you sure you want to finish receiving return ' + this.receivedReturn.RETURN_REFERENCE + '? This action cannot be reversed.';
                this.showConfirmationModal = true;
            },
            completeReturn() {
                /* Sorted Manifest - redirect to the sort to manifest page. */
                if (!this.manifestId) {
                    this.$router.push({
                        name: 'SortToManifest',
                        query: {returnReference: this.returnReference, preload: true}
                    });

                    return true;
                }

                /* Unsorted Manifest - add the return to the manifest. */
                this.errors = {};
                this.showConfirmationModal = false;
                this.isSubmitting = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifest/check-sort-return');
                let params = new URLSearchParams();
                params.append('ReturnManifest[RETURN_ID]', this.returnId);
                params.append('ReturnManifest[MANIFEST_ID]', this.manifestId);
                params.append('ReturnManifest[MANIFEST_IS_READY_TO_SHIP]', this.manifestIsReadyToShip);

                this.$http.post(url.href, params).then((data) => {
                    this.isSubmitting = false;

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    } else {
                        this.$store.commit('manifests/addReturnToManifest', [this.manifestCode, this.receivedReturn.RETURN_REFERENCE]);

                        if (this.manifestIsReadyToShip && (this.manifestIsReadyToShip === true || this.manifestIsReadyToShip === 'true')) {
                            this.$store.commit('manifests/setManifestReadyToShip', this.manifestCode);

                            this.$router.push({
                                name: 'ReceiveToManifest'
                            });
                        } else {
                            this.$router.push({
                                name: 'ReceiveToManifest',
                                query: {manifestId: this.manifestId}
                            });
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 180px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }
</style>
