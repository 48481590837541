import { render, staticRenderFns } from "./ShipManifest.vue?vue&type=template&id=0b934775&scoped=true"
import script from "./ShipManifest.vue?vue&type=script&lang=js"
export * from "./ShipManifest.vue?vue&type=script&lang=js"
import style0 from "./ShipManifest.vue?vue&type=style&index=0&id=0b934775&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b934775",
  null
  
)

export default component.exports