<template>
    <div>
        <HeaderBar title="Container"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <template v-if="isLoadingContainer">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="scan-manisfest-container">
                                    Loading container...
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content">
                            <div class="container">
                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>License plate</th>
                                                <th>Account</th>
                                                <th>Storage</th>
                                                <th>
                                                    <template v-if="container.STORAGE_TYPE === 'AFS'">
                                                        Disposition resolution
                                                    </template>

                                                    <template v-else>
                                                        Disposition type
                                                    </template>
                                                </th>
                                                <th>Assets</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ container.LICENSE_PLATE }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                        {{ container.RETURNBIRD_ACCOUNT_NAME | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ container.STORAGE_TYPE }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="container.STORAGE_TYPE === 'AFS'">
                                                                        <template v-if="container.DISPOSITION_RESOLUTION">
                                                                            {{ container.DISPOSITION_RESOLUTION | capitalize }}
                                                                        </template>

                                                                        <template v-else>
                                                                            -
                                                                        </template>
                                                                    </template>

                                                                    <template v-else>
                                                                        <template v-if="container.DISPOSITION_TYPE">
                                                                            {{ container.DISPOSITION_TYPE | capitalize }}
                                                                        </template>

                                                                        <template v-else>
                                                                            -
                                                                        </template>
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ container.assets.length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="9" class="pt-4 pb-0">
                                                                    <div class="d-flex align-items-center justify-content-between pb-3">
                                                                        <div>
                                                                            <button type="button" class="btn btn-danger sm" @click.prevent="showDeleteContainerPopup = true" v-if="!container.assets || Object.keys(container.assets).length === 0">
                                                                                Delete
                                                                            </button>

                                                                            <InputFieldError attribute="containerDelete" :errors="errors"/>
                                                                        </div>

                                                                        <div class="d-flex align-items-center justify-content-end pt-3">
                                                                            <span>
                                                                              Container Status
                                                                            </span>

                                                                            <div class="flipswitch ml-3">
                                                                                <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="fs" :checked="container.IS_FULL == 1" @click.prevent="toggleContainerFull()" :disabled="isSavingContainerStatus">

                                                                                <label class="flipswitch-label mb-0" for="fs">
                                                                                    <div class="flipswitch-inner"></div>
                                                                                    <div class="flipswitch-switch"></div>
                                                                                </label>
                                                                            </div>

                                                                            <InputFieldError attribute="containerStatus" :errors="errors"/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="9" class="pt-0" v-if="container.assets && Object.keys(container.assets).length > 0">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="w-auto">SKU</th>
                                                                                <th class="w-auto">License plate</th>
                                                                                <th class="w-auto">Received grade</th>
                                                                                <th class="w-auto" v-if="storage === 'AFS'">Revalued grade</th>
                                                                                <th class="w-auto">Received date</th>
                                                                                <th class="w-auto" colspan="2"></th>
                                                                                <th class="w-auto"></th>
                                                                                <th class="w-auto" v-if="canDispositionAssets"></th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="asset in container.assets" :key="asset.ID">
                                                                                <template v-if="assetsBeingMoved[asset.ID]">
                                                                                    <td colspan="9">
                                                                                        <div class="d-flex align-items-center justify-content-center">
                                                                                            Moving asset...
                                                                                        </div>
                                                                                    </td>
                                                                                </template>

                                                                                <template v-else>
                                                                                    <td class="w-auto">
                                                                                        <template v-if="asset.SKU">
                                                                                            {{ asset.SKU }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            -
                                                                                        </template>
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ asset.LICENSE_PLATE }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ asset.GRADE }}
                                                                                    </td>

                                                                                    <td class="w-auto" v-if="storage === 'AFS'">
                                                                                        {{ asset.REWORKED_GRADE }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ asset.CREATION | formatDate }}
                                                                                    </td>

                                                                                    <td class="w-auto" colspan="2">
                                                                                        <div class="d-flex align-items-center scan-licence-plate justify-content-end">
                                                                                            <input type="text" name="" value="" class="form-control" placeholder="Scan container" v-model="licensePlates[asset.ID]" @keyup.enter.prevent="moveAsset(asset.ID)">

                                                                                            <button type="button" class="btn btn-primary" @click.prevent="moveAsset(asset.ID)">
                                                                                                <span class="icon-transfer"></span>
                                                                                            </button>
                                                                                        </div>

                                                                                        <div class="d-flex align-items-center justify-content-end">
                                                                                            <InputFieldError :attribute="asset.ID" :errors="licensePlateErrors"/>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td class="w-auto px-0 text-center" :class="canDispositionAssets ? 'pr-0' : ''">
                                                                                        <button type="button" class="btn btn-primary px-3" @click.prevent="viewAsset(asset.ID)">
                                                                                            <span class="icon-search"></span>
                                                                                        </button>
                                                                                    </td>

                                                                                    <td class="w-auto" v-if="canDispositionAssets">
                                                                                        <button type="button" class="btn btn-primary px-3" @click.prevent="assetRework(asset.ID)">
                                                                                            <span class="icon-wrench"></span>
                                                                                        </button>
                                                                                    </td>
                                                                                </template>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td colspan="7" class="pt-0" v-else>
                                                                    No assets added to this container.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!-- scrollable-content -->
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>

        <ConfirmationPopup v-if="showDeleteContainerPopup"
                           :message="'Are you sure you want to delete container ' + container.LICENSE_PLATE + '?'"
                           :loadingMessage="'Deleting container ' + container.LICENSE_PLATE + '...'"
                           :isLoading="isDeletingContainer"
                           @confirm="deleteContainer()"
                           @deny="showDeleteContainerPopup = false"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import Vue from 'vue';
    import _ from 'lodash';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                isLoadingSubmodules: false,
                moduleAccess: {},
                canDispositionAssets: false,
                errors: {},
                storage: this.$route.params.storage,
                licensePlateErrors: {},
                isLoadingContainer: false,
                isSavingContainerStatus: false,
                containerId: this.$route.query.id,
                container: null,
                licensePlates: {},
                assetsBeingMoved: {},
                showDeleteContainerPopup: false,
                isDeletingContainer: false,
            };
        },
        mounted() {
            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingSubmodules = false;
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['return']?.['storage'];
                this.canDispositionAssets = typeof this.moduleAccess['asset-disposition'] !== 'undefined' && this.moduleAccess['asset-disposition'].ACCESS;
            });
        },
        created() {
            this.isLoadingContainer = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocationId = data.value;
                }

                this.getContainer();
            });
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'Container',
                    query: {id: this.containerId}
                });
            },
            getContainer() {
                this.isLoadingContainer = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/get-by-id');
                url.searchParams.append('locationId', this.selectedLocationId);
                url.searchParams.append('id', this.containerId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainer = false;
                    this.container = data.data;
                });
            },
            toggleContainerFull() {
                this.errors = {};
                this.isSavingContainerStatus = true;

                let newValue = this.container.IS_FULL == 1 ? 0 : 1;

                let url = new URL(this.$store.state.baseUrl + 'containers/' + this.container.ID);
                let params = new URLSearchParams();
                params.append('IS_FULL', newValue);

                this.$http.put(url.href, params).then((data) => {
                    this.isSavingContainerStatus = false;

                    if (data.status === 200) {
                        this.container.IS_FULL = newValue;
                    } else {
                        this.errors = {containerStatus: 'Unable to update container status.'};
                    }
                });
            },
            moveAsset(assetId) {
                this.licensePlateErrors = {};
                let targetContainerLicensePlate = this.licensePlates[assetId];

                if (!targetContainerLicensePlate) {
                    this.licensePlateErrors[assetId] = 'Enter a license plate.';
                    return false;
                } else if (targetContainerLicensePlate == this.container.LICENSE_PLATE) {
                    this.licensePlateErrors[assetId] = 'Select a different container.';
                    return false;
                }

                let url = new URL(this.$store.state.baseUrl + 'containers/move-asset');
                let params = new URLSearchParams();
                params.append('Container[assetId]', assetId);
                params.append('Container[targetContainerLicensePlate]', targetContainerLicensePlate);

                this.assetsBeingMoved[assetId] = true;

                this.$http.post(url.href, params).then((data) => {
                    Vue.delete(this.assetsBeingMoved, assetId);

                    if (data.data.code === 100) {
                        /* Remove the asset from the asset overview. */
                        this.container.assets = _.pickBy(this.container.assets, (asset) => {
                            return asset.ID != assetId;
                        });
                    } else {
                        this.licensePlateErrors[assetId] = data.data.errors;
                    }
                });
            },
            viewAsset(assetId) {
                this.$router.push({
                    name: 'Asset',
                    query: {id: assetId, containerId: this.container.ID}
                });
            },
            assetRework(assetId) {
                this.$router.push({
                    name: 'AssetRework',
                    query: {id: assetId, containerId: this.container.ID}
                });
            },
            deleteContainer() {
                this.errors = {};
                this.isDeletingContainer = true;

                const url = new URL(this.$store.state.baseUrl + 'containers/delete-container');
                const params = new URLSearchParams();

                params.append('Container[ID]', this.container.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingContainer = false;
                    this.showDeleteContainerPopup = false;

                    if (data.data.code === 422) {
                        this.errors = {containerDelete: data.data.errors};
                    } else {
                        this.$router.push({
                            name: 'ContainerOverview',
                        });
                    }
                });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                return day + '-' + month + '-' + year;
            }
        },
    }
</script>

<style scoped>
    input[type="text"] {
        background-color: #fff;
        min-width: 200px;
    }

    td button {
        height: 48px;
    }
</style>
