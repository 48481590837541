<template>
    <div>
        <HeaderBar />

        <main class="main">
            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">

                            <div class="table-responsive">
                                <template v-if="isLoadingManifest">
                                    Loading manifest...
                                </template>

                                <template v-else-if="!manifest || Object.keys(manifest).length === 0">
                                    No manifest found with code {{ manifestCode }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Manifest</th>
                                                <th>Manifest ID</th>
                                                <th>Destination</th>
                                                <th>Parcels</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ manifest.MANIFEST_CODE }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.ID }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.carrierDestinationLocation?.NAME ?? '-' }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.parcelManifestRows.length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5" class="pt-4 pb-0">
                                                                    <div
                                                                        class="d-flex align-items-center justify-content-between pt-3"
                                                                        v-if="!manifest.parcelManifestRows || Object.keys(manifest.parcelManifestRows).length === 0"
                                                                    >
                                                                        <div>
                                                                            <button
                                                                                type="button" class="btn btn-danger sm"
                                                                                @click.prevent="showDeleteManifestPopup = true"
                                                                            >
                                                                                Delete
                                                                            </button>

                                                                            <InputFieldError attribute="manifestDelete" :errors="errors"/>
                                                                        </div>
                                                                    </div>

                                                                    <div class="d-flex align-items-center justify-content-between pt-3" v-else>
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3"
                                                                            ref="btnMerge"
                                                                            :style="{width: btnWidth}"
                                                                            :disabled="manifestIsReadyToShip"
                                                                            @click.prevent="$router.push({name: 'ShippingMergeManifest', query: {code: manifest.ID}})"
                                                                            v-if="manifest.parcelManifestRows && Object.keys(manifest.parcelManifestRows).length > 0"
                                                                        >
                                                                            Merge
                                                                        </button>

                                                                        <div>
                                                                            <div class="d-flex justify-content-center">
                                                                                <div class="d-flex align-items-center justify-content-end">
                                                                                    <span>
                                                                                        Ready to ship
                                                                                    </span>

                                                                                    <label class="flipswitch ml-3">
                                                                                        <input type="checkbox"
                                                                                               name="flipswitch"
                                                                                               class="flipswitch-cb" id="readyToShip"
                                                                                               :checked="manifestIsReadyToShip"
                                                                                               @click.prevent="toggleManifestReadyToShip()"
                                                                                               :disabled="isSavingManifestReadyToShip">

                                                                                        <div class="flipswitch-label mb-0" for="readyToShip">
                                                                                            <div class="flipswitch-inner flipswitch-inner--ready-to-ship" :class="manifest.SHIPMENT_REFERENCE ? 'orange' : 'red'"></div>
                                                                                            <div class="flipswitch-switch"></div>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <InputFieldError attribute="manifestReadyToShipStatus" :errors="errors"/>
                                                                        </div>

                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3 ml-5"
                                                                            ref="btnManifestList"
                                                                            :style="{width: btnWidth}"
                                                                            :disabled="isGeneratingDocument"
                                                                            @click.prevent="getManifestListPdf()"
                                                                        >
                                                                            Manifest list
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="5" v-if="manifest.parcelManifestRows && Object.keys(manifest.parcelManifestRows).length > 0">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="w-auto">Parcel</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(manifestRow, key) in manifest.parcelManifestRows" :key="key">
                                                                                <td class="w-auto">
                                                                                    {{ manifestRow.PARCEL_REFERENCE }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td colspan="5" v-else>
                                                                    No parcels added to this manifest.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <router-link :to="{name: 'ShippingManifestOverview'}" class="btn btn-secondary">
                                    Back
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>

        <ConfirmationPopup
            :message="'Are you sure you want to delete manifest ' + manifest.MANIFEST_CODE + '?'"
            :loadingMessage="'Deleting manifest ' + manifest.MANIFEST_CODE + '...'"
            :isLoading="isDeletingManifest"
            @confirm="deleteManifest()"
            @deny="showDeleteManifestPopup = false"
            v-if="showDeleteManifestPopup"
        />
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        name: 'ManifestPage',
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                errors: {},
                isLoadingManifest: false,
                isDeletingManifest: false,
                manifestCode: this.$route.query.code,
                manifest: null,
                isSavingManifestReadyToShip: false,
                showDeleteManifestPopup: false,
                isGeneratingDocument: false,
                btnWidth: null,
            };
        },
        created() {
            this.getManifest();
        },
        methods: {
            getManifest() {
                this.isLoadingManifest = true;

                let url = new URL(this.$store.state.baseUrl + 'parcel-manifests/get-parcel-manifest');
                url.searchParams.append('manifestCode', this.manifestCode);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data?.data;

                    this.setButtonWidths();
                });
            },
            deleteManifest() {
                this.errors = {};
                this.isDeletingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/delete-manifest');
                const params = new URLSearchParams();

                params.append('ParcelManifest[ID]', this.manifest.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingManifest = false;
                    this.showDeleteManifestPopup = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestDelete: data.data.errors};
                    } else {
                        this.$router.push({
                            name: 'ShippingManifestOverview',
                        });
                    }
                });
            },
            toggleManifestReadyToShip() {
                if (this.isSavingManifestReadyToShip) {
                    return;
                }

                this.errors = {};
                this.isSavingManifestReadyToShip = true;

                const isReadyToShip = this.manifest.IS_READY_TO_SHIP == 1 ? 0 : 1;

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/set-manifest-ready-to-ship');
                const params = new URLSearchParams();

                params.append('ParcelManifest[ID]', this.manifest.ID);
                params.append('ParcelManifest[IS_READY_TO_SHIP]', isReadyToShip);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingManifestReadyToShip = false;

                    if (data.data.code === 100) {
                        this.manifest.IS_READY_TO_SHIP = isReadyToShip;
                    } else if (data.data.code === 422) {
                        this.errors = {manifestReadyToShipStatus: data.data.errors};
                    }
                });
            },
            getManifestListPdf() {
                this.errors = {};
                this.isGeneratingDocument = true;

                const url = new URL(this.$store.state.baseUrl + 'pdf/parcel-manifest');
                url.searchParams.append('id', this.manifest.ID);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    this.isGeneratingDocument = false;

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            setButtonWidths() {
                this.$nextTick(() => {
                    const btnMerge = this.$refs.btnMerge;
                    const btnManifestList = this.$refs.btnManifestList;

                    if (btnMerge && btnManifestList) {
                        const btnMergeWidth = parseFloat(getComputedStyle(btnMerge).width.replace('px', ''));
                        const btnManifestListWidth = parseFloat(getComputedStyle(btnManifestList).width.replace('px', ''));

                        let width = 0;
                        width = (btnMergeWidth > width) ? btnMergeWidth : width;
                        width = (btnManifestListWidth > width) ? btnManifestListWidth : width;
                        this.btnWidth = width + 'px';
                    }
                });
            }
        },
        computed: {
            manifestIsReadyToShip() {
                return this.manifest && this.manifest.IS_READY_TO_SHIP == 1;
            }
        },
    }
</script>

<style scoped>

</style>
