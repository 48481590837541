<template>
    <div>
        <HeaderBar title="Container"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <table class="table-first-level">
                                <thead>
                                    <tr>
                                        <th>Selected containers</th>
                                        <th>Shipping destination</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table-second-level">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ Object.keys(selectedContainers).length }}
                                                        </th>

                                                        <th>
                                                            <template v-if="!isLoadingShippingDestinations">
                                                                <model-list-select
                                                                        :list="shippingDestinations"
                                                                        option-value="RTN_LTN_ID"
                                                                        option-text="RTN_LTN_NAME"
                                                                        v-model="selectedShippingDestinationId"
                                                                        required
                                                                >
                                                                </model-list-select>
                                                            </template>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <template v-if="isLoadingContainers">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                Loading containers to ship...
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else-if="containerArray.length === 0">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                No containers ready to ship.
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else>
                                                        <tr>
                                                            <td colspan="4">
                                                                <input type="text" placeholder="Scan container license plate" class="form-control lg" ref="scanContainerInput" v-model="containerLicensePlate">
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <div class="d-flex justify-content-between">
                                                                    <span>
                                                                        <template v-if="containerLicensePlate || showSelectedContainersOnly">
                                                                            Showing {{ filteredContainersReadyToShip.length }} of {{ containerArray.length }} containers
                                                                        </template>

                                                                        <template v-else>
                                                                            Showing all containers
                                                                        </template>
                                                                    </span>

                                                                    <label class="checkbox-container mr-3">
                                                                        <input type="checkbox" class="form-control" v-model="showSelectedContainersOnly">
                                                                        <span class="checkmark"></span>

                                                                        Only show selected containers
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="py-0">
                                                                <InputFieldError attribute="shipment" :errors="errors"/>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="6" class="pt-5 pb-3">
                                                                <label class="checkbox-container mb-0 ml-4" @click.prevent="toggleAllCheckboxes()">
                                                                    Select all

                                                                    <input type="checkbox" v-model="selectedAll" :disabled="isCreatingShipment">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <table class="table-third-level">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="w-20 pr-0"></th>

                                                                            <th class="w-auto pl-0">
                                                                                License plate
                                                                            </th>

                                                                            <th class="w-auto">
                                                                                Account
                                                                            </th>

                                                                            <th class="w-auto">
                                                                                Assets
                                                                            </th>

                                                                            <th class="w-auto"></th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="container in filteredContainersReadyToShip" :key="container.ID">
                                                                            <td class="w-20 pr-0">
                                                                                <label class="checkbox-container mb-20 mr-1">
                                                                                    <input type="checkbox" v-model="selectedContainersToShip[container.ID]" :disabled="isCreatingShipment">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </td>

                                                                            <td class="w-auto pl-0">
                                                                                {{ container.LICENSE_PLATE }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                                    {{ container.RETURNBIRD_ACCOUNT_NAME | capitalize }}
                                                                                </template>

                                                                                <template v-else>
                                                                                    -
                                                                                </template>
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ container.assets.length }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                <template v-if="containerDocumentsBeingGenerated && containerDocumentsBeingGenerated.includes(container.ID)">
                                                                                    Generating container document...
                                                                                </template>

                                                                                <template v-else>
                                                                                    <a href="#" @click.prevent="getContainerPdf(container.ID)">
                                                                                        Container document
                                                                                    </a>
                                                                                </template>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <div v-if="isCreatingShipment">
                                    Creating shipment...
                                </div>

                                <div v-else-if="isGeneratingDocument">
                                    Generating shipment document...
                                </div>

                                <button type="button" class="btn btn-primary" @click="createShipment()" :disabled="!Object.keys(selectedContainers).length || !selectedShippingDestinationId || isCreatingShipment">
                                    Create shipment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                errors: {},
                isLoadingContainers: false,
                isLoadingMerchantAccounts: false,
                isLoadingShippingDestinations: false,
                isCreatingShipment: false,
                isGeneratingDocument: false,
                containerLicensePlate: null,
                shippingDestinations: [],
                showSelectedContainersOnly: false,
                containersReadyToShip: null,
                selectedContainersToShip: {},
                containerDocumentsBeingGenerated: [],
                merchantAccounts: [],
                selectedShippingDestinationId: null,
                canShipShipments: false,
            };
        },
        mounted() {
            this.$root.$on('loadedRoleModuleAccess', () => {
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['return']?.['outbound'];
                this.canShipShipments = typeof this.moduleAccess['ship-shipments'] !== 'undefined' && this.moduleAccess['ship-shipments'].ACCESS;
            });
        },
        created() {
            this.getShippingDestinations(this.$route.query.merchant);
            this.getContainers();
            this.getMerchantAccounts();
        },
        methods: {
            getContainers() {
                this.containersReadyToShip = null;
                this.isLoadingContainers = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/get-all');
                url.searchParams.set('includeOnShipment', false);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainers = false;
                    this.containersReadyToShip = data.data;
                });
            },
            getMerchantAccounts() {
                this.isLoadingMerchantAccounts = true;

                this.$store.dispatch('merchantAccounts/getMerchantAccounts').then((data) => {
                    this.isLoadingMerchantAccounts = false;

                    if (data) {
                        for (let i in data) {
                            this.merchantAccounts.push({
                                id: data[i].RTN_ACT_ID,
                                origin: 1,
                                name: data[i].RTN_ACT_NAME
                            });
                        }
                    }
                });
            },
            createShipment() {
                this.errors = {};

                if (this.selectedContainers.length === 0 || !this.selectedShippingDestinationId) {
                    return;
                }

                let selectedContainerIds = [];

                for (let i in this.selectedContainers) {
                    selectedContainerIds.push(this.selectedContainers[i].ID);
                }

                this.isCreatingShipment = true;

                const url = new URL(this.$store.state.baseUrl + 'shipments/create-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[modelIds]', selectedContainerIds);
                params.append('Shipment[type]', 'container');
                params.append('Shipment[destinationReturnbirdLocationId]', this.selectedShippingDestination.RTN_LTN_ID);

                if (this.selectedShippingDestination.RETURNDOCK_LOCATION_ID) {
                    params.append('Shipment[destinationReturndockLocationId]', this.selectedShippingDestination.RETURNDOCK_LOCATION_ID);
                }

                params.append('Shipment[DESTINATION_COUNTRY]', this.selectedShippingDestination.locationAddress.RTN_LNA_COUNTRY);
                params.append('Shipment[DESTINATION_CITY]', this.selectedShippingDestination.locationAddress.RTN_LNA_CITY);
                params.append('Shipment[DESTINATION_NAME]', this.selectedShippingDestination.RTN_LTN_NAME);

                if (this.selectedShippingDestination.locationAddress.RTN_LNA_ADDRESSEE) {
                    params.append('Shipment[DESTINATION_ADDRESSEE]', this.selectedShippingDestination.locationAddress.RTN_LNA_ADDRESSEE);
                }

                if (this.selectedShippingDestination.locationAddress.RTN_LNA_POSTAL) {
                    params.append('Shipment[DESTINATION_POSTAL_CODE]', this.selectedShippingDestination.locationAddress.RTN_LNA_POSTAL);
                }

                if (this.selectedShippingDestination.locationAddress.RTN_LNA_ADMINISTRATIVE_AREA) {
                    params.append('Shipment[DESTINATION_ADMINISTRATIVE_AREA]', this.selectedShippingDestination.locationAddress.RTN_LNA_ADMINISTRATIVE_AREA);
                }

                if (this.selectedShippingDestination.locationAddress.RTN_LNA_ADDRESS_LINE_1) {
                    params.append('Shipment[DESTINATION_ADDRESS_LINE_1]', this.selectedShippingDestination.locationAddress.RTN_LNA_ADDRESS_LINE_1);
                }

                if (this.selectedShippingDestination.locationAddress.RTN_LNA_ADDRESS_LINE_2) {
                    params.append('Shipment[DESTINATION_ADDRESS_LINE_2]', this.selectedShippingDestination.locationAddress.RTN_LNA_ADDRESS_LINE_2);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isCreatingShipment = false;

                    if (data.data.code === 422) {
                        this.$set(this.errors, 'shipment', data.data.errors);
                    } else if (data.data.code === 100) {
                        this.getShipmentPdf(data.data.data);

                        if (this.canShipShipments) {
                            this.$router.push({
                                name: 'ShipShipment',
                                query: {reference: data.data.data, origin: 'container', merchant: this.$route.query.merchant}
                            });
                        } else {
                            this.selectedContainersToShip = {};
                            this.selectedShippingDestinationId = null;
                            this.getContainers();
                        }
                    }
                });
            },
            previous() {
                this.$router.push({
                    name: 'Outbound',
                });
            },
            getShippingDestinations(rbAccountId) {
                this.shippingDestinations = [];
                this.isLoadingShippingDestinations = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/get-available-shipping-addresses');
                url.searchParams.append('rbAccountId', rbAccountId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingShippingDestinations = false;

                    if (data.data.code === 100) {
                        for (let i in data.data.data) {
                            const location = data.data.data[i];

                            this.shippingDestinations.push(location);
                        }
                    }
                });
            },
            toggleAllCheckboxes() {
                if (this.isCreatingShipment) {
                    return;
                }

                if (this.filteredContainersReadyToShip) {
                    const isSelected = this.selectedAll;

                    for (let i = 0; i < Object.keys(this.filteredContainersReadyToShip).length; i++) {
                        const key = this.filteredContainersReadyToShip[i].ID;
                        this.$set(this.selectedContainersToShip, key, !isSelected);
                    }
                }
            },
            getContainerPdf(containerId) {
                this.errors = {};
                this.containerDocumentsBeingGenerated.push(containerId);

                const url = new URL(this.$store.state.baseUrl + 'pdf/container');
                url.searchParams.append('id', containerId);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    let index = this.containerDocumentsBeingGenerated.indexOf(containerId);
                    this.containerDocumentsBeingGenerated.splice(index, 1);

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            getShipmentPdf(shipmentId) {
                this.errors = {};
                this.isGeneratingDocument = true;

                const url = new URL(this.$store.state.baseUrl + 'pdf/outbound-shipment');
                url.searchParams.append('id', shipmentId);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    this.isGeneratingDocument = false;

                    const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(documentBlob));
                });
            },
        },
        computed: {
            containerArray() {
                if (!this.containersReadyToShip) {
                    return [];
                }

                let result = [];

                for (let i in this.containersReadyToShip) {
                    const container = this.containersReadyToShip[i];

                    if (container.RETURNBIRD_ACCOUNT_ID !== this.$route.query.merchant) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            filteredContainersReadyToShip() {
                if (!this.containerArray || this.containerArray.length === 0) {
                    return [];
                }

                let result = [];

                for (let i in this.containerArray) {
                    const container = this.containerArray[i];

                    if (this.showSelectedContainersOnly && !this.selectedContainers.includes(container)) {
                        continue;
                    }

                    if (this.containerLicensePlate && !container.LICENSE_PLATE.toLowerCase().includes(this.containerLicensePlate.toLowerCase()) && !container.ID.includes(this.containerLicensePlate)) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            selectedContainers() {
                if (Object.keys(this.selectedContainersToShip).length === 0) {
                    return [];
                }

                let result = [];

                for (let i = 0; i < Object.keys(this.selectedContainersToShip).length; i++) {
                    const key = Object.keys(this.selectedContainersToShip)[i];
                    const value = Object.values(this.selectedContainersToShip)[i];

                    if (value) {
                        const container = this.containersReadyToShip.find(container => container.ID == key);

                        if (container) {
                            result.push(container);
                        }
                    }
                }

                return result;
            },
            selectedAll() {
                return this.filteredContainersReadyToShip.length > 0 && this.filteredContainersReadyToShip.length === this.selectedContainers.length;
            },
            merchantAccountName() {
                let result = '';

                if (this.$route.query.merchant && this.merchantAccounts.length > 0) {
                    const merchantAccount = this.merchantAccounts.find(account => account.id == this.$route.query.merchant);
                    result = merchantAccount.name;
                }

                return result;
            },
            selectedShippingDestination() {
                let result = null;

                if (this.selectedShippingDestinationId && this.shippingDestinations.length > 0) {
                    result = this.shippingDestinations.find(destination => destination.RTN_LTN_ID == this.selectedShippingDestinationId);
                }

                return result;
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                return day + '-' + month + '-' + year;
            }
        },
    }
</script>

<style scoped>
    .shipping-message {
        font-size: 18px;
    }

    .table-first-level thead tr th, .table-second-level thead tr th {
        width: 16%;
    }
</style>
