<template>
    <div>
        <HeaderBar title="Manifest"></HeaderBar>

        <main class="main">

            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">

                            <div class="table-responsive">
                                <template v-if="isLoadingManifest">
                                    Loading manifest...
                                </template>

                                <template v-else-if="!manifest || Object.keys(manifest).length === 0">
                                    No manifest found with code {{ manifestCode }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Manifest</th>
                                                <th>Manifest ID</th>
                                                <th>Sorting type</th>
                                                <th>Location</th>
                                                <th v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">Account</th>
                                                <th>Returns</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="6">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ manifest.MANIFEST_CODE }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.ID }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="manifest.SORTING_TYPE">
                                                                        {{ manifest.SORTING_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    <template v-if="manifest.location.NAME">
                                                                        {{ manifest.location.NAME }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">
                                                                    {{ manifest.MERCHANT_ACCOUNT_NAME }}
                                                                </th>

                                                                <th>
                                                                    {{ Object.keys(manifest.unshippedReturnManifestRows).length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="6" class="pt-4 pb-0">
                                                                    <div class="d-flex align-items-center justify-content-end pt-3">
                                                                        <div>
                                                                            <span>
                                                                                Ready to ship
                                                                            </span>

                                                                            <p v-if="manifest.IS_READY_TO_SHIP == 1">
                                                                                Yes
                                                                            </p>

                                                                            <p v-else>
                                                                                No
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="6" v-if="manifest.unshippedReturnManifestRows && Object.keys(manifest.unshippedReturnManifestRows).length > 0">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="w-auto">Return</th>
                                                                                <th class="w-auto">External Reference</th>
                                                                                <th class="w-auto">Received Date</th>
                                                                                <th class="w-auto" v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'unsorted'">Account</th>
                                                                                <th class="w-auto">Channel</th>
                                                                                <th class="w-auto">Return Policy</th>
                                                                                <th class="w-auto">Country</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(returnManifestRow, key) in manifest.unshippedReturnManifestRows" :key="key">
                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_REFERENCE">
                                                                                        {{ returnManifestRow.RETURN_REFERENCE }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        {{ returnManifestRow.SHIPMENT_TRACKING_CODE }}
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_EXTERNAL_REFERENCE">
                                                                                        {{ returnManifestRow.RETURN_EXTERNAL_REFERENCE }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    {{ returnManifestRow.CREATION | formatDate }}
                                                                                </td>

                                                                                <td class="w-auto" v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'unsorted'">
                                                                                    <template v-if="returnManifestRow.MERCHANT_ACCOUNT_NAME">
                                                                                        {{ returnManifestRow.MERCHANT_ACCOUNT_NAME }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.PORTAL_NAME">
                                                                                        {{ returnManifestRow.PORTAL_NAME }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.MERCHANT_SETUP">
                                                                                        {{ returnManifestRow.MERCHANT_SETUP }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_ORIGIN_COUNTRY">
                                                                                        <template v-if="countryList && countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY]">
                                                                                            {{ countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY] }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            {{ returnManifestRow.RETURN_ORIGIN_COUNTRY }}
                                                                                        </template>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td colspan="5" v-else>
                                                                    No returns added to this manifest.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        name: 'ManifestPage',
        components: {
            'HeaderBar': HeaderBar,
        },
        data() {
            return {
                isLoadingManifest: false,
                isLoadingCountryList: false,
                manifestCode: this.$route.query.code,
                manifest: null,
                countryList: null,
            };
        },
        created() {
            this.isLoadingManifest = true;

            this.getManifest();
            this.getCountryList();
        },
        methods: {
            getManifest() {
                this.isLoadingManifest = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-return-manifest');
                url.searchParams.append('manifestCode', this.manifestCode);
                url.searchParams.append('showReadyToShip', true);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data.data.data;
                });
            },
            previous() {
                this.$router.push({
                    name: 'InventoryManifestOverview',
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                return day + '-' + month + '-' + year;
            }
        }
    }
</script>

<style scoped>

</style>
