<template>
    <div>
        <HeaderBar title="Manifest"></HeaderBar>

        <main class="main">

            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">

                            <div class="table-responsive">
                                <template v-if="isLoadingManifest">
                                    Loading manifest...
                                </template>

                                <template v-else-if="!manifest || Object.keys(manifest).length === 0">
                                    No manifest found with code {{ manifestCode }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Manifest</th>
                                                <th>Manifest ID</th>
                                                <th>Sorting type</th>
                                                <th v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">Account</th>
                                                <th>Returns</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ manifest.MANIFEST_CODE }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.ID }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="manifest.SORTING_TYPE">
                                                                        {{ manifest.SORTING_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">
                                                                    {{ manifest.MERCHANT_ACCOUNT_NAME }}
                                                                </th>

                                                                <th>
                                                                    {{ Object.keys(manifest.unshippedReturnManifestRows).length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5" class="pt-4 pb-0">
                                                                    <div class="d-flex align-items-center justify-content-between pt-3" v-if="!manifest.returnManifestRows || Object.keys(manifest.returnManifestRows).length === 0">
                                                                        <div>
                                                                            <button type="button" class="btn btn-danger sm" @click.prevent="showDeleteManifestPopup = true">
                                                                                Delete
                                                                            </button>

                                                                            <InputFieldError attribute="manifestDelete" :errors="errors"/>
                                                                        </div>
                                                                    </div>

                                                                    <div class="d-flex align-items-center justify-content-between pt-3" v-else>
                                                                        <button type="button" class="btn btn-primary p-3" @click.prevent="$router.push({name: 'MergeManifest', query: {code: manifest.ID}})" v-if="manifest.unshippedReturnManifestRows && Object.keys(manifest.unshippedReturnManifestRows).length > 0" :disabled="manifestIsReadyToShip" ref="btnMerge" :style="{width: btnWidth}">
                                                                            Merge
                                                                        </button>

                                                                        <div>
                                                                            <div class="d-flex justify-content-center">
                                                                                <div class="d-flex align-items-center justify-content-end">
                                                                                    <span>
                                                                                        Ready to ship
                                                                                    </span>

                                                                                    <div class="flipswitch ml-3">
                                                                                        <input type="checkbox"
                                                                                               name="flipswitch"
                                                                                               class="flipswitch-cb" id="readyToShip"
                                                                                               :checked="manifestIsReadyToShip"
                                                                                               @click.prevent="toggleManifestReadyToShip()"
                                                                                               :disabled="isSavingManifestReadyToShip">

                                                                                        <label class="flipswitch-label mb-0" for="readyToShip">
                                                                                            <div class="flipswitch-inner flipswitch-inner--ready-to-ship" :class="manifest.SHIPMENT_REFERENCE ? 'orange' : 'red'"></div>
                                                                                            <div class="flipswitch-switch"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <InputFieldError attribute="manifestReadyToShipStatus" :errors="errors"/>
                                                                        </div>

                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3 ml-5"
                                                                            @click.prevent="getManifestListPdf()"
                                                                            :disabled="isGeneratingDocument"
                                                                            ref="btnManifestList"
                                                                            :style="{width: btnWidth}"
                                                                        >
                                                                            Manifest list
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="5" v-if="manifest.unshippedReturnManifestRows && Object.keys(manifest.unshippedReturnManifestRows).length > 0">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="w-auto">Return</th>
                                                                                <th class="w-auto">External Reference</th>
                                                                                <th class="w-auto">Received Date</th>
                                                                                <th class="w-auto" v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'unsorted'">Account</th>
                                                                                <th class="w-auto">Channel</th>
                                                                                <th class="w-auto">Return Policy</th>
                                                                                <th class="w-auto">Country</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(returnManifestRow, key) in manifest.unshippedReturnManifestRows" :key="key">
                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_REFERENCE">
                                                                                        {{ returnManifestRow.RETURN_REFERENCE }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        {{ returnManifestRow.SHIPMENT_TRACKING_CODE }}
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_EXTERNAL_REFERENCE">
                                                                                        {{ returnManifestRow.RETURN_EXTERNAL_REFERENCE }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    {{ returnManifestRow.CREATION | formatDate }}
                                                                                </td>

                                                                                <td class="w-auto" v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'unsorted'">
                                                                                    <template v-if="returnManifestRow.MERCHANT_ACCOUNT_NAME">
                                                                                        {{ returnManifestRow.MERCHANT_ACCOUNT_NAME }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.PORTAL_NAME">
                                                                                        {{ returnManifestRow.PORTAL_NAME }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.MERCHANT_SETUP">
                                                                                        {{ returnManifestRow.MERCHANT_SETUP }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_ORIGIN_COUNTRY">
                                                                                        <template v-if="countryList && countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY]">
                                                                                            {{ countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY] }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            {{ returnManifestRow.RETURN_ORIGIN_COUNTRY }}
                                                                                        </template>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td colspan="5" v-else>
                                                                    No returns added to this manifest.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>

        <ConfirmationPopup v-if="showDeleteManifestPopup"
                           :message="'Are you sure you want to delete manifest ' + manifest.MANIFEST_CODE + '?'"
                           :loadingMessage="'Deleting manifest ' + manifest.MANIFEST_CODE + '...'"
                           :isLoading="isDeletingManifest"
                           @confirm="deleteManifest()"
                           @deny="showDeleteManifestPopup = false"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        name: 'ManifestPage',
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                errors: {},
                isLoadingManifest: false,
                isLoadingCountryList: false,
                isDeletingManifest: false,
                manifestCode: this.$route.query.code,
                manifest: null,
                isSavingManifestReadyToShip: false,
                selectedLocation: null,
                countryList: null,
                showDeleteManifestPopup: false,
                isGeneratingDocument: false,
                btnWidth: null,
            };
        },
        created() {
            this.isLoadingManifest = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;
                }

                this.getManifest();
            });

            this.getCountryList();
        },
        methods: {
            getManifest() {
                this.isLoadingManifest = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-return-manifest');
                url.searchParams.append('manifestCode', this.manifestCode);
                url.searchParams.append('locationId', this.selectedLocation);
                url.searchParams.append('showReadyToShip', true);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data.data.data;

                    this.setButtonWidths();
                });
            },
            previous() {
                this.$router.push({
                    name: 'ManifestOverview',
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
            deleteManifest() {
                this.errors = {};
                this.isDeletingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/delete-manifest');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifest.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingManifest = false;
                    this.showDeleteManifestPopup = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestDelete: data.data.errors};
                    } else {
                        this.$router.push({
                            name: 'ManifestOverview',
                        });
                    }
                });
            },
            toggleManifestReadyToShip() {
                if (this.isSavingManifestReadyToShip) {
                    return;
                }

                this.errors = {};
                this.isSavingManifestReadyToShip = true;

                const isReadyToShip = this.manifest.IS_READY_TO_SHIP == 1 ? 0 : 1;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/set-manifest-ready-to-ship');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifest.ID);
                params.append('ReturnManifest[IS_READY_TO_SHIP]', isReadyToShip);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingManifestReadyToShip = false;

                    if (data.data.code === 100) {
                        this.manifest.IS_READY_TO_SHIP = isReadyToShip;
                    } else if (data.data.code === 422) {
                        this.errors = {manifestReadyToShipStatus: data.data.errors};
                    }
                });
            },
            getManifestListPdf() {
                this.errors = {};
                this.isGeneratingDocument = true;

                const url = new URL(this.$store.state.baseUrl + 'pdf/manifest');
                url.searchParams.append('id', this.manifest.ID);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    this.isGeneratingDocument = false;

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            setButtonWidths() {
                this.$nextTick(() => {
                    const btnMerge = this.$refs.btnMerge;
                    const btnManifestList = this.$refs.btnManifestList;

                    if (btnMerge && btnManifestList) {
                        const btnMergeWidth = parseFloat(getComputedStyle(btnMerge).width.replace('px', ''));
                        const btnManifestListWidth = parseFloat(getComputedStyle(btnManifestList).width.replace('px', ''));

                        let width = 0;
                        width = (btnMergeWidth > width) ? btnMergeWidth : width;
                        width = (btnManifestListWidth > width) ? btnManifestListWidth : width;
                        this.btnWidth = width + 'px';
                    }
                });
            }
        },
        computed: {
            manifestIsReadyToShip() {
                return this.manifest && this.manifest.IS_READY_TO_SHIP == 1;
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                return day + '-' + month + '-' + year;
            }
        }
    }
</script>

<style scoped>

</style>
