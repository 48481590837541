<template>
    <div>
        <HeaderBar title="User overview"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="User" activeItem="Overview"></SettingsMenu>

                <template v-if="initLoaded">
                    <div class="col-10">
                        <div class="row col-12">
                            <div class="col-md-5">
                                Email
                            </div>
                            <div class="col-md-4">
                                Name
                            </div>
                            <div class="col-md-1">
                                Type
                            </div>
                            <div class="col-md-1">
                                Update
                            </div>
                            <div class="col-md-1">
                                Delete
                            </div>
                        </div>

                        <div class="col-12 row" v-for="(agent, key) in $store.state.users.agents" v-bind:key="key"
                             v-bind:id="agent.ID">
                            <template v-if="agentsBeingDeleted.indexOf(agent.ID) != -1">
                                deleting agent...
                            </template>
                            <template v-else>
                                <div class="col-md-5">
                                    {{ agent.EMAIL}}
                                </div>
                                <div class="col-md-4">
                                    {{ agent.FIRST_NAME }} {{ agent.LAST_NAME }}
                                </div>
                                <div class="col-md-1">
                                    {{ agent.TYPE == 3 ? 'admin' : 'operator' }}
                                </div>
                                <div class="col-md-1 text-primary pointer" v-on:click="updateUser(agent.ID)">
                                    Update
                                </div>
                                <div class="col-md-1 text-danger pointer" v-on:click="deleteUser(agent.ID)">
                                    Delete
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <div v-else>
                    loading agents...
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'SettingsMenu': SettingsMenu
        },
        data() {
            return {
                initLoaded: false,
                agentsBeingDeleted: []
            };
        },
        created() {
            let agentsPromise = this.$store.dispatch('users/getAgents');

            Promise.all([agentsPromise]).then(() => {
                this.initLoaded = true;
            });

        },
        methods: {
            updateUser(id) {
                this.$router.push({
                    name: 'UserUpdate',
                    query: {userId: id}
                });
            },
            deleteUser(id) {
                let url = new URL(this.$store.state.baseUrl + 'users/delete-user');
                let params = new URLSearchParams();
                params.append('User[ID]', id);

                this.agentsBeingDeleted.push(id);

                this.$http.post(url.href, params).then(() => {
                    let index = this.agentsBeingDeleted.indexOf(id);
                    this.agentsBeingDeleted.splice(index, 1);
                    this.$store.commit('users/deleteAgent', id);
                });
            }
        }
    }
</script>

<style>

</style>
