<template>
    <div>
        <template v-if="isAddingPictures">
            <div class="webcam-component w-100">
                <video ref="camera" class="d-block" autoplay
                       :style="{
                            width: isCameraEnabled ? '100%' : '0px',
                            height: isCameraEnabled ? '100%' : '0px',
                            maxWidth: width ? (width + 'px') : '100%',
                            maxHeight: height ? (height + 'px') : '100%'
                       }"></video>

                <InputFieldError :attribute="['camera']" :errors="errors"/>

                <div class="row" v-if="!isUploadingScreenshots && !isUploadingFileBrowserImages && (uploadedImages && (uploadedImages.length < imageCountLimit))">
                    <div :class="isCameraEnabled ? 'col-4' : 'd-none'">
                        <button type="button" class="btn btn-primary btn-md" @click.prevent="createScreenshot()" v-if="getRemainingAvailableScreenshotCount() > 0" :disabled="isUploadingScreenshots || isUploadingFileBrowserImages">
                            Take picture
                        </button>
                    </div>

                    <div :class="isCameraEnabled ? 'col-4 d-flex align-items-center' : 'col-6 d-flex align-items-center'">
                        <input id="fileUpload" type="file" ref="file" class="d-block" multiple @change="errors = {}" :disabled="isUploadingScreenshots || isUploadingFileBrowserImages">
                    </div>

                    <div :class="isCameraEnabled ? 'col-4 text-right' : 'col-6 text-right'">
                        <button type="button" class="btn btn-primary btn-md" @click.prevent="saveImages()" v-if="(screenshots && screenshots.length > 0 && getRemainingAvailableScreenshotCount() > -1) || (getSelectedImageCount() > 0)" :disabled="isUploadingScreenshots || isUploadingFileBrowserImages">
                            Save pictures
                        </button>
                    </div>
                </div>

                <InputFieldError :attribute="['fileUpload', 'fileBrowserUpload', 'FILE']" :errors="errors"/>
            </div>

            <div class="row my-4">
                <div class="col-12">
                    <template v-if="isUploadingScreenshots || isUploadingFileBrowserImages">
                        <p>
                            Saving pictures...
                        </p>
                    </template>

                    <template v-else>
                        <ImageGallery :images="uploadedImages"
                                      idAttribute="ID"
                                      urlAttribute="URL"
                                      :allowDelete="true"
                                      @deleteImage="deleteImage"
                                      :imagesBeingDeleted="imagesBeingDeleted"
                                      class="md mt-3 d-inline-flex btn--delete-image__green">
                        </ImageGallery>

                        <ImageGallery :images="screenshots"
                                      idAttribute="id"
                                      urlAttribute="displayUrl"
                                      :allowDelete="true"
                                      @deleteImage="deleteScreenshot"
                                      class="md mt-3 d-inline-flex">
                        </ImageGallery>
                    </template>
                </div>

                <div class="col-12">
                    <InputFieldError :attribute="['screenshot', 'fileDelete']" :errors="errors"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-right">
                    <button type="button" class="btn btn-primary btn-md" @click.prevent="stopAddingPictures()">
                        Close
                    </button>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-block text-right">
                <button type="button" class="btn btn-primary btn-md" @click.prevent="startAddingPictures()">
                    Add pictures
                </button>
            </div>

            <div class="row" v-if="uploadedImages && uploadedImages.length > 0">
                <div class="col-12">
                    <label class="label">
                        Saved pictures
                    </label>

                    <ImageGallery :images="uploadedImages"
                                  idAttribute="ID"
                                  urlAttribute="URL"
                                  class="md">
                    </ImageGallery>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ImageGallery from '@/components/_ImageGallery.vue';

    export default {
        components: {
            'InputFieldError': InputFieldError,
            'ImageGallery': ImageGallery,
        },
        props: [
            'width',
            'height',
            'imageCountLimit',
            'model',
            'modelPk',
            'fileUploadCategory',
            'shouldStopAddingPictures',
        ],
        data() {
            return {
                errors: {},
                isAddingPictures: false,
                isCameraEnabled: false,
                isLoadingUploadedImages: false,
                isUploadingScreenshots: false,
                isUploadingFileBrowserImages: false,
                screenshotId: 0,
                screenshots: [],
                uploadedImages: [],
                imagesBeingDeleted: [],
            }
        },
        created() {
            this.getUploadedImages();
        },
        methods: {
            startAddingPictures() {
                this.isAddingPictures = true;
                this.$emit('startedAddingPictures');

                this.$nextTick(() => {
                    this.activateCamera();
                });
            },
            stopAddingPictures() {
                if (this.screenshots && this.screenshots.length > 0) {
                    this.errors = {screenshot: 'There are pictures which have not yet been saved. Please save or remove them.'};
                    return false;
                }

                this.deactivateCamera();
                this.$emit('stoppedAddingPictures');
                
                this.isAddingPictures = false;
            },
            activateCamera() {
                if (!this.$refs.camera) {
                    return;
                }

                this.errors = {};

                if (!navigator.mediaDevices) {
                    /* mediaDevices is not supported, possibly due to serving the website over http instead of https */
                    this.errors = {camera: 'Unable to detect media devices.'};
                    return;
                }

                const constraints = {
                    video: true
                };

                navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                    this.$refs.camera.srcObject = stream;
                }).catch((error) => {
                    if (error.name === 'NotAllowedError') {
                        this.errors = {camera: 'Please grant this page permission to access to your camera device in order to create photos.'};
                    } else {
                        this.errors = {camera: 'Something went wrong while trying to activate the camera.'};
                    }

                    this.isCameraEnabled = false;
                    return false;
                });

                navigator.mediaDevices.ondevicechange = () => {
                    this.deactivateCamera();
                };

                this.isCameraEnabled = true;
            },
            deactivateCamera() {
                if (!this.$refs.camera || !this.$refs.camera.srcObject) {
                    this.isCameraEnabled = false;
                    return;
                }

                let tracks = this.$refs.camera.srcObject.getTracks();

                if (tracks && tracks.length > 0) {
                    tracks.forEach(track => {
                        track.stop();
                    });
                }

                this.$refs.camera.srcObject = null;
                this.isCameraEnabled = false;
            },
            createScreenshot() {
                if (!this.$refs.camera.srcObject) {
                    this.errors = {screenshot: 'Unable to create screenshot.'};
                    return;
                }

                this.screenshotId++;

                /* Draw screenshot on canvas */
                let canvas = document.createElement('canvas');
                canvas.width = this.$refs.camera.videoWidth;
                canvas.height = this.$refs.camera.videoHeight;

                const context = canvas.getContext('2d');
                const widthRatio = canvas.width / this.$refs.camera.videoWidth;
                const heightRatio = canvas.height / this.$refs.camera.videoHeight;
                const ratio = Math.min(widthRatio, heightRatio);
                const offsetX = (canvas.width - this.$refs.camera.videoWidth * ratio) / 2;
                const offsetY = (canvas.height - this.$refs.camera.videoHeight * ratio) / 2;

                /* Draw centered image */
                context.drawImage(this.$refs.camera, 0, 0, this.$refs.camera.videoWidth, this.$refs.camera.videoHeight, offsetX, offsetY, this.$refs.camera.videoWidth * ratio, this.$refs.camera.videoHeight * ratio);

                canvas.toBlob((blob) => {
                    const file = new File([blob], 'screenshot-' + this.screenshotId + '.png', {type: "image/png"});

                    this.screenshots.push({
                        id: this.screenshotId,
                        file: file,
                        displayUrl: canvas.toDataURL()
                    });
                }, 'image/png');
            },
            deleteScreenshot(id) {
                const index = this.screenshots.findIndex(screenShot => screenShot.id === id);

                if (index > -1) {
                    this.screenshots.splice(index, 1);
                }
            },
            deleteImage(id) {
                this.errors = {};
                this.imagesBeingDeleted.push(id);

                const url = new URL(this.$store.state.baseUrl + 'files/delete-image');
                const params = new URLSearchParams();
                params.append('File[MODEL]', this.model);
                params.append('File[MODEL_PK]', this.modelPk);
                params.append('File[FILE_ID]', id);

                this.$http.post(url.href, params).then((data) => {
                    const imagesBeingDeletedIndex = this.imagesBeingDeleted.indexOf(id);

                    if (imagesBeingDeletedIndex > -1) {
                        this.imagesBeingDeleted.splice(imagesBeingDeletedIndex, 1);
                    }

                    if (data.data === undefined) {
                        this.errors = {fileDelete: 'Something went wrong while trying to delete the image.'};
                    } else if (data.data.code === 100) {
                        const index = this.uploadedImages.findIndex(image => image.ID === id);

                        if (index > -1) {
                            this.uploadedImages.splice(index, 1);
                        }
                    } else if (data.data.code === 422) {
                        this.errors = {fileDelete: data.data.errors};
                    }
                });
            },
            uploadScreenshots() {
                if (!this.screenshots || this.screenshots.length === 0) {
                    this.errors = {fileUpload: 'No images selected to upload.'};
                    return false;
                }

                this.errors = {};
                this.isUploadingScreenshots = true;

                let formData = new FormData();
                formData.append('FileModel[CATEGORY]', this.fileUploadCategory);
                formData.append('FileModel[MODEL]', this.model);
                formData.append('FileModel[MODEL_PK]', this.modelPk);

                for (let i in this.screenshots) {
                    formData.append('File[' + i + ']', this.screenshots[i].file);
                }

                let url = new URL(this.$store.state.baseUrl + 'files/upload-images');

                this.$http.post(url.href, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((data) => {
                    this.isUploadingScreenshots = false;

                    if (data.data === undefined) {
                        this.errors = {fileUpload: 'Something went wrong while trying to save the image(s).'};
                    } else if (data.data.code === 100) {
                        for (let i in data.data.data) {
                            this.uploadedImages.push(data.data.data[i]);
                        }

                        this.screenshots = [];
                    } else if (data.data.code === 422) {
                        if (typeof data.data.errors === 'object') {
                            this.errors = data.data.errors;
                        } else {
                            this.errors = {fileUpload: data.data.errors};
                        }

                        if (data.data.data) {
                            for (let i in data.data.data) {
                                this.uploadedImages.push(data.data.data[i]);
                            }
                        }
                    }
                });
            },
            uploadFileBrowserImages() {
                if (this.getSelectedImageCount() === 0) {
                    this.errors = {fileBrowserUpload: 'No images selected to upload.'};
                    return false;
                }

                if (this.getRemainingAvailableImageCount() <= 0) {
                    this.errors = {fileBrowserUpload: 'A maximum of ' + this.imageCountLimit + ' pictures can be added.'};
                    return false;
                }

                this.errors = {};
                this.isUploadingFileBrowserImages = true;

                let formData = new FormData();
                formData.append('FileModel[CATEGORY]', this.fileUploadCategory);
                formData.append('FileModel[MODEL]', this.model);
                formData.append('FileModel[MODEL_PK]', this.modelPk);

                for (let i in this.$refs.file.files) {
                    formData.append('File[' + i + ']', this.$refs.file.files[i]);
                }

                let url = new URL(this.$store.state.baseUrl + 'files/upload-images');

                this.$http.post(url.href, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((data) => {
                    this.isUploadingFileBrowserImages = false;

                    if (data.data === undefined) {
                        this.errors = {fileBrowserUpload: 'Something went wrong while trying to save the image(s).'};
                    } else if (data.data.code === 100) {
                        for (let i in data.data.data) {
                            this.uploadedImages.push(data.data.data[i]);
                        }
                    } else if (data.data.code === 422) {
                        if (typeof data.data.errors === 'object') {
                            this.errors = data.data.errors;
                        } else {
                            this.errors = {fileBrowserUpload: data.data.errors};
                        }

                        if (data.data.data) {
                            for (let i in data.data.data) {
                                this.uploadedImages.push(data.data.data[i]);
                            }
                        }
                    }
                });
            },
            getUploadedImages() {
                this.isLoadingUploadedImages = true;

                const url = new URL(this.$store.state.baseUrl + 'files/get-uploaded-images');
                url.searchParams.set('model', this.model);
                url.searchParams.set('modelPk', this.modelPk);
                url.searchParams.set('category', this.fileUploadCategory);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingUploadedImages = false;

                    if (data.data.code === 100) {
                        if (data.data.data) {
                            this.uploadedImages = data.data.data;
                        }
                    } else if (data.data.code === 422) {
                        this.errors = {fileUpload: data.data.errors};
                    }
                });
            },
            getRemainingAvailableImageCount() {
                return this.imageCountLimit - this.uploadedImages.length;
            },
            getRemainingAvailableScreenshotCount() {
                return this.imageCountLimit - this.uploadedImages.length - this.screenshots.length;
            },
            getSelectedImageCount() {
                const fileUpload = document.getElementById('fileUpload');

                if (!fileUpload) {
                    return 0;
                }

                return fileUpload.files.length;
            },
            saveImages() {
                if (this.screenshots && this.screenshots.length > 0 && this.getRemainingAvailableScreenshotCount() > -1) {
                    this.uploadScreenshots();
                }

                if (this.getSelectedImageCount() > 0) {
                    this.uploadFileBrowserImages();
                }
            }
        },
        watch: {
            shouldStopAddingPictures(value) {
                if (value) {
                    this.stopAddingPictures();
                }
            }
        }
    }
</script>


<style scoped lang="scss">
    .webcam-component {
        display: inline-block;

        video {
            background: #000000;
        }
    }
</style>
