<template>
    <div>
        <HeaderBar title="Manifest overview"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="scanManifest()">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="form-group">
                                <label for="manifest-code" class="label-title">
                                    Scan to view manifest
                                </label>

                                <input type="text" placeholder="Scan manifest number" class="form-control lg"
                                       id="manifest-code" ref="scanManifestInput" v-model="manifestCode" :disabled="isSearchingManifest">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="manifestCode" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isSearchingManifest">
                                    Loading manifest {{ manifestCode }}...
                                </div>
                            </div>

                            <div class="scan-manisfest-container">
                                <div class="row">
                                    <template v-if="isLoadingManifests">
                                        Loading manifests...
                                    </template>

                                    <template v-else-if="Object.keys(manifests).length === 0">
                                        There are no open manifests.
                                    </template>

                                    <template v-else>
                                        <div class="col-sm-6 col-lg-3" v-for="(manifest, key) in manifests" :key="key">
                                            <router-link class="text-decoration-none scan-manifest-card-link" :to="{name: 'InventoryManifest', query: {code: manifest.ID}}">
                                                <div class="scan-manisfest-card">
                                                    <div class="scan-manisfest-card-header" :class="manifest.IS_READY_TO_SHIP == 1 ? manifest.SHIPMENT_REFERENCE ? 'orange' : 'red' : ''">
                                                        <h4>
                                                            {{ manifest.MANIFEST_CODE }}
                                                        </h4>

                                                        <span class="badge">
                                                            {{ manifest.unshippedReturnManifestRows.length }}
                                                        </span>
                                                    </div>

                                                    <div class="scan-manisfest-card-body" ref="manifestCardBody" :style="{'height': manifestCardBodyHeight}">
                                                        <div>
                                                            <span class="label">Manifest ID</span>

                                                            <span class="content">
                                                                {{ manifest.ID }}
                                                            </span>
                                                        </div>

                                                        <div class="mt-4" v-if="manifest.SHIPMENT_REFERENCE">
                                                            <span class="label">Shipment</span>

                                                            <span class="content">
                                                                {{ manifest.SHIPMENT_REFERENCE }}
                                                            </span>
                                                        </div>

                                                        <div class="mt-4">
                                                            <span class="label">Sorting type</span>

                                                            <span class="content">
                                                                <template v-if="manifest.SORTING_TYPE">
                                                                    {{ manifest.SORTING_TYPE | capitalize }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </span>
                                                        </div>

                                                        <div class="mt-4">
                                                            <span class="label">Location</span>

                                                            <span class="content">
                                                                <template v-if="manifest.location.NAME">
                                                                    {{ manifest.location.NAME }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </span>
                                                        </div>

                                                        <template v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">
                                                            <div class="mt-4">
                                                                <span class="label">Account</span>

                                                                <span class="content">
                                                                    {{ manifest.MERCHANT_ACCOUNT_NAME }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                errors: {},
                isLoadingManifests: false,
                isSearchingManifest: false,
                isLoadingCountryList: false,
                manifestCode: null,
                manifests: null,
                countryList: null,
                manifestCardBodyHeight: null,
            };
        },
        mounted() {
            /* Focus on the "Scan manifest" input field on page load */
            this.$refs.scanManifestInput.focus();
        },
        created() {
            this.isLoadingManifests = true;

            this.getManifests();
            this.getCountryList();
        },
        methods: {
            getManifests() {
                this.isLoadingManifests = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-all');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifests = false;
                    this.manifests = data.data;

                    this.$nextTick(() => {
                        if (this.$refs.manifestCardBody) {
                            let scrollHeight = 0;

                            for (let i = 0; i < this.$refs.manifestCardBody.length; i++) {
                                if (this.$refs.manifestCardBody[i].scrollHeight > scrollHeight) {
                                    scrollHeight = this.$refs.manifestCardBody[i].scrollHeight;
                                }
                            }

                            this.manifestCardBodyHeight = scrollHeight + 'px';
                        }
                    });
                });
            },
            scanManifest() {
                this.$router.push({
                    name: 'InventoryManifest',
                    query: {code: this.manifestCode}
                });
            },
            previous() {
                this.$router.push({
                    name: 'Inventory',
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped>

</style>
