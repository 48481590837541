<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <template v-if="isLoadingProductGroups">
                <div class="container mt-5">
                    Loading product groups...
                </div>
            </template>

            <template v-else-if="isSavingProduct">
                <div class="container mt-5">
                    Saving product...
                </div>
            </template>

            <form v-else>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <h3 class="section-title-sm mb-4">Add unexpected product</h3>

                            <div class="vertical-sets">
                                <div class="vertical-sets-left"></div>

                                <div class="vertical-sets-right">
                                    <div class="form-group">
                                        <label class="label">
                                            Product group
                                        </label>

                                        <v-select v-model="selectedProductGroupId" :filterable="true" :searchable="true"
                                                  :options="_.values(productGroupList)" label="RTN_ARP_NAME"
                                                  :reduce="productGroup => productGroup.RTN_ARP_ID"></v-select>
                                    </div>

                                    <div class="form-group" v-if="selectedProductGroupId">
                                        <template v-if="isLoadingProducts">
                                            Loading products...
                                        </template>

                                        <template v-else-if="productList[selectedProductGroupId]">
                                            <label class="label">
                                                Product
                                            </label>

                                            <v-select v-model="selectedProductId" :filterable="true" :searchable="true"
                                                      :options="_.values(productList[selectedProductGroupId])"
                                                      label="RTN_ARL_MODEL_NUMBER"
                                                      :reduce="article => article.RTN_ARL_ID"></v-select>
                                        </template>
                                    </div>

                                    <InputFieldError attribute="global" :errors="errors"/>
                                </div>
                            </div>
                        </div><!-- container -->
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back to overview
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="next()">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->
            </form>

        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        data() {
            return {
                flow: this.$route.query.flow,
                manifestId: this.$route.query.manifestId,
                manifestReference: this.$route.query.manifestReference,
                isLoadingProductGroups: false,
                isLoadingProducts: false,
                isSavingProduct: false,
                errors: {},
                productGroupList: null,
                productList: {},
                selectedProductGroupId: null,
                selectedProductId: null,
            };
        },
        created() {
            this.isLoadingProductGroups = true;

            this.$store.dispatch('articleGroups/getAll', this.$route.query.returnId).then((data) => {
                this.isLoadingProductGroups = false;
                this.productGroupList = data;
            });
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'AssetOverview',
                    query: {
                        returnId: this.$route.query.returnId,
                        flow: this.flow,
                        manifestId: this.manifestId,
                        manifestReference: this.manifestReference,
                    }
                });
            },
            next() {
                this.$store.commit('returns/clearUnexpectedProducts');

                this.$router.push({
                    name: 'Unpack',
                    query: {
                        returnId: this.$route.query.returnId,
                        articleId: this.selectedProductId,
                        flow: this.flow,
                        manifestId: this.manifestId,
                        manifestReference: this.manifestReference,
                    }
                });
            },
            getProductsFromGroup() {
                this.isLoadingProducts = true;

                this.$store.dispatch('articles/getAllFromGroup', this.selectedProductGroupId).then((data) => {
                    this.isLoadingProducts = false;
                    this.productList[this.selectedProductGroupId] = data;
                });
            },
        },
        watch: {
            selectedProductGroupId() {
                this.getProductsFromGroup();
                this.selectedProductId = null;
                this.errors = {};
            },
            selectedProductId() {
                this.errors = {};
            }
        }
    }
</script>

<style scoped>

</style>
