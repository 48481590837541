<template>
    <div>
        <HeaderBar></HeaderBar>

        <main class="main">
            <template v-if="isLoadingReturnModel">
                <div class="container mt-5">
                    Loading data...
                </div>
            </template>

            <form v-else>
                <div class="scrollable-area">
                    <div class="scrollable-content no-footer">
                        <div class="container">
                            <h3 class="section-title-sm mb-4">
                                Remarks
                            </h3>

                            <div class="table-responsive">
                                <table class="table-first-level">
                                    <thead>
                                        <tr>
                                            <th>
                                                Return
                                            </th>

                                            <th>
                                                External reference
                                            </th>

                                            <th>
                                                Account
                                            </th>

                                            <th>
                                                Channel
                                            </th>

                                            <th>
                                                Return policy
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colspan="5">
                                                <table class="table-second-level">
                                                    <thead>
                                                        <tr>
                                                            <th class="border-bottom-none">
                                                                {{ returnModel.RETURN_REFERENCE }}
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <template v-if="returnModel.EXTERNAL_REFERENCE">
                                                                    {{ returnModel.EXTERNAL_REFERENCE }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                {{ returnModel.MERCHANT_ACCOUNT_NAME }}
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <template v-if="returnModel.PORTAL_NAME">
                                                                    {{ returnModel.PORTAL_NAME }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <template v-if="returnModel.MERCHANT_SETUP_NAME">
                                                                    {{ returnModel.MERCHANT_SETUP_NAME }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="p-0 border-radius-none border-top-td">
                                                                <template v-if="isSaving">
                                                                    <div class="container my-4">
                                                                        Saving remarks...
                                                                    </div>
                                                                </template>

                                                                <template v-else>
                                                                    <table class="w-100 min-table-height">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-radius-none vertical-top">
                                                                                    <Webcam model="ReturnModel"
                                                                                            :modelPk="pk"
                                                                                            imageCountLimit="5"
                                                                                            fileUploadCategory="returnModelSortToManifest"
                                                                                            @startedAddingPictures="isAddingPictures = true"
                                                                                            @stoppedAddingPictures="isAddingPictures = false"
                                                                                            :shouldStopAddingPictures="shouldStopAddingPictures"
                                                                                            ref="webcamComponent"
                                                                                    >
                                                                                    </Webcam>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top">
                                                                                    <div class="form-group">
                                                                                        <label class="label">
                                                                                            Available remarks
                                                                                        </label>

                                                                                        <div v-if="isLoadingAvailableRemarks">
                                                                                            <p>
                                                                                                Loading available remarks...
                                                                                            </p>
                                                                                        </div>

                                                                                        <div v-else-if="availableRemarks.length === 0 || filteredAvailableRemarks.length === 0">
                                                                                            <p>
                                                                                                No available remarks.
                                                                                            </p>
                                                                                        </div>

                                                                                        <v-select
                                                                                                v-model="selectedRemark"
                                                                                                :filterable="true"
                                                                                                :searchable="true"
                                                                                                :clearable="false"
                                                                                                :options="filteredAvailableRemarks"
                                                                                                v-else
                                                                                        >
                                                                                        </v-select>

                                                                                        <InputFieldError attribute="selectedRemark" :errors="errors"/>
                                                                                    </div>

                                                                                    <div class="form-group">
                                                                                        <InputFieldError attribute="global" :errors="errors"/>
                                                                                    </div>

                                                                                    <div class="form-group" v-if="!isLoadingAvailableRemarks">
                                                                                        <label class="label">
                                                                                            Return remarks
                                                                                        </label>

                                                                                        <template v-if="remarks && remarks.length > 0">
                                                                                            <table class="w-100">
                                                                                                <tr v-for="remark in remarks" :key="remark">
                                                                                                    <td class="pl-0 py-2">
                                                                                                        <p>
                                                                                                            {{ remark }}
                                                                                                        </p>
                                                                                                    </td>

                                                                                                    <td class="pr-0 py-2">
                                                                                                        <a href="#" @click.prevent="removeRemark(remark)" class="action-btn">
                                                                                                            &times;
                                                                                                        </a>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            <p>
                                                                                                -
                                                                                            </p>
                                                                                        </template>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </template>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="saveRemarks()" v-if="!isLoadingReturnModel" :disabled="isSaving">
                                    Save remarks
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import Webcam from '@/components/_Webcam.vue';
    import vSelect from 'vue-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'Webcam': Webcam,
            'v-select': vSelect,
        },
        data() {
            return {
                pk: this.$route.query.pk,
                manifestIsReadyToShip: this.$route.query.readyToShip,
                errors: {},
                isLoadingReturnModel: false,
                isLoadingAvailableRemarks: false,
                isSaving: false,
                availableRemarks: [],
                remarks: [],
                selectedRemark: null,
                isAddingPictures: false,
                shouldStopAddingPictures: false,
            };
        },
        created() {
            this.getReturnByPk();
            this.getAvailableRemarks();
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'SortToManifest',
                    query: {returnReference: this.returnModel.RETURN_REFERENCE, preload: true}
                });
            },
            next() {
                this.$router.push({
                    name: 'SortToManifest',
                    query: {returnReference: this.returnModel.RETURN_REFERENCE, preload: true}
                });
            },
            saveRemarks() {
                this.errors = {};
                this.isSaving = true;

                const url = new URL(this.$store.state.baseUrl + 'returns/save-remarks');
                const params = new URLSearchParams();
                params.append('ReturnModel[PK]', this.pk);
                params.append('ReturnModel[REMARKS]', this.remarks);

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else if (data.data.code === 100) {
                        this.next();
                    }
                });
            },
            getReturnByPk() {
                this.isLoadingReturnModel = true;

                const url = new URL(this.$store.state.baseUrl + 'returns/find-by-pk');
                url.searchParams.set('pk', this.pk);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingReturnModel = false;
                    this.returnModel = data.data;

                    if (this.returnModel.REMARKS_JSON) {
                        this.remarks = JSON.parse(this.returnModel.REMARKS_JSON);
                    }
                });
            },
            getAvailableRemarks() {
                this.isLoadingAvailableRemarks = true;

                const url = new URL(this.$store.state.baseUrl + 'returns/get-available-remarks');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingAvailableRemarks = false;
                    this.availableRemarks = data.data;
                });
            },
            removeRemark(key) {
                let index = this.remarks.indexOf(key);
                this.remarks.splice(index, 1);
            }
        },
        computed: {
            filteredAvailableRemarks() {
                let availableRemarks = [];

                if (this.availableRemarks) {
                    for (const remark of this.availableRemarks) {
                        if (!this.remarks.includes(remark)) {
                            availableRemarks.push(remark);
                        }
                    }
                }

                return availableRemarks;
            },
        },
        watch: {
            selectedRemark(value) {
                if (value) {
                    this.remarks.push(value);
                    this.selectedRemark = null;
                }
            }
        }
    }
</script>

<style scoped>
    .section-title-sm {
        padding-left: 14px;
    }

    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 180px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }

    .min-table-height {
        min-height: 370px;
    }

    .action-btn {
        padding: 3px 9px;
        border: 1px solid #4a90e2;
        text-decoration: none;
        border-radius: 5px;
    }

    .action-btn:hover {
        color: #fff;
        background-color: #4a90e2;
    }
</style>
