<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <template v-if="isLoading = (isLoadingProductData || isLoadingReturnData || isLoadingAsset || isLoadingCustomAttributes || isLoadingCountryList)">
                <div class="container mt-5">
                    Loading product data...
                </div>
            </template>

            <template v-else-if="isSavingAsset">
                <div class="container mt-5">
                    Saving asset...
                </div>
            </template>

            <form v-else>
                <div class="scrollable-area">
                    <div class="scrollable-area--header" v-if="manifestId">
                        <div class="container">
                            <div class="col-12">
                                <div class="scrollable-area--subtitle">
                                    You're working on manifest
                                </div>

                                <div class="scrollable-area--title">
                                    {{ manifestCode }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="scrollable-content">
                        <div class="container">
                            <h3 class="section-title-sm mb-4">Receive asset</h3>

                            <div class="vertical-sets">
                                <div class="vertical-sets-left">
                                    <div class="form-group">
                                        <label class="label">
                                            SKU
                                        </label>

                                        <p>
                                            <template v-if="!isUnexpectedProduct && gradingProduct.SKU">
                                                {{ gradingProduct.SKU }}
                                            </template>

                                            <template v-else-if="gradingArticle && gradingArticle.RTN_ARL_SKU">
                                                {{ gradingArticle.RTN_ARL_SKU }}
                                            </template>

                                            <template v-else>
                                                -
                                            </template>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label class="label">
                                            Description
                                        </label>

                                        <p>
                                            <template v-if="!isUnexpectedProduct">
                                                {{ gradingProduct.DESCRIPTION }}
                                            </template>

                                            <template v-else>
                                                {{ gradingArticle.RTN_ARL_DESCRIPTION }}
                                            </template>
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label class="label">
                                            Receive instruction
                                        </label>

                                        <p>
                                            {{ unpackingInstructionMessage }}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <label class="label">Product image</label>

                                        <ImageGallery :images="unpackingInstructionImages" idAttribute="RTN_WFE_ID" urlAttribute="RTN_WFE_URL"></ImageGallery>
                                    </div>
                                </div>

                                <div class="vertical-sets-right">
                                    <div class="form-group">
                                        <label for="plate" class="label">
                                            License plate
                                        </label>

                                        <input id="plate" type="text" class="form-control" placeholder="License plate" v-model="licensePlate" ref="licensePlateInput">
                                        <InputFieldError attribute="LICENSE_PLATE" :errors="errors"/>
                                    </div>

                                    <div class="form-group">
                                        <label class="label">
                                            Country of origin
                                        </label>

                                        <v-select v-model="asset.COUNTRY_OF_ORIGIN" :filterable="true" :searchable="true"
                                                  :options="_.toPairs(countryList).map(a => { return {name: a[0], label: a[1]}; })"
                                                  :reduce="country => country.name"></v-select>

                                        <InputFieldError attribute="COUNTRY_OF_ORIGIN" :errors="errors"/>
                                    </div>

                                    <CustomAttributesForm :customAttributes="customAttributes" :errors="errors" ref="customAttrForm"/>
                                </div>
                            </div>
                        </div><!-- container -->
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back to overview
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="saveAsset()">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->
            </form>

        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import CustomAttributesForm from '@/components/_CustomAttributesForm.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';
    import vSelect from 'vue-select';
    import ImageGallery from '@/components/_ImageGallery.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'CustomAttributesForm': CustomAttributesForm,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
            'ImageGallery': ImageGallery,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                returnReference: this.$route.query.returnReference,
                manifestId: this.$route.query.manifestId,
                manifestCode: this.$route.query.manifestCode,
                manifestIsReadyToShip: this.$route.query.readyToShip,
                isLoading: false,
                isLoadingProductData: false,
                isLoadingReturnData: false,
                isLoadingUnpackingInstructions: false,
                isLoadingAsset: false,
                isLoadingCustomAttributes: false,
                isLoadingCountryList: false,
                isSavingAsset: false,
                isUnexpectedProduct: false,
                gradingProduct: null,
                gradingArticle: null,
                unpackingInstructionMessage: null,
                unpackingInstructionImages: null,
                asset: {},
                currentLocation: null,
                customAttributes: null,
                errors: {},
                countryList: null,
                clearLicensePlateOnInput: false,
                licensePlate: null,
                receivedReturn: null,
            };
        },
        created() {
            this.isLoadingProductData = true;
            this.isUnexpectedProduct = !this.$route.query.productId && this.$route.query.articleId;

            this.getReturnData();

            if (!this.isUnexpectedProduct) {
                this.$store.dispatch('products/getGradingProduct', this.$route.query.productId).then((data) => {
                    this.isLoadingProductData = false;
                    this.gradingProduct = data;

                    this.getProductInstructions();
                });
            } else {
                const url = new URL(this.$store.state.baseUrl + 'articles/get-by-id');
                url.searchParams.append('articleId', this.$route.query.articleId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingProductData = false;
                    this.gradingArticle = data.data;

                    this.getArticleInstructions();

                    if (!this.$route.query.assetId) {
                        this.getCustomAttributes();
                    }
                });
            }

            this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                this.currentLocation = data.value;
            });

            if (this.$route.query.assetId) {
                this.getAsset();
            } else {
                this.isLoadingAsset = true;
                let url = new URL(this.$store.state.baseUrl + 'assets/generate-license-plate');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingAsset = false;
                    this.asset.RETURN_ID = this.returnId;
                    this.licensePlate = data.data;

                    if (!this.isUnexpectedProduct) {
                        this.asset.PRODUCT_ID = this.$route.query.productId;
                        this.getCustomAttributes();
                    }
                });
            }

            this.getCountryList();
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'CheckReturnAssetOverview',
                    query: {returnId: this.returnId, returnReference: this.returnReference, manifestId: this.manifestId, manifestCode: this.manifestCode, readyToShip: this.manifestIsReadyToShip}
                });
            },
            next() {
                this.$router.push({
                    name: 'CheckReturnAssetOverview',
                    query: {returnId: this.returnId, returnReference: this.returnReference, manifestId: this.manifestId, manifestCode: this.manifestCode, readyToShip: this.manifestIsReadyToShip}
                });
            },
            getProductInstructions() {
                this.isLoadingUnpackingInstructions = true;

                this.$store.dispatch('products/getUnpackInstructions', [this.gradingProduct.PRODUCT_ID, this.gradingProduct.SKU]).then((data) => {
                    this.isLoadingUnpackingInstructions = false;

                    if (data) {
                        this.unpackingInstructionMessage = data.message;
                        this.unpackingInstructionImages = data.images;
                    }
                });
            },
            getArticleInstructions() {
                this.isLoadingUnpackingInstructions = true;

                const url = new URL(this.$store.state.baseUrl + 'products/get-instructions');
                url.searchParams.append('sku', this.gradingArticle.RTN_ARL_SKU);
                url.searchParams.append('type', 'unpack');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingUnpackingInstructions = false;

                    if (data.data) {
                        this.unpackingInstructionMessage = data.data.message;
                        this.unpackingInstructionImages = data.data.images;
                    }
                });
            },
            getAsset() {
                this.isLoadingAsset = true;
                let method;

                if (this.$route.query.assetId) {
                    method = this.$store.dispatch('assets/getAsset', [this.returnId, this.$route.query.assetId]);
                } else {
                    method = this.$store.dispatch('assets/getAsset', [this.returnId, null, this.$route.query.productId]);
                }

                method.then((data) => {
                    this.isLoadingAsset = false;
                    this.asset = data;
                    this.licensePlate = this.asset.LICENSE_PLATE;

                    this.getCustomAttributes();
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
            getCustomAttributes() {
                this.isLoadingCustomAttributes = true;
                let method;

                if (this.$route.query.assetId) {
                    method = this.$store.dispatch('assets/getCustomAttributes', [this.$route.query.assetId, null, null, 'RETURN_DOCK_RECEIVE_ASSET']);
                } else if (!this.isUnexpectedProduct) {
                    method = this.$store.dispatch('assets/getCustomAttributes', [null, this.returnId, this.$route.query.productId, 'RETURN_DOCK_RECEIVE_ASSET']);
                } else {
                    const url = new URL(this.$store.state.baseUrl + 'assets/get-custom-attributes');
                    url.searchParams.append('returnId', this.returnId);
                    url.searchParams.append('articleId', this.gradingArticle.RTN_ARL_ID);
                    url.searchParams.append('displayOption', 'RETURN_DOCK_RECEIVE_ASSET');
                    method = this.$http.get(url.href);
                }

                method.then((data) => {
                    this.isLoadingCustomAttributes = false;

                    if (!this.isUnexpectedProduct) {
                        this.customAttributes = data;
                    } else {
                        this.customAttributes = data.data;
                    }
                });
            },
            getReturnData() {
                this.isLoadingReturnData = true;

                this.$store.dispatch('returns/getReceivedReturn', this.returnId).then((data) => {
                    this.isLoadingReturnData = false;
                    this.receivedReturn = data;
                });
            },
            saveAsset() {
                let params = new URLSearchParams();
                let method;
                let url;

                params.append('Asset[RETURN_ID]', this.asset.RETURN_ID);
                params.append('Asset[ACCOUNT_ID]', this.$store.state.users.currentUser.ACCOUNT_ID);
                params.append('Asset[USER_ID_CREATED_BY]', this.$store.state.users.currentUser.ID);
                params.append('Asset[DISPOSITION_STATUS]', 'unpacked');
                params.append('Asset[LOCATION_ID]', this.currentLocation);
                params.append('Asset[CURRENT_LOCATION_ID]', this.currentLocation);
                params.append('Asset[LICENSE_PLATE]', this.licensePlate);

                if (this.asset.COUNTRY_OF_ORIGIN) {
                    params.append('Asset[COUNTRY_OF_ORIGIN]', this.asset.COUNTRY_OF_ORIGIN);
                }

                if (!this.isUnexpectedProduct) {
                    /* Retrieve data from the grading product */
                    params.append('Asset[PRODUCT_ID]', this.asset.PRODUCT_ID);

                    params.append('Asset[RETURN_REFERENCE]', this.receivedReturn.RETURN_REFERENCE);
                    params.append('Asset[RETURN_TYPE]', this.receivedReturn.RETURN_TYPE);
                    params.append('Asset[RETURNBIRD_ACCOUNT_ID]', this.receivedReturn.MERCHANT_ACCOUNT_ID);
                    params.append('Asset[RETURNBIRD_ACCOUNT_NAME]', this.receivedReturn.MERCHANT_ACCOUNT_NAME);
                    params.append('Asset[PORTAL_ID]', this.receivedReturn.PORTAL_ID);
                    params.append('Asset[PORTAL_NAME]', this.receivedReturn.PORTAL_NAME);
                    params.append('Asset[MERCHANT_SETUP_ID]', this.receivedReturn.MERCHANT_SETUP_ID);
                    params.append('Asset[MERCHANT_SETUP_NAME]', this.receivedReturn.MERCHANT_SETUP_NAME);

                    if (this.gradingProduct.SKU) {
                        params.append('Asset[SKU]', this.gradingProduct.SKU);
                    }

                    if (this.gradingProduct.ARTICLE_ID) {
                        params.append('Asset[ARTICLE_ID]', this.gradingProduct.ARTICLE_ID);
                    }

                    if (this.gradingProduct.ARTICLE_GROUP_ID) {
                        params.append('Asset[ARTICLE_GROUP_ID]', this.gradingProduct.ARTICLE_GROUP_ID);
                    }
                } else {
                    /* Retrieve data from the article and return model */
                    params.append('Asset[RETURN_REFERENCE]', this.receivedReturn.RETURN_REFERENCE);
                    params.append('Asset[RETURN_TYPE]', this.receivedReturn.RETURN_TYPE);
                    params.append('Asset[RETURNBIRD_ACCOUNT_ID]', this.receivedReturn.MERCHANT_ACCOUNT_ID);
                    params.append('Asset[RETURNBIRD_ACCOUNT_NAME]', this.receivedReturn.MERCHANT_ACCOUNT_NAME);
                    params.append('Asset[PORTAL_ID]', this.receivedReturn.PORTAL_ID);
                    params.append('Asset[PORTAL_NAME]', this.receivedReturn.PORTAL_NAME);
                    params.append('Asset[MERCHANT_SETUP_ID]', this.receivedReturn.MERCHANT_SETUP_ID);
                    params.append('Asset[MERCHANT_SETUP_NAME]', this.receivedReturn.MERCHANT_SETUP_NAME);
                    params.append('Asset[ARTICLE_ID]', this.gradingArticle.RTN_ARL_ID);

                    if (this.gradingArticle.RTN_ARL_SKU) {
                        params.append('Asset[SKU]', this.gradingArticle.RTN_ARL_SKU);
                    }

                    if (this.gradingArticle.RTN_ARP_ID) {
                        params.append('Asset[ARTICLE_GROUP_ID]', this.gradingArticle.RTN_ARP_ID);
                    }
                }

                params.append('Asset[CUSTOM_ATTRIBUTE_OPTION]', 'RETURN_DOCK_RECEIVE_ASSET');
                let customAttributesData = this.$refs.customAttrForm.getData();

                _.forOwn(customAttributesData, function (value, key) {
                    if (value !== null) {
                        params.append('Asset[' + key + ']', value);
                    }
                });

                this.isSavingAsset = true;

                if (this.asset.ID) {
                    url = new URL(this.$store.state.baseUrl + 'assets/save');
                    url.searchParams.set('assetId', this.asset.ID);
                    method = this.$http.post(url.href, params);
                } else {
                    url = new URL(this.$store.state.baseUrl + 'assets/save');
                    method = this.$http.post(url.href, params);
                }

                method.then((data) => {
                    this.isSavingAsset = false;

                    if (data.data.code === 100) {
                        let asset = data.data.data;
                        this.$store.commit('assets/addUnpackedAsset', asset);

                        /* Update custom attributes in store */
                        _.forOwn(customAttributesData, (value, key) => {
                            this.customAttributes[key].value = value;
                        });

                        this.$store.commit('assets/setCustomAttributes', [asset.ID, this.customAttributes]);
                        this.asset = asset;

                        this.next();
                    } else {
                        this.errors = data.data.errors;
                    }
                });
            }
        },
        watch: {
            isLoading(value) {
                if (value === false) {
                    /* Focus on the "scan product" input field and set the cursor position to the beginning */
                    this.$refs.licensePlateInput.focus();
                    this.$refs.licensePlateInput.selectionEnd = 0;
                    this.clearLicensePlateOnInput = true;
                }
            },
            licensePlate(value, oldValue) {
                if (value.indexOf('ASSET_') !== -1) {
                    /* Clear the default generated license plate the first time the field is updated. */
                    if (this.clearLicensePlateOnInput) {
                        this.licensePlate = this.licensePlate.replace(oldValue, '');
                    }

                    this.clearLicensePlateOnInput = false;
                }
            }
        }
    }
</script>

<style scoped>
    .section-title-sm {
        padding-left: 14px;
    }

    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 180px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }
</style>
