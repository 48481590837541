<template>
    <div>
        <HeaderBar title="Update profile"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <div class="col-10">
                    <div v-if="isSaving">
                        saving profile...
                    </div>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="first-name">
                                    First name:
                                </label>

                                <input type="text" class="form-control" v-model="user.firstName" id="first-name">

                                <InputFieldError attribute="FIRST_NAME" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="last-name">
                                    Last name:
                                </label>

                                <input type="text" class="form-control" v-model="user.lastName" id="last-name">

                                <InputFieldError attribute="LAST_NAME" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="password">
                                    Password:
                                </label>

                                <input type="password" autocomplete="off" class="form-control" v-model="user.password"
                                       id="password">

                                <InputFieldError attribute="PASSWORD" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="repeat-password">
                                    Repeat password:
                                </label>

                                <input type="password" autocomplete="off" class="form-control"
                                       v-model="user.repeatPassword" id="repeat-password">

                                <InputFieldError attribute="REPEAT_PASSWORD" :errors="errors"/>
                            </div>
                        </div>
                    </template>

                    <button type="button" name="button" v-if="!isSaving" v-on:click="save()" class="btn md btn-primary">
                        save
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError
        },
        data() {
            return {
                user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    password: '',
                    repeatPassword: ''
                },
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            this.$store.dispatch('users/getCurrentUser').then(() => {
                this.initLoaded = true;
                let user = this.$store.state.users.currentUser;

                this.user = {
                    id: user.ID,
                    firstName: user.FIRST_NAME,
                    lastName: user.LAST_NAME,
                    password: '',
                    repeatPassword: '',
                };
            });
        },
        methods: {
            save() {
                this.errors = {};

                if (this.user.password != this.user.repeatPassword) {
                    this.errors = {REPEAT_PASSWORD: 'Passwords do not match.'};
                    return false;
                }

                this.isSaving = true;

                let params = new URLSearchParams();
                let url = new URL(this.$store.state.baseUrl + 'agents/update-profile');

                params.append('AgentUser[FIRST_NAME]', this.user.firstName);
                params.append('AgentUser[LAST_NAME]', this.user.lastName);

                if (this.user.password) {
                    params.append('AgentUser[PASSWORD]', this.user.password);
                }

                this.$http.put(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else {
                        this.$store.commit('users/setCurrentUser', data.data.user);
                    }
                });
            }
        }
    }
</script>
