<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form action="" @submit.prevent="generateBarcodes()">
                            <div class="form-group">
                                <label class="label-title">
                                    Enter the codes for which you want to generate barcodes.
                                </label>

                                <InputFieldError attribute="general" :errors="errors"/>

                                <div v-for="(barcode, index) in barcodes" :key="index">
                                    <div class="barcode-input-row my-3">
                                        <input
                                            type="text"
                                            placeholder="Enter code"
                                            :id="`barcode_${index}`"
                                            class="form-control lg"
                                            v-model="barcode.value"
                                            :disabled="isGeneratingBarcodes"
                                        >

                                        <button
                                            class="btn btn-danger sm"
                                            @click.prevent="removeBarcode(index)"
                                            :disabled="isGeneratingBarcodes"
                                        >
                                            Delete
                                        </button>
                                    </div>

                                    <InputFieldError :attribute="`barcode_${index}`" :errors="errors"/>
                                </div>
                            </div>

                            <button
                                class="btn btn-secondary btn-md"
                                @click.prevent="addBarcode()"
                                :disabled="isGeneratingBarcodes"
                            >
                                Add additional barcode
                            </button>

                            <div class="my-5">
                                <button
                                    class="btn btn-primary btn-md"
                                    @click.prevent="generateBarcodes()"
                                    :disabled="isGeneratingBarcodes"
                                >
                                    Generate barcodes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingStorageDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            barcodes: [{value: ''}],
            isGeneratingBarcodes: false,
        };
    },
    created() {
        this.focusLastInputField();
    },
    methods: {
        validateForm() {
            this.errors = {};

            if (!this.barcodes?.length) {
                this.errors = {general: 'Please enter codes for which you want to generate barcodes.'};
                return false;
            }

            this.barcodes.forEach((barcode, index) => {
                if (!barcode?.value) {
                    this.errors[`barcode_${index}`] = 'Please enter a code.';
                }
            });

            return Object.keys(this.errors).length === 0;
        },
        generateBarcodes() {
            if (!this.validateForm()) {
                return;
            }

            this.isGeneratingBarcodes = true;

            const url = new URL(this.$store.state.baseUrl + 'pdf/generate-barcodes-document');
            url.searchParams.set('barcodes', this.barcodes.map((barcode) => barcode.value));

            this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                this.isGeneratingBarcodes = false;

                const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(documentBlob));
            });
        },
        addBarcode() {
            this.barcodes.push({value: ''});

            this.focusLastInputField();
        },
        removeBarcode(index) {
            this.barcodes.splice(index, 1);
        },
        focusLastInputField() {
            this.$nextTick(() => {
                document.getElementById(`barcode_${this.barcodes.length - 1}`)?.focus();
            });
        }
    }
}
</script>

<style scoped>
.barcode-input-row {
    display: flex;

    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>
