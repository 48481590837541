class ArrayStoreFormatter {
    /*
     * map numeric to key value
     */
    static format(array, key) {
        let result = {};

        for(let i in array) {
            let row = array[i];
            result[row[key]] = row;
        }
        return result;
    }
}

export default ArrayStoreFormatter;
