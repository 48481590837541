<template>
    <div>
        <HeaderBar title="Dashboard"></HeaderBar>

        <main class="main">
            <div class="dashboard-view">
                <div class="container">
                    <h2 class="section-title">Dashboard</h2>

                    <div class="row">
                        <template v-if="initLoaded">
                            <div class="col-12">
                                <div class="dashboard-card-horizontal">
                                    <div class="dashboard-card-horizontal-head">
                                        <div class="dashboard-card-horizontal-left">
                                            <div class="d-flex">
                                                <img :src="require('@/assets/images/colored-icons/inbound.svg')" class="img-fluid" alt="">

                                                <div class="dashboard-card-horizontal-title-text">
                                                    <span class="returns">RETURNS</span>
                                                    <span class="dashboard-card-horizontal-name">In transit</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dashboard-card-horizontal-right justify-content-end">
                                            <span class="total">{{ dashboardData.intransit.total }}</span>
                                        </div>
                                    </div><!-- dashboard-card-horizontal-head -->

                                    <div class="dashboard-card-horizontal-head title--last-update">
                                        Data last updated at {{ dashboardData.intransit.lastUpdate | formatDate }}
                                    </div>

                                    <div class="dashboard-card-horizontal-body">
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <div class="location-list">
                                                    <h5>
                                                        <i class="icon-gps"></i>
                                                        <span>Locations</span>
                                                    </h5>

                                                    <ul>
                                                        <li v-for="(locationStat, key) in dashboardData.intransit.locations" v-bind:key="key">
                                                            <div class="dashboard-card-horizontal-left">
                                                                <span>{{ locationStat.name }}</span>
                                                            </div>

                                                            <div class="dashboard-card-horizontal-right">
                                                                <span class="total">{{ locationStat.count }}</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="account-list">
                                                    <h5>
                                                        <i class="icon-clients"></i>
                                                        <span>Accounts</span>
                                                    </h5>

                                                    <ul>
                                                        <li v-for="(customerStat, key) in dashboardData.intransit.customers" v-bind:key="key">
                                                            <div class="dashboard-card-horizontal-left">
                                                                <span>{{ customerStat.name }}</span>
                                                            </div>

                                                            <div class="dashboard-card-horizontal-right">
                                                                <span class="total">{{ customerStat.count }}</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- dashboard-card-horizontal-body -->
                                </div><!-- dashboard-card-horizontal -->
                            </div>
                        </template>

                        <template v-else>
                            <p class="ml-3">Loading dashboard data...</p>
                        </template>
                    </div><!-- container -->
                </div><!-- dashboard-view -->
            </div>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main><!-- main -->
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        name: 'DashboardPage',
        components: {
            'HeaderBar': HeaderBar
        },
        data() {
            return {
                initLoaded: false,
                dashboardData: null,
            }
        },
        created() {
            let url = new URL(this.$store.state.baseUrl + 'dashboard/get-data');

            this.$http.get(url.href).then((data) => {
                this.dashboardData = data.data.data;
                this.initLoaded = true;
            });
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'ModuleSelect',
                });
            }
        },
        filters: {
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const hours = ('0' + date.getHours()).slice(-2);
                const minutes = ('0' + date.getMinutes()).slice(-2);

                return hours + ':' + minutes + ' UTC';
            }
        }
    }
</script>
