<template>
    <div>
        <HeaderBar v-bind:title="$route.query.userId ? 'Agent update' : 'Agent create'"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="User" activeItem="Create"></SettingsMenu>

                <div class="col-10">
                    <div v-if="isSaving">
                        Saving agent...
                    </div>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="email">
                                    Email
                                </label>

                                <input type="text" class="form-control" v-model="user.email" id="email">

                                <InputFieldError attribute="EMAIL" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="first-name">
                                    First name
                                </label>

                                <input type="text" class="form-control" v-model="user.firstName" id="first-name">

                                <InputFieldError attribute="FIRST_NAME" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="last-name">
                                    Last name
                                </label>

                                <input type="text" class="form-control" v-model="user.lastName" id="last-name">

                                <InputFieldError attribute="LAST_NAME" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div v-if="Object.keys(userTypeOptions).length > 0">
                                    <label for="user-type">
                                        User type
                                    </label>

                                    <model-list-select
                                            v-bind:list="userTypeOptions"
                                            option-value="value"
                                            option-text="label"
                                            v-model="user.userType"
                                            placeholder="select type"
                                            id="user-type"
                                            required
                                    >
                                    </model-list-select>

                                    <InputFieldError attribute="TYPE" :errors="errors"/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="my-3" v-if="Object.keys(roleOptions).length > 0">
                                    <label for="user-role">
                                        Role
                                    </label>

                                    <model-list-select
                                            v-bind:list="roleOptions"
                                            option-value="value"
                                            option-text="label"
                                            v-model="user.roleId"
                                            placeholder="select role"
                                            id="user-role"
                                            required
                                    >
                                    </model-list-select>

                                    <InputFieldError attribute="ROLE_ID" :errors="errors"/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <button type="button" name="button" v-if="!isSaving" v-on:click="save()" class="btn md btn-primary">
                        Save
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'SettingsMenu': SettingsMenu,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    roleId: '',
                    userType: ''
                },
                userTypeOptions: [
                    {value: 4, label: 'operator'},
                    {value: 3, label: 'admin'}
                ],
                roleOptions: [],
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            let rolesPromise = this.$store.dispatch('roles/getAll');
            let agentsPromise = this.$store.dispatch('users/getAgents');


            Promise.all([agentsPromise, rolesPromise]).then((data) => {
                this.initLoaded = true;

                for (let key in data[1]) {
                    let role = data[1][key];
                    this.roleOptions.push({value: role.ID, label: role.NAME});
                }

                if (this.$route.query.userId) {
                    let user = this.$store.state.users.agents[this.$route.query.userId];
                    this.user = {
                        id: user.ID,
                        firstName: user.FIRST_NAME,
                        lastName: user.LAST_NAME,
                        email: user.EMAIL,
                        roleId: parseInt(user.ROLE_ID),
                        userType: parseInt(user.TYPE)
                    };
                }
            });
        },
        methods: {
            save() {
                let params = new URLSearchParams();
                let method;
                let url;

                params.append('AgentUser[FIRST_NAME]', this.user.firstName);
                params.append('AgentUser[LAST_NAME]', this.user.lastName);
                params.append('AgentUser[EMAIL]', this.user.email);
                params.append('AgentUser[ROLE_ID]', this.user.roleId);
                params.append('AgentUser[TYPE]', this.user.userType);

                this.isSaving = true;

                if (this.user.id) {
                    url = new URL(this.$store.state.baseUrl + 'agents/update-agent-user');
                    url.searchParams.append('id', this.user.id);
                    method = this.$http.put(url.href, params);
                } else {
                    url = new URL(this.$store.state.baseUrl + 'agents/create-agent-user');
                    method = this.$http.post(url.href, params);
                }

                method.then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else {
                        this.$store.commit('users/setAgent', data.data.user);
                        this.$router.push({name: 'UserOverview'});
                    }
                });
            }
        }
    }
</script>
