<template>
    <LeftMenuBase v-bind:menu="menu"></LeftMenuBase>
</template>


<script>
    import LeftMenuBase from './_LeftMenuBase';

    export default {
        components: {
            'LeftMenuBase': LeftMenuBase
        },
        props: ['activeCategory', 'activeItem', 'roleId'],
        data() {
            return {
                menu: {
                    categories: {
                        Role: {
                            name: 'Role',
                            link: '/roleOverview',
                            items: {
                                Overview: { link: '/roleOverview' },
                                General: { link: '/roleUpdate?roleId=' + this.roleId },
                            }
                        },
                        Access: {
                            name: 'Role access',
                            link: '/roleUpdate?roleId=' + this.roleId,
                            items: {
                                Locations: { link: '/roleLocationAccess?roleId=' + this.roleId },
                                LocationAccounts: {
                                    name: 'Location accounts',
                                    link: '/roleLocationAccountAccess?roleId=' + this.roleId
                                },
                                Modules: {
                                    link: '/roleModuleAccess?roleId=' + this.roleId
                                }
                            }
                        }
                    }
                }
            };
        },
        created() {
            this.menu['activeCategory'] = this.activeCategory;
            this.menu['activeItem'] = this.activeItem;
        }
    }
</script>
