<template>
    <div>
        <HeaderBar title="Container"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <template v-if="isLoadingContainer">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="scan-manisfest-container">
                                    Loading container...
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content">
                            <div class="container">
                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>License plate</th>
                                                <th>Account</th>
                                                <th>Storage</th>
                                                <th>Disposition type</th>
                                                <th>Assets</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ container.LICENSE_PLATE }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                        {{ container.RETURNBIRD_ACCOUNT_NAME | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ container.STORAGE_TYPE }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="container.DISPOSITION_TYPE">
                                                                        {{ container.DISPOSITION_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ container.assets.length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5" class="pt-4 pb-0">
                                                                    <div class="d-flex align-items-center justify-content-end pt-3">
                                                                        <div>
                                                                            <span>
                                                                                Container Status
                                                                            </span>

                                                                            <p class="text-red" v-if="container.IS_FULL == 1">
                                                                                Full
                                                                            </p>

                                                                            <p class="text-green" v-else>
                                                                                Available
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="5" class="pt-0" v-if="container.assets && Object.keys(container.assets).length > 0">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="w-auto">SKU</th>
                                                                                <th class="w-auto">License plate</th>
                                                                                <th class="w-auto">Grade</th>
                                                                                <th class="w-auto" v-if="storage === 'AFS'">Revalued grade</th>
                                                                                <th class="w-auto">Received Date</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="asset in container.assets" :key="asset.ID">
                                                                                <template>
                                                                                    <td class="w-auto">
                                                                                        <template v-if="asset.SKU">
                                                                                            {{ asset.SKU }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            -
                                                                                        </template>
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ asset.LICENSE_PLATE }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ asset.GRADE }}
                                                                                    </td>

                                                                                    <td class="w-auto" v-if="storage === 'AFS'">
                                                                                        {{ asset.REWORKED_GRADE }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ asset.CREATION | formatDate }}
                                                                                    </td>
                                                                                </template>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td colspan="5" class="pt-0" v-else>
                                                                    No assets added to this container.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!-- scrollable-content -->
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
        },
        data() {
            return {
                storage: this.$route.params.storage,
                isLoadingContainer: false,
                containerId: this.$route.query.id,
                container: null,
            };
        },
        created() {
            this.getContainer();
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'InventoryContainer',
                    query: {id: this.containerId}
                });
            },
            getContainer() {
                this.isLoadingContainer = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/get-by-id');
                url.searchParams.append('id', this.containerId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainer = false;
                    this.container = data.data;
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                return day + '-' + month + '-' + year;
            }
        },
    }
</script>

<style scoped>
    input[type="text"] {
        background-color: #fff;
    }
</style>
