<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <section class="section-dashboard">
                <div class="container">
                    <div class="d-flex flex-wrap justify-content-between mb-5">
                        <h2 class="section-title">
                            Return storage
                        </h2>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <InputFieldError attribute="global" :errors="errors"/>
                        </div>
                    </div>

                    <template v-if="isLoadingSubmodules || isLoadingSelectedLocation">
                        Loading submodules...
                    </template>

                    <template v-else-if="(!canViewManifests && !canViewContainers &&!canViewWorkOrders) || !selectedLocation">
                        No submodules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="canViewManifests">
                                <a href="#" @click.prevent="goToSubmodule('ManifestOverview')" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">VIEW MANIFESTS</span>
                                </a>
                            </div>

                            <div class="card-item-wrapper" v-if="canViewContainers">
                                <a href="#" @click.prevent="goToSubmodule('StorageAFD')" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">AVAILABLE FOR DISPOSITION (AFD)</span>
                                </a>
                            </div>

                            <div class="card-item-wrapper" v-if="canViewContainers">
                                <a href="#" @click.prevent="goToSubmodule('StorageAFS')" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">AVAILABLE FOR SALES (AFS)</span>
                                </a>
                            </div>
                        </div>

                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="canViewWorkOrders">
                                <a href="#" @click.prevent="goToSubmodule('WorkOrderOverview')" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">WORK ORDER OVERVIEW</span>
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        name: 'DashboardPage',
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                isLoadingSubmodules: false,
                moduleAccess: {},
                canViewManifests: false,
                canViewContainers: false,
                canViewWorkOrders: false,
                isLoadingSelectedLocation: false,
                selectedLocation: null,
                errors: {},
            };
        },
        created() {
            this.isLoadingSubmodules = true;

            this.getSelectedLocation();
        },
        mounted() {
            this.$root.$on('switchedLocation', () => {
                this.errors = {};
                this.getSelectedLocation();
            });

            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingSubmodules = false;
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['return']?.['storage'];
                this.canViewManifests = typeof this.moduleAccess['view-manifests'] !== 'undefined' && this.moduleAccess['view-manifests'].ACCESS;
                this.canViewContainers = typeof this.moduleAccess['view-containers'] !== 'undefined' && this.moduleAccess['view-containers'].ACCESS;
                this.canViewWorkOrders = typeof this.moduleAccess['view-work-orders'] !== 'undefined' && this.moduleAccess['view-work-orders'].ACCESS;
            });
        },
        methods: {
            goToSubmodule(submodule) {
                if (!this.selectedLocation) {
                    this.errors = {global: 'Select a location.'};
                    return false;
                }

                this.$router.push({
                    name: submodule,
                });
            },
            getSelectedLocation() {
                this.isLoadingSelectedLocation = true;

                this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                    this.isLoadingSelectedLocation = false;
                    this.selectedLocation = data;
                });
            },
            previous() {
                this.$router.push({
                    name: 'ReturnModuleSelect',
                });
            }
        }
    }
</script>
