<template>
    <div>
        <HeaderBar title="Asset search"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="scanAsset()">
                <div class="scrollable-area">
                    <div class="scrollable-content" ref="scrollableContent">
                        <div class="container">
                            <div class="form-group my-0">
                                <label for="license-plate" class="label-title">
                                    Scan to search asset
                                </label>

                                <input id="license-plate" type="text" placeholder="Scan asset license plate"
                                       class="form-control lg" ref="scanAssetInput" v-model="assetLicensePlate">
                            </div>

                            <div class="row my-3">
                                <div class="col-12">
                                    <InputFieldError attribute="assetLicensePlate" :errors="errors"/>
                                </div>

                                <div class="col-12 my-3" v-if="isSearchingAsset">
                                    Loading asset {{ assetLicensePlate }}...
                                </div>
                            </div>

                            <div class="row" v-for="skuItem in skus" :key="skuItem.sku">
                                <div class="col-12">
                                    <div class="sku-row px-3 my-2">
                                        <div class="row sku-row--header py-3" @click.prevent="toggleShowAssets(skuItem.sku)">
                                            <div class="col-8 col-md-3 order-1">
                                                <div class="form-group mb-0 pl-md-4">
                                                    <label class="label">
                                                        SKU
                                                    </label>

                                                    <p class="mb-0">
                                                        {{ skuItem.sku }}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-5 order-3 order-md-2 mt-3 mt-md-0">
                                                <div class="form-group mb-0">
                                                    <label class="label">
                                                        Description
                                                    </label>

                                                    <p class="mb-0">
                                                        <template v-if="skuItem.description">
                                                            {{ skuItem.description }}
                                                        </template>

                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="col-4 col-md-4 order-2 order-md-3">
                                                <div class="d-flex justify-content-between">
                                                    <div class="form-group mb-0">
                                                        <label class="label">
                                                            On hand
                                                        </label>

                                                        <p class="mb-0">
                                                            {{ skuItem.onHandCount }}
                                                        </p>
                                                    </div>

                                                    <template v-if="storage === 'AFD'">
                                                        <div class="form-group mb-0">
                                                            <label class="label">
                                                                Reserved
                                                            </label>

                                                            <p class="mb-0">
                                                                {{ skuItem.reservedCount }}
                                                            </p>
                                                        </div>

                                                        <div class="form-group mb-0">
                                                            <label class="label">
                                                                In progress
                                                            </label>

                                                            <p class="mb-0">
                                                                {{ skuItem.inProgressCount }}
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <template v-else-if="storage === 'AFS'">
                                                        <div class="form-group mb-0">
                                                            <label class="label">
                                                                Expected
                                                            </label>

                                                            <p class="mb-0">
                                                                {{ skuItem.expectedCount }}
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <div class="form-group mb-0">
                                                        <label class="label">
                                                            Available
                                                        </label>

                                                        <p class="mb-0">
                                                            <template v-if="storage === 'AFD'">
                                                                {{ skuItem.onHandCount - skuItem.reservedCount - skuItem.inProgressCount }}
                                                            </template>

                                                            <template v-else-if="storage === 'AFS'">
                                                                {{ skuItem.onHandCount - skuItem.expectedCount }}
                                                            </template>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="asset-rows px-3 pb-3" v-if="skuItem.isShowingAssets">
                                            <table class="table-third-level interactable mb-3" v-if="assetsPerSku && assetsPerSku[skuItem.sku] && assetsPerSku[skuItem.sku].assets.length > 0">
                                                <thead>
                                                    <tr>
                                                        <th class="w-auto">
                                                            License plate
                                                        </th>

                                                        <th class="w-auto">
                                                            Received grade
                                                        </th>

                                                        <th class="w-auto" v-if="storage === 'AFS'">
                                                            Revalued grade
                                                        </th>

                                                        <th class="w-auto">
                                                            Container
                                                        </th>

                                                        <th class="w-auto">
                                                            Status
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr v-for="asset in assetsPerSku[skuItem.sku].assets" :key="asset.ID" @click.prevent="viewAsset(asset.ID)">
                                                        <td class="w-auto">
                                                            {{ asset.LICENSE_PLATE }}
                                                        </td>

                                                        <td class="w-auto">
                                                            <template v-if="asset.GRADE">
                                                                {{ asset.GRADE }}
                                                            </template>

                                                            <template v-else>
                                                                -
                                                            </template>
                                                        </td>

                                                        <td class="w-auto" v-if="storage === 'AFS'">
                                                            <template v-if="asset.REWORKED_GRADE">
                                                                {{ asset.REWORKED_GRADE }}
                                                            </template>

                                                            <template v-else>
                                                                -
                                                            </template>
                                                        </td>

                                                        <td class="w-auto">
                                                            <template v-if="asset.container">
                                                                {{ asset.container.LICENSE_PLATE }}
                                                            </template>

                                                            <template v-else>
                                                                -
                                                            </template>
                                                        </td>

                                                        <td class="w-auto">
                                                            {{ getAssetStatus(asset) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <template v-if="assetsPerSku[skuItem.sku].assets.length > 0">
                                                <p class="mt-4">
                                                    Showing {{ assetsPerSku[skuItem.sku].assets.length }} of {{ skuItem.onHandCount }} assets.
                                                </p>
                                            </template>

                                            <template v-if="!assetPagination.paginationPerSku[skuItem.sku].hasRetrievedAll && !assetPagination.paginationPerSku[skuItem.sku].isLoading">
                                                <div class="d-flex justify-content-center" ref="loadAsset" :id="skuItem.sku">
                                                    <button type="button" class="btn btn-primary py-2" @click.prevent="getAssets(skuItem.sku)">
                                                        Show more assets
                                                    </button>
                                                </div>
                                            </template>

                                            <template v-if="assetPagination.paginationPerSku[skuItem.sku] && assetPagination.paginationPerSku[skuItem.sku].isLoading">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p class="mb-0 text-center">
                                                            Loading assets with SKU {{ skuItem.sku }}...
                                                        </p>
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else-if="!assetsPerSku[skuItem.sku] || !assetsPerSku[skuItem.sku].assets || assetsPerSku[skuItem.sku].assets.length === 0">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p class="mb-0 text-center">
                                                            No assets found with SKU {{ skuItem.sku }}.
                                                        </p>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center mt-3" v-if="!skuPagination.hasRetrievedAll && !skuPagination.isLoading" ref="loadSkus">
                                <button type="button" class="btn btn-primary py-2" @click.prevent="getSkus()">
                                    Show more SKUs
                                </button>
                            </div>

                            <template v-if="skuPagination.isLoading">
                                <p class="mt-3">
                                    Loading assets...
                                </p>
                            </template>

                            <template v-else-if="skus.length === 0">
                                <p class="mt-3">
                                    No assets available.
                                </p>
                            </template>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import _ from 'lodash';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            storage: this.$route.params.storage,
            isSearchingAsset: false,
            selectedLocationId: null,
            assetLicensePlate: null,
            skuPagination: {
                isLoading: false,
                hasRetrievedAll: false,
                limit: 10,
                offset: 0,
            },
            assetPagination: {
                limit: 10,
                paginationPerSku: {}
            },
            skus: [],
            assetsPerSku: {},
            isScrollEventOnCooldown: false,
        };
    },
    mounted() {
        /* Focus on the "Scan asset" input field on page load */
        this.$refs.scanAssetInput.focus();
    },
    created() {
        this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
            if (data && data.value) {
                this.selectedLocationId = data.value;
            }
        });

        this.getSkus();

        this.$nextTick(() => {
            if (this.$refs && this.$refs.scrollableContent) {
                this.$refs.scrollableContent.addEventListener('scroll', this.performLazyLoading);
            }
        });
    },
    destroyed() {
        if (this.$refs && this.$refs.scrollableContent) {
            this.$refs.scrollableContent.removeEventListener('scroll', this.performLazyLoading);
        }
    },
    methods: {
        scanAsset() {
            this.errors = {};
            this.isSearchingAsset = true;

            let url = new URL(this.$store.state.baseUrl + 'assets/find-by-license-plate');
            url.searchParams.append('licensePlate', this.assetLicensePlate);

            this.$http.get(url.href).then((data) => {
                this.isSearchingAsset = false;

                if (_.isEmpty(data.data) || !data.data.ID) {
                    this.errors = {assetLicensePlate: 'No asset found with license plate ' + this.assetLicensePlate};
                } else if (data.data.STORAGE_TYPE !== this.storage) {
                    this.errors = {assetLicensePlate: 'Asset "' + data.data.LICENSE_PLATE + '" is in the ' + data.data.STORAGE_TYPE + ' storage.'};
                } else {
                    this.$store.commit('assets/setAssetToView', data.data);

                    this.$router.push({
                        name: 'Asset',
                        query: {id: data.data.ID}
                    });
                }
            });
        },
        previous() {
            if (this.storage === 'AFD') {
                this.$router.push({
                    name: 'StorageAFD',
                });
            } else if (this.storage === 'AFS') {
                this.$router.push({
                    name: 'StorageAFS',
                });
            } else {
                this.$router.push({
                    name: 'Storage',
                });
            }
        },
        toggleShowAssets(sku) {
            const index = this.skus.findIndex((item) => item.sku === sku);

            if (index > -1) {
                if (this.skus[index].isShowingAssets) {
                    this.skus[index].isShowingAssets = false;
                } else {
                    this.skus[index].isShowingAssets = true;

                    if (!this.assetsPerSku || !this.assetsPerSku[sku] || this.assetsPerSku[sku].assets.length === 0) {
                        this.getAssets(sku);
                    }
                }
            }
        },
        getAssets(sku) {
            if (!this.assetPagination.paginationPerSku || !this.assetPagination.paginationPerSku[sku]) {
                return;
            }

            if (this.assetPagination.paginationPerSku[sku].hasRetrievedAll) {
                /* All the assets with this SKU have already been retrieved. */
                return;
            }

            this.assetPagination.paginationPerSku[sku].isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-by-sku');
            url.searchParams.append('storageType', this.storage);
            url.searchParams.append('sku', sku);
            url.searchParams.append('limit', this.assetPagination.limit);
            url.searchParams.append('offset', this.assetPagination.paginationPerSku[sku].offset);

            this.$http.get(url.href).then((data) => {
                this.assetPagination.paginationPerSku[sku].isLoading = false;

                if (data.status === 200 && data.data) {
                    this.assetPagination.paginationPerSku[sku].offset += data.data.length;

                    if (data.data.length < this.assetPagination.limit) {
                        this.assetPagination.paginationPerSku[sku].hasRetrievedAll = true;
                    }

                    for (const asset of data.data) {
                        this.assetsPerSku[sku].assets.push(asset);
                    }
                }
            });
        },
        getSkus() {
            if (this.skuPagination.hasRetrievedAll) {
                /* All the SKUs have already been retrieved. */
                return;
            }

            this.skuPagination.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-skus');
            url.searchParams.append('storageType', this.storage);
            url.searchParams.append('limit', this.skuPagination.limit);
            url.searchParams.append('offset', this.skuPagination.offset);

            this.$http.get(url.href).then((data) => {
                this.skuPagination.isLoading = false;

                if (data.status === 200 && data.data) {
                    this.skuPagination.offset += data.data.length;

                    if (data.data.length < this.skuPagination.limit) {
                        this.skuPagination.hasRetrievedAll = true;
                    }

                    for (const item of data.data) {
                        this.skus.push({
                            sku: item.SKU,
                            description: item.DESCRIPTION,
                            onHandCount: item.onHandCount,
                            reservedCount: item.reservedCount ?? 0,
                            inProgressCount: item.inProgressCount ?? 0,
                            expectedCount: item.expectedCount ?? 0,
                            isShowingAssets: false,
                        });

                        this.$set(this.assetPagination.paginationPerSku, item.SKU, {
                            isLoading: false,
                            hasRetrievedAll: false,
                            offset: 0,
                        });

                        this.$set(this.assetsPerSku, item.SKU, {
                            assets: [],
                        });
                    }
                }
            });
        },
        viewAsset(assetId) {
            this.$router.push({
                name: 'Asset',
                query: {id: assetId}
            });
        },
        performLazyLoading() {
            if (this.isScrollEventOnCooldown) {
                return;
            }

            this.performSkusLazyLoading();
            this.performAssetLazyLoading();

            this.isScrollEventOnCooldown = true;

            setTimeout(() => {
                this.isScrollEventOnCooldown = false;
            }, 100);
        },
        performSkusLazyLoading() {
            if (this.$refs && this.$refs['loadSkus']) {
                let rect = this.$refs['loadSkus'].getBoundingClientRect();

                if (rect.bottom > 0 &&
                    rect.right > 0 &&
                    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                    rect.top < (window.innerHeight || document.documentElement.clientHeight)) {
                    this.getSkus();
                }
            }
        },
        performAssetLazyLoading() {
            if (this.$refs && this.$refs['loadAsset'] && this.$refs['loadAsset'].length > 0) {
                for (const element of this.$refs['loadAsset']) {
                    let rect = element.getBoundingClientRect();

                    if (rect.bottom > 0 &&
                        rect.right > 0 &&
                        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                        rect.top < (window.innerHeight || document.documentElement.clientHeight)) {
                        this.getAssets(element.id);
                    }
                }
            }
        },
        getAssetStatus(asset) {
            if (asset) {
                const assetFlags = {
                    IS_ARCHIVED: 'Archived',
                    IS_EXPECTED: 'Expected',
                    IS_IN_PROGRESS: 'In progress',
                    IS_RESERVED: 'Reserved',
                    IS_ON_HAND: 'Available',
                };

                for (const [attribute, label] of Object.entries(assetFlags)) {
                    if (asset?.[attribute] === 1 || asset?.[attribute] === '1') {
                        return label;
                    }
                }
            }

            return '-';
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
}
</script>

<style scoped lang="scss">
    .scan-manisfest-container {
        margin-top: 0;
        padding: 30px;
    }

    .sku-row {
        background: #fff;
        border: 1px solid #d9d7e4;
        border-radius: 13px;

        &--header {
            cursor: pointer;

            &:hover {
                background: darken(#fff, 3%);
            }
        }
    }

    .btn--toggle-assets {
        width: 35px;
        height: 35px;
        font-size: 20px;
    }
</style>
