<template>
    <tbody>
        <tr v-if="hasErrors">
            <td colspan="5" class="pb-0">
                <InputFieldError attribute="parcelShipment" :errors="allErrors"></InputFieldError>
            </td>
        </tr>

        <tr>
            <td colspan="5" class="py-0">
                <template v-if="Object.keys(parcelManifests).length === 0">
                    <button type="button"
                            class="btn btn-danger sm mt-4"
                            @click.prevent="isShowingDeleteShipmentModal = true"
                    >
                        Delete
                    </button>

                    <p class="pt-4 mb-0">
                        There are no parcel manifests on this shipment.
                    </p>
                </template>

                <template v-else>
                    <table class="table-third-level">
                        <thead>
                            <tr>
                                <th class="w-auto">
                                    Manifest
                                </th>

                                <th class="w-auto">
                                    Manifest ID
                                </th>

                                <th class="w-auto">
                                    Parcels
                                </th>

                                <th class="w-auto"></th>

                                <th class="w-auto"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="manifest in parcelManifests" :key="manifest.ID">
                                <template v-if="manifestsBeingRemoved.includes(manifest.ID)">
                                    <td colspan="5" class="w-auto">
                                        Removing parcel manifest {{ manifest.MANIFEST_CODE }} from this parcel shipment...
                                    </td>
                                </template>

                                <template v-else>
                                    <td class="w-auto">
                                        {{ manifest.MANIFEST_CODE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ manifest.ID }}
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="manifest.parcelManifestRows">
                                            {{ manifest.parcelManifestRows.length }}
                                        </template>

                                        <template v-else>
                                            -
                                        </template>
                                    </td>

                                    <td class="w-auto">
                                        <template
                                            v-if="manifestDocumentsBeingGenerated && manifestDocumentsBeingGenerated.includes(manifest.ID)">
                                            Generating manifest list...
                                        </template>

                                        <template v-else>
                                            <a href="#" @click.prevent="getManifestListPdf(manifest.ID)">
                                                Manifest list
                                            </a>
                                        </template>
                                    </td>

                                    <td class="w-auto text-right">
                                        <a href="#" @click.prevent="showRemoveManifestFromShipmentModal(manifest)"
                                           class="action-btn">
                                            &times;
                                        </a>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </td>
        </tr>

        <tr>
            <td colspan="5" class="pb-0">
                <p class="m-0 action-link" v-b-toggle="'available-manifests-to-add-dropdown'">
                    <span class="when-open">
                        Hide available manifests
                    </span>

                    <span class="when-closed">
                        Add manifest
                    </span>
                </p>
            </td>
        </tr>

        <tr>
            <td colspan="5">
                <b-collapse id="available-manifests-to-add-dropdown">
                    <template v-if="isLoadingManifestsReadyToShip">
                        Loading available parcel manifests...
                    </template>

                    <template v-else-if="filteredManifestsReadyToShip.length === 0">
                        There are no available parcel manifests to add to this shipment.
                    </template>

                    <template v-else-if="isAddingManifests">
                        Adding manifests to this shipment...
                    </template>

                    <template v-else>
                        <table class="table-third-level">
                            <thead>
                                <tr>
                                    <th class="w-20 pr-0"></th>

                                    <th class="w-auto pl-0">
                                        Manifest
                                    </th>

                                    <th class="w-auto">
                                        Manifest ID
                                    </th>

                                    <th class="w-auto">
                                        Parcels
                                    </th>

                                    <th class="w-auto"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="manifest in filteredManifestsReadyToShip" :key="manifest.ID">
                                    <td class="w-20 pr-0">
                                        <label class="checkbox-container mb-20 mr-1">
                                            <input type="checkbox" v-model="selectedParcelManifests[manifest.ID]"
                                                   :disabled="isAddingManifests">
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>

                                    <td class="w-auto pl-0">
                                        {{ manifest.MANIFEST_CODE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ manifest.ID }}
                                    </td>

                                    <td class="w-auto">
                                        {{ manifest.parcelManifestRows.length }}
                                    </td>

                                    <td class="w-auto">
                                        <template
                                            v-if="manifestDocumentsBeingGenerated && manifestDocumentsBeingGenerated.includes(manifest.ID)">
                                            Generating manifest list...
                                        </template>

                                        <template v-else>
                                            <a href="#" @click.prevent="getManifestListPdf(manifest.ID)">
                                                Manifest list
                                            </a>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button class="btn btn-primary btn-md mt-3" :disabled="selectedManifests.length === 0"
                                @click.prevent="addManifestsToShipment()">
                            Add manifests
                        </button>
                    </template>
                </b-collapse>
            </td>
        </tr>

        <ConfirmationPopup v-if="isShowingRemoveManifestFromShipmentModal"
                           :message="'Are you sure you want to remove parcel manifest ' + manifestToRemove.MANIFEST_CODE + ' from this shipment?'"
                           @confirm="removeManifestFromShipment()"
                           @deny="isShowingRemoveManifestFromShipmentModal = false">
        </ConfirmationPopup>

        <ConfirmationPopup v-if="isShowingDeleteShipmentModal"
                           :message="'Are you sure you want to delete parcel shipment ' + parcelShipment.REFERENCE + '?'"
                           :loadingMessage="'Deleting shipment ' + parcelShipment.REFERENCE + '...'"
                           :isLoading="isDeletingShipment"
                           @confirm="deleteShipment()"
                           @deny="isShowingDeleteShipmentModal = false">
        </ConfirmationPopup>
    </tbody>
</template>

<script>
import {BCollapse, VBToggle} from 'bootstrap-vue';
import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    props: [
        'parcelShipment',
        'errors'
    ],
    components: {
        'b-collapse': BCollapse,
        'ConfirmationPopup': ConfirmationPopup,
        'InputFieldError': InputFieldError,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            localErrors: {},
            isShowingDeleteShipmentModal: false,
            isDeletingShipment: false,
            isShowingRemoveManifestFromShipmentModal: false,
            manifestToRemove: null,
            manifestsBeingRemoved: [],
            isAddingManifests: false,
            selectedParcelManifests: [],
            manifestsReadyToShip: [],
            isLoadingManifestsReadyToShip: false,
            manifestDocumentsBeingGenerated: [],
            parcelManifests: [],
        };
    },
    created() {
        this.parcelManifests = this.parcelShipment.parcelManifests;
        this.getManifestsReadyToShip();
    },
    methods: {
        previous() {
            console.log(this.parcelShipment)
            if (this.$route.query.origin) {
                if (this.$route.query.origin === 'shipSorted') {
                    this.$router.push({
                        name: 'ShipParcelManifest',
                        query: {
                            type: 'sorted',
                            carrierLocationId: this.parcelShipment.DESTINATION_CARRIER_LOCATION_ID
                        }
                    });
                    return;
                } else if (this.$route.query.origin === 'shipUnsorted') {
                    this.$router.push({
                        name: 'ShipParcelManifest',
                        query: {
                            type: 'unsorted'
                        }
                    });
                    return;
                }
            }

            this.$router.push({
                name: 'ParcelShipmentOverview',
            });
        },
        getManifestListPdf(manifestId) {
            this.localErrors = {};
            this.manifestDocumentsBeingGenerated.push(manifestId);

            const url = new URL(this.$store.state.baseUrl + 'pdf/parcel-manifest');
            url.searchParams.append('id', manifestId);

            this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                let index = this.manifestDocumentsBeingGenerated.indexOf(manifestId);
                this.manifestDocumentsBeingGenerated.splice(index, 1);

                let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(containerDocumentBlob));
            });
        },
        removeManifestFromShipment() {
            this.localErrors = {};
            this.isShowingRemoveManifestFromShipmentModal = false;
            this.manifestsBeingRemoved.push(this.manifestToRemove.ID.toString());

            const url = new URL(this.$store.state.baseUrl + 'parcel-shipments/remove-parcel-manifest-from-shipment');
            const params = new URLSearchParams();
            params.append('ParcelShipment[ParcelManifestID]', this.manifestToRemove.ID);
            params.append('ParcelShipment[ParcelShipmentID]', this.parcelShipment.ID);

            this.$http.post(url.href, params).then((data) => {
                if (data.data.code === 100) {
                    const index = this.parcelManifests.findIndex((manifest) => manifest.ID.toString() === this.manifestToRemove.ID.toString());

                    if (index > -1) {
                        this.parcelManifests.splice(index, 1);
                    }

                    this.getManifestsReadyToShip();
                } else if (data.data.code === 422) {
                    this.localErrors = {parcelShipment: data.data.errors};
                }

                let index = this.manifestsBeingRemoved.indexOf(this.manifestToRemove.ID.toString());
                this.manifestsBeingRemoved.splice(index, 1);
                this.manifestToRemove = null;
            });
        },
        showRemoveManifestFromShipmentModal(manifest) {
            this.manifestToRemove = manifest;
            this.isShowingRemoveManifestFromShipmentModal = true;
        },
        getManifestsReadyToShip() {
            this.isLoadingManifestsReadyToShip = true;
            const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/get-all-ready-to-ship');
            url.searchParams.set('carrierLocationId', this.parcelShipment?.DESTINATION_CARRIER_LOCATION_ID);

            this.manifestsReadyToShip = null;

            this.$http.get(url.href).then((data) => {
                this.isLoadingManifestsReadyToShip = false;
                this.manifestsReadyToShip = data.data;
            });
        },
        addManifestsToShipment() {
            this.isAddingManifests = true;

            let selectedManifestIds = [];

            for (let i in this.selectedManifests) {
                selectedManifestIds.push(this.selectedManifests[i].ID);
            }

            const url = new URL(this.$store.state.baseUrl + 'parcel-shipments/add-parcel-manifests-to-shipment');
            const params = new URLSearchParams();
            params.append('ParcelShipment[ID]', this.parcelShipment.ID);
            params.append('ParcelShipment[parcelManifestIds]', selectedManifestIds);

            this.$http.post(url.href, params).then((data) => {
                this.isAddingManifests = false;

                if (data.data.code === 100) {
                    for (let i in this.selectedManifests) {
                        this.parcelManifests.push(this.selectedManifests[i]);
                    }

                    this.selectedParcelManifests = [];
                    this.getManifestsReadyToShip();
                } else if (data.data.code === 422) {
                    this.localErrors = {parcelShipment: data.data.errors};
                }
            });
        },
        deleteShipment() {
            this.localErrors = {};
            this.isDeletingShipment = true;

            const url = new URL(this.$store.state.baseUrl + 'parcel-shipments/delete-parcel-shipment');
            const params = new URLSearchParams();
            params.append('ParcelShipment[ID]', this.parcelShipment.ID);

            this.$http.post(url.href, params).then((data) => {
                this.isDeletingShipment = false;
                this.isShowingDeleteShipmentModal = false;

                if (data.data.code === 100) {
                    this.previous();
                } else if (data.data.code === 422) {
                    this.localErrors = {parcelShipment: data.data.errors};
                }
            });
        },
    },
    computed: {
        hasErrors() {
            return this.errors && Object.keys(this.errors).length > 0;
        },
        manifestArray() {
            if (!this.manifestsReadyToShip) {
                return [];
            }

            let result = [];

            for (let i in this.manifestsReadyToShip) {
                const manifest = this.manifestsReadyToShip[i];

                if (`${manifest.SORTING_TYPE}-parcel-manifest` !== this.parcelShipment.TYPE) {
                    continue;
                }

                if (this.parcelShipment.SORTING_TYPE === 'sorted-parcel-manifest' && manifest.MERCHANT_ACCOUNT_ID !== this.parcelShipment.MERCHANT_ACCOUNT_ID) {
                    console.log('no 2')
                    continue;
                }

                result.push(manifest);
            }

            return result;
        },
        filteredManifestsReadyToShip() {
            if (!this.manifestArray || this.manifestArray.length === 0) {
                return [];
            }

            let result = [];

            for (let i in this.manifestArray) {
                const manifest = this.manifestArray[i];

                if (this.showSelectedManifestsOnly && !this.selectedManifests.includes(manifest)) {
                    continue;
                }

                result.push(manifest);
            }

            return result;
        },
        selectedManifests() {
            if (Object.keys(this.selectedParcelManifests).length === 0) {
                return [];
            }

            let result = [];

            for (let i = 0; i < Object.keys(this.selectedParcelManifests).length; i++) {
                const key = Object.keys(this.selectedParcelManifests)[i];
                const value = Object.values(this.selectedParcelManifests)[i];

                if (value) {
                    const manifest = this.manifestsReadyToShip.find(manifest => manifest.ID == key);

                    if (manifest) {
                        result.push(manifest);
                    }
                }
            }

            return result;
        },
        allErrors() {
            return {...this.errors, ...this.localErrors};
        }
    }
}
</script>

<style scoped>
.action-btn {
    padding: 3px 9px;
    border: 1px solid #4a90e2;
    text-decoration: none;
    border-radius: 5px;
}

.action-btn:hover {
    color: #fff;
    background-color: #4a90e2;
}

.collapsed > .when-open, .not-collapsed > .when-closed {
    display: none;
}

.action-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.action-link:hover {
    color: #0056b3;
    text-decoration: underline;
}
</style>
