<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <form action="" @submit.prevent="scanShipment()">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="form-group">
                                <label for="shipment-code" class="label-title">
                                    Scan to view parcel shipment
                                </label>

                                <input type="text"
                                       placeholder="Scan parcel shipment number"
                                       class="form-control lg"
                                       id="shipment-code"
                                       ref="scanParcelShipmentInput"
                                       v-model="parcelShipmentReference"
                                >
                            </div>

                            <div class="scan-manisfest-container">
                                <div class="row">
                                    <template v-if="isLoadingShipments">
                                        Loading parcel shipments...
                                    </template>

                                    <template v-else-if="Object.keys(parcelShipments).length === 0">
                                        There are no parcel shipments.
                                    </template>

                                    <template v-else>
                                        <div class="col-sm-6 col-lg-3" v-for="(parcelShipment, key) in parcelShipments"
                                             :key="key">
                                            <router-link
                                                class="text-decoration-none scan-manifest-card-link"
                                                :to="{name: 'ShipParcelShipment', query: {reference: parcelShipment.ID}}"
                                            >
                                                <div class="scan-manisfest-card">
                                                    <div class="scan-manisfest-card-header orange">
                                                        <h4>
                                                            {{ parcelShipment.REFERENCE }}
                                                        </h4>

                                                        <span class="badge">
                                                            {{ parcelShipment.parcelManifests.length }}
                                                        </span>
                                                    </div>

                                                    <div class="scan-manisfest-card-body" ref="shipmentCardBody"
                                                         :style="{'height': shipmentCardBodyHeight}"
                                                    >
                                                        <div>
                                                            <span class="label">
                                                                Shipment ID
                                                            </span>

                                                            <span class="content">
                                                                {{ parcelShipment.ID }}
                                                            </span>
                                                        </div>

                                                        <div class="mt-4">
                                                            <span class="label">
                                                                Destination
                                                            </span>

                                                            <span class="content">
                                                                {{ parcelShipment.DESTINATION_NAME }}
                                                            </span>
                                                        </div>

                                                        <div class="mt-4">
                                                            <span class="label">
                                                                Type
                                                            </span>

                                                            <span class="content">
                                                                {{ getTypeLabel(parcelShipment.TYPE) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <router-link :to="{name: 'ShippingOutboundDashboard'}" class="btn btn-secondary">
                                    Back
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';

export default {
    components: {
        'HeaderBar': HeaderBar,
    },
    data() {
        return {
            errors: {},
            isLoadingShipments: false,
            isLoadingCountryList: false,
            parcelShipmentReference: null,
            parcelShipments: null,
            shipmentCardBodyHeight: null,
        };
    },
    mounted() {
        /* Focus on the "Scan manifest" input field on page load */
        this.$refs.scanParcelShipmentInput.focus();
    },
    created() {
        this.getShipments();
    },
    methods: {
        getShipments() {
            this.isLoadingShipments = true;

            let url = new URL(this.$store.state.baseUrl + 'parcel-shipments/get-all');

            this.$http.get(url.href).then((data) => {
                this.isLoadingShipments = false;
                this.parcelShipments = data.data;

                this.$nextTick(() => {
                    if (this.$refs.shipmentCardBody) {
                        let scrollHeight = 0;

                        for (let i = 0; i < this.$refs.shipmentCardBody.length; i++) {
                            if (this.$refs.shipmentCardBody[i].scrollHeight > scrollHeight) {
                                scrollHeight = this.$refs.shipmentCardBody[i].scrollHeight;
                            }
                        }

                        this.shipmentCardBodyHeight = scrollHeight + 'px';
                    }
                });
            });
        },
        scanShipment() {
            this.$router.push({
                name: 'ShipParcelShipment',
                query: {reference: this.parcelShipmentReference}
            });
        },
        getTypeLabel(type) {
            switch (type) {
                case 'sorted-parcel-manifest':
                    return 'Sorted parcel manifest';

                case 'unsorted-parcel-manifest':
                    return 'Unsorted parcel manifest';

                default:
                    return '';
            }
        }
    },
}
</script>

<style scoped>

</style>
