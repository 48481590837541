import axios from '@/classes/Axios';

const vendorStore = {
    namespaced: true,
    state: {
        vendors: {},
        vendorEntityLocations: {},
    },
    mutations: {
        setVendors(state, [merchantId, vendors]) {
            state.vendors[merchantId] = vendors;
        },
        setVendorEntityLocations(state, [vendorEntityId, locations]) {
            state.vendorEntityLocations[vendorEntityId] = locations;
        },
        clear(state) {
            state.vendors = {};
        }
    },
    actions: {
        getVendors(storeArgObj, merchantId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.vendors?.[merchantId]?.length > 0) {
                    resolve(storeArgObj.state.vendors[merchantId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'work-orders/get-vendors');
                    url.searchParams.set('merchantId', merchantId);

                    axios.get(url.href).then((data) => {
                        const vendors = data?.data;

                        storeArgObj.commit('setVendors', [merchantId, vendors]);
                        resolve(vendors);
                    });
                }
            });
        },
        getVendorEntityLocations(storeArgObj, vendorEntityId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.vendorEntityLocations?.[vendorEntityId]?.length > 0) {
                    resolve(storeArgObj.state.vendorEntityLocations[vendorEntityId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'work-order/get-vendor-entity-locations');
                    url.searchParams.append('vendorEntityId', vendorEntityId);

                    axios.get(url.href).then((data) => {
                        const locations = data?.data;

                        storeArgObj.commit('setVendorEntityLocations', [vendorEntityId, locations]);
                        resolve(locations);
                    });
                }
            });
        }
    }
};

export default vendorStore;
