import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './classes/Axios';
import VueAxios from 'vue-axios';
import WindowEventManager from './classes/WindowEventManager';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import _ from 'lodash';
import VueIntercom from 'vue-intercom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import '@/assets/sass/style.scss';
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

Vue.prototype._ = _;

Vue.use(VueAxios, axios);
Vue.use(WindowEventManager.plugin);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueIntercom, {appId: 'u65jlkxx'});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
