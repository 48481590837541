<template>
    <div>
        <HeaderBar title="Manifest overview"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="scanShipment()">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="form-group">
                                <label for="shipment-code" class="label-title">
                                    Scan to view shipment
                                </label>

                                <input type="text" placeholder="Scan shipment number" class="form-control lg"
                                       id="shipment-code" ref="scanShipmentInput" v-model="shipmentReference">
                            </div>

                            <div class="scan-manisfest-container">
                                <div class="row">
                                    <template v-if="isLoadingShipments">
                                        Loading shipments...
                                    </template>

                                    <template v-else-if="Object.keys(shipments).length === 0">
                                        There are no shipments.
                                    </template>

                                    <template v-else>
                                        <div class="col-sm-6 col-lg-3" v-for="(shipment, key) in shipments" :key="key">
                                            <router-link class="text-decoration-none scan-manifest-card-link" :to="{name: 'ShipShipment', query: {reference: shipment.ID}}">
                                                <div class="scan-manisfest-card">
                                                    <div class="scan-manisfest-card-header orange">
                                                        <h4>
                                                            {{ shipment.REFERENCE }}
                                                        </h4>

                                                        <span class="badge">
                                                            <template v-if="shipment.TYPE === 'sorted-manifest' || shipment.TYPE === 'unsorted-manifest'">
                                                                {{ shipment.returnManifests.length }}
                                                            </template>

                                                            <template v-else-if="shipment.TYPE === 'container'">
                                                                {{ shipment.containers.length }}
                                                            </template>

                                                            <template v-else-if="shipment.TYPE === 'work-order'">
                                                                {{ shipment.workOrders.length }}
                                                            </template>
                                                        </span>
                                                    </div>

                                                    <div class="scan-manisfest-card-body" ref="shipmentCardBody" :style="{'height': shipmentCardBodyHeight}">
                                                        <div>
                                                            <span class="label">Shipment ID</span>

                                                            <span class="content">
                                                                {{ shipment.ID }}
                                                            </span>
                                                        </div>

                                                        <div class="mt-4">
                                                            <span class="label">Destination</span>

                                                            <span class="content">
                                                                {{ shipment.DESTINATION_NAME }}
                                                            </span>
                                                        </div>

                                                        <div class="mt-4">
                                                            <span class="label">Type</span>

                                                            <span class="content">
                                                                {{ getTypeLabel(shipment.TYPE) }}
                                                            </span>
                                                        </div>

                                                        <template v-if="shipment.SORTING_TYPE && shipment.SORTING_TYPE == 'sorted'">
                                                            <div class="mt-4">
                                                                <span class="label">Account</span>

                                                                <span class="content">
                                                                    {{ shipment.MERCHANT_ACCOUNT_NAME }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
        },
        data() {
            return {
                errors: {},
                isLoadingShipments: false,
                isLoadingCountryList: false,
                shipmentReference: null,
                shipments: null,
                shipmentCardBodyHeight: null,
            };
        },
        mounted() {
            /* Focus on the "Scan manifest" input field on page load */
            this.$refs.scanShipmentInput.focus();
        },
        created() {
            this.getShipments();
        },
        methods: {
            getShipments() {
                this.isLoadingShipments = true;

                let url = new URL(this.$store.state.baseUrl + 'shipments/get-all');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingShipments = false;
                    this.shipments = data.data;

                    this.$nextTick(() => {
                        if (this.$refs.shipmentCardBody) {
                            let scrollHeight = 0;

                            for (let i = 0; i < this.$refs.shipmentCardBody.length; i++) {
                                if (this.$refs.shipmentCardBody[i].scrollHeight > scrollHeight) {
                                    scrollHeight = this.$refs.shipmentCardBody[i].scrollHeight;
                                }
                            }

                            this.shipmentCardBodyHeight = scrollHeight + 'px';
                        }
                    });
                });
            },
            scanShipment() {
                this.$router.push({
                    name: 'ShipShipment',
                    query: {reference: this.shipmentReference}
                });
            },
            previous() {
                this.$router.push({
                    name: 'Outbound',
                });
            },
            getTypeLabel(type) {
                switch (type) {
                    case 'sorted-manifest':
                        return 'Sorted manifest';

                    case 'unsorted-manifest':
                        return 'Unsorted manifest';

                    case 'container':
                        return 'Container';

                    case 'work-order':
                        return 'Work order';

                    default:
                        return '';
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped>

</style>
