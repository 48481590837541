<template>
    <div>
        <HeaderBar title="Manifest"></HeaderBar>

        <main class="main">

            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">

                            <div class="table-responsive">
                                <template v-if="isLoadingManifest">
                                    Loading manifest...
                                </template>

                                <template v-else-if="!manifest || Object.keys(manifest).length === 0">
                                    No manifest found with code {{ manifestCode }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Manifest</th>
                                                <th>Manifest ID</th>
                                                <th>Sorting type</th>
                                                <th v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">Account</th>
                                                <th>Returns</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ manifest.MANIFEST_CODE }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.ID }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="manifest.SORTING_TYPE">
                                                                        {{ manifest.SORTING_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'sorted'">
                                                                    {{ manifest.MERCHANT_ACCOUNT_NAME }}
                                                                </th>

                                                                <th>
                                                                    {{ Object.keys(manifest.unshippedReturnManifestRows).length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <template v-if="isMergingManifest">
                                                                <tr>
                                                                    <td colspan="6" class="pt-5 pb-3">
                                                                        Merging manifests...
                                                                    </td>
                                                                </tr>
                                                            </template>

                                                            <template v-else>
                                                                <tr>
                                                                    <td colspan="6" class="pt-5 pb-3">
                                                                        <template v-if="manifest.IS_READY_TO_SHIP == 1">
                                                                            This manifest is ready to ship and can't be merged.
                                                                        </template>

                                                                        <template v-else-if="isLoadingAvailableTargetManifests">
                                                                            Loading available manifests...
                                                                        </template>

                                                                        <template v-else-if="availableTargetManifests.length === 0">
                                                                            There are no available manifests to merge with.
                                                                        </template>

                                                                        <template v-else>
                                                                            <p>
                                                                                Select a manifest to merge with
                                                                            </p>

                                                                            <model-list-select
                                                                                    :list="availableTargetManifests"
                                                                                    class="target-manifest-list"
                                                                                    option-value="value"
                                                                                    option-text="label"
                                                                                    v-model="targetManifestId"
                                                                                    required
                                                                            >
                                                                            </model-list-select>
                                                                        </template>
                                                                    </td>
                                                                </tr>

                                                                <template v-if="targetManifestId">
                                                                    <tr>
                                                                        <td colspan="6" class="pt-3 pb-0">
                                                                            <template v-if="manifest.shippedReturnManifestRows && manifest.shippedReturnManifestRows.length > 0">
                                                                                This manifest has already been partially shipped. If all returns are merged, this manifest will be marked as shipped.
                                                                            </template>

                                                                            <template v-else>
                                                                                If all returns are merged, this manifest ({{ manifest.MANIFEST_CODE }}) will be deleted.
                                                                            </template>
                                                                        </td>
                                                                    </tr>

                                                                    <template v-if="!manifest.unshippedReturnManifestRows || manifest.unshippedReturnManifestRows.length === 0">
                                                                        <tr>
                                                                            <td colspan="6" class="pb-3">
                                                                                No returns added to this manifest.
                                                                            </td>
                                                                        </tr>
                                                                    </template>

                                                                    <template v-else>
                                                                        <tr>
                                                                            <td colspan="6" class="pb-3">
                                                                                <label class="checkbox-container mb-0 ml-4"
                                                                                       @click.prevent="toggleAllCheckboxes()">
                                                                                    Select all

                                                                                    <input type="checkbox" v-model="selectedAll">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td colspan="6" class="pt-0">
                                                                                <table class="table-third-level">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th class="w-20 pr-0"></th>

                                                                                            <th class="w-auto pl-0">
                                                                                                Return
                                                                                            </th>

                                                                                            <th class="w-auto">
                                                                                                External Reference
                                                                                            </th>

                                                                                            <th class="w-auto">
                                                                                                Received Date
                                                                                            </th>

                                                                                            <th class="w-auto" v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'unsorted'">
                                                                                                Account
                                                                                            </th>

                                                                                            <th class="w-auto">
                                                                                                Channel
                                                                                            </th>

                                                                                            <th class="w-auto">
                                                                                                Return Policy
                                                                                            </th>

                                                                                            <th class="w-auto">
                                                                                                Country
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody>
                                                                                        <tr v-for="(returnManifestRow, key) in manifest.unshippedReturnManifestRows"
                                                                                            :key="key">
                                                                                            <td class="w-20 pr-0">
                                                                                                <label class="checkbox-container mb-20 mr-1">
                                                                                                    <input type="checkbox"
                                                                                                           v-model="selectedReturnManifestRows[returnManifestRow.ID]">
                                                                                                    <span class="checkmark"></span>
                                                                                                </label>
                                                                                            </td>

                                                                                            <td class="w-auto pl-0">
                                                                                                <template v-if="returnManifestRow.RETURN_REFERENCE">
                                                                                                    {{ returnManifestRow.RETURN_REFERENCE }}
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    {{ returnManifestRow.SHIPMENT_TRACKING_CODE }}
                                                                                                </template>
                                                                                            </td>

                                                                                            <td class="w-auto">
                                                                                                <template v-if="returnManifestRow.RETURN_EXTERNAL_REFERENCE">
                                                                                                    {{ returnManifestRow.RETURN_EXTERNAL_REFERENCE }}
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    -
                                                                                                </template>
                                                                                            </td>

                                                                                            <td class="w-auto">
                                                                                                {{ returnManifestRow.CREATION | formatDate }}
                                                                                            </td>

                                                                                            <td class="w-auto" v-if="manifest.SORTING_TYPE && manifest.SORTING_TYPE == 'unsorted'">
                                                                                                <template v-if="returnManifestRow.MERCHANT_ACCOUNT_NAME">
                                                                                                    {{ returnManifestRow.MERCHANT_ACCOUNT_NAME }}
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    -
                                                                                                </template>
                                                                                            </td>

                                                                                            <td class="w-auto">
                                                                                                <template v-if="returnManifestRow.PORTAL_NAME">
                                                                                                    {{ returnManifestRow.PORTAL_NAME }}
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    -
                                                                                                </template>
                                                                                            </td>

                                                                                            <td class="w-auto">
                                                                                                <template v-if="returnManifestRow.MERCHANT_SETUP">
                                                                                                    {{ returnManifestRow.MERCHANT_SETUP }}
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    -
                                                                                                </template>
                                                                                            </td>

                                                                                            <td class="w-auto">
                                                                                                <template v-if="returnManifestRow.RETURN_ORIGIN_COUNTRY">
                                                                                                    <template v-if="countryList && countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY]">
                                                                                                        {{ countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY] }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        {{ returnManifestRow.RETURN_ORIGIN_COUNTRY }}
                                                                                                    </template>
                                                                                                </template>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td colspan="6" class="pt-0">
                                                                                <InputFieldError attribute="global" :errors="errors"/>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="mergeManifest()" v-if="targetManifestId && manifest.unshippedReturnManifestRows && manifest.unshippedReturnManifestRows.length > 0" :disabled="isMergingManifest">
                                    Merge
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                errors: {},
                isLoadingManifest: false,
                isLoadingCountryList: false,
                isLoadingAvailableTargetManifests: false,
                isDeletingManifest: false,
                isMergingManifest: false,
                manifestCode: this.$route.query.code,
                manifest: null,
                targetManifestId: null,
                selectedLocation: null,
                countryList: null,
                showDeleteManifestPopup: false,
                selectedReturnManifestRows: {},
                availableTargetManifests: [],
            };
        },
        created() {
            this.isLoadingManifest = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;
                }

                this.getManifest();
            });

            this.getCountryList();
        },
        methods: {
            getManifest() {
                this.isLoadingManifest = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-return-manifest');
                url.searchParams.append('manifestCode', this.manifestCode);
                url.searchParams.append('locationId', this.selectedLocation);
                url.searchParams.append('showReadyToShip', true);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data.data.data;

                    /* Pre-populate selected return manifest rows */
                    _.forOwn(this.manifest.unshippedReturnManifestRows, (returnManifestRow) => {
                        this.$set(this.selectedReturnManifestRows, returnManifestRow.ID, false);
                    });

                    this.getAvailableTargetManifests();
                });
            },
            getAvailableTargetManifests() {
                this.isLoadingAvailableTargetManifests = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-all');
                url.searchParams.append('locationId', this.selectedLocation);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingAvailableTargetManifests = false;

                    if (data.data) {
                        _.forOwn(data.data, (manifest) => {
                            if (this.manifest.SORTING_TYPE == manifest.SORTING_TYPE && this.manifest.ID != manifest.ID && manifest.IS_READY_TO_SHIP == 0 && this.manifest.MERCHANT_ACCOUNT_ID == manifest.MERCHANT_ACCOUNT_ID) {
                                this.availableTargetManifests.push({value: parseInt(manifest.ID), label: manifest.MANIFEST_CODE});
                            }
                        });
                    }
                });
            },
            previous() {
                this.$router.push({
                    name: 'Manifest',
                    query: {code: this.manifestCode}
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
            deleteManifest() {
                this.errors = {};
                this.isDeletingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/delete-manifest');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifest.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingManifest = false;
                    this.showDeleteManifestPopup = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestDelete: data.data.errors};
                    } else {
                        this.$router.push({
                            name: 'ManifestOverview',
                        });
                    }
                });
            },
            toggleAllCheckboxes() {
                if (this.manifest.unshippedReturnManifestRows) {
                    let selectedAll = this.selectedAll;

                    _.forOwn(this.selectedReturnManifestRows, (value, key) => {
                        this.$set(this.selectedReturnManifestRows, key, !selectedAll);
                    });
                }
            },
            mergeManifest() {
                this.errors = {};
                this.isMergingManifest = true;
                let returnManifestRowIds = [];

                /* Collect the IDs of the ReturnManifestRows which should be updated */
                _.forOwn(this.selectedReturnManifestRows, (selectedReturnManifestRow, key) => {
                    if (selectedReturnManifestRow) {
                        returnManifestRowIds.push(key);
                    }
                });

                if (!this.selectedAny) {
                    this.errors = {global: 'Select one or more returns to merge'};
                    this.isMergingManifest = false;
                    return false;
                }

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/merge-manifest');
                const params = new URLSearchParams();
                params.append('ReturnManifest[CurrentManifestID]', this.manifest.ID);
                params.append('ReturnManifest[TargetManifestID]', this.targetManifestId);
                params.append('ReturnManifest[ReturnManifestRowIDs]', returnManifestRowIds);

                this.$http.post(url.href, params).then((data) => {
                    this.isMergingManifest = false;

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    } else if (data.data.code === 302) {
                        /* The manifest has been emptied and closed, redirect to the manifest overview */
                        this.$router.push({
                            name: 'ManifestOverview',
                        });
                    } else {
                        /* Refresh the data */
                        this.manifest = data.data.manifest;
                        this.selectedReturnManifestRows = {};

                        /* Pre-populate selected return manifest rows */
                        _.forOwn(this.manifest.unshippedReturnManifestRows, (returnManifestRow) => {
                            this.$set(this.selectedReturnManifestRows, returnManifestRow.ID, false);
                        });
                    }
                });
            }
        },
        computed: {
            /* Make sure the "Select all" checkbox is ticked when all returns are selected. */
            selectedAll() {
                let count = 0;

                _.forOwn(this.selectedReturnManifestRows, (selectedReturnManifestRow) => {
                    if (selectedReturnManifestRow) {
                        count++;
                    }
                });

                return count === Object.keys(this.selectedReturnManifestRows).length;
            },
            selectedAny() {
                let count = 0;

                _.forOwn(this.selectedReturnManifestRows, (selectedReturnManifestRow) => {
                    if (selectedReturnManifestRow) {
                        count++;
                    }
                });

                return count > 0;
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            formatDate: function (value) {
                if (!value) return '';

                const date = new Date(value);
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                return day + '-' + month + '-' + year;
            }
        },
        watch: {
            selectedReturnManifestRows: {
                handler() {
                    /* Reset errors when the selected return manifest rows changes. */
                    this.errors = {};
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    h5 {
        font-weight: 600;
    }

    .table-responsive {
        overflow: visible;
    }

    .target-manifest-list {
        max-width: 300px;
    }

    .label-title {
        padding-left: 0;
        font-weight: 600;
        font-size: 14px;
    }
</style>
