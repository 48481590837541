<template>
    <div class="login-wrapper">
        <div class="login-container">
            <template v-if="isValidDomain">
                <div class="return-top-title">
                    <span class="d-inline-block pl-2">
                        <template v-if="accountLogo">
                            <img :src="accountLogo" class="account-logo">
                        </template>

                        <template v-else>
                            Return Dock
                        </template>
                    </span>
                </div>

                <div class="login-title">
                    Welcome Back
                </div>

                <div class="login-sub-title">
                    Sign In To Your Account
                </div>

                <div class="form-group">
                    <InputFieldError attribute="global" :errors="errors" :key="errors.global"/>
                </div>

                <form action="#">
                    <template v-if="isLoggingIn">
                        <div class="form-group text-center">
                            Logging in...
                        </div>
                    </template>

                    <template v-else-if="isLoadingAvailableLocations || isLoadingCurrentLocation">
                        <div class="form-group text-center">
                            Loading available locations...
                        </div>
                    </template>

                    <template v-else-if="isUpdatingLocation">
                        <div class="form-group text-center">
                            {{ updatingLocationMessage }}
                        </div>
                    </template>

                    <template v-else-if="requiresLocationSelection">
                        <div class="form-group">
                            <label for="locationSelection">
                                Select a location
                            </label>

                            <v-select :options="_.toPairs(availableLocations).map(a => { return {id: a[0], label: a[1].name}; })"
                                      v-model="selectedLocation" :clearable="false" id="locationSelection">
                            </v-select>
                        </div>

                        <div class="form-group text-center">
                            <button type="button" class="btn btn-primary btn-lg" @click.prevent="selectLocation()" :disabled="!selectedLocation">
                                {{ loginButtonMessage }}
                            </button>
                        </div>
                    </template>

                    <template v-else>
                        <div class="form-group">
                            <label for="email" class="label">Email address</label>

                            <input id="email" type="email" v-model="user.email" class="form-control"
                                   placeholder="Enter your email">
                        </div>

                        <div class="form-group">
                            <label for="password" class="label">Password</label>

                            <input v-bind:type="this.passwordFieldType" v-model="user.password" id="password"
                                   class="form-control password" placeholder="Enter your password">
                        </div>

                        <div class="form-group">
                            <label class="checkbox-container">
                                Keep me signed in

                                <input type="checkbox" checked="checked" v-model="keepUserSignedIn">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <div class="form-group text-center">
                            <button type="submit" v-on:click.prevent="login()" name="button" class="btn btn-primary btn-lg">
                                Login
                            </button>
                        </div>

                        <div class="form-group text-center">
                            <router-link :to="{name: 'ForgotPassword'}" class="forgot-passw">
                                <button type="submit" name="button" class="btn btn-secondary btn-lg">
                                    I FORGOT MY PASSWORD
                                </button>
                            </router-link>
                        </div>
                    </template>
                </form>
            </template>

            <template v-else>
                <div class="d-flex justify-content-center mb-3">
                    <img :src="require('@/assets/images/colored-icons/in-process.svg')" class="img-fluid" alt="">
                </div>

                <div class="text-center pt-3">
                    This account is not active or does not exist.
                </div>
            </template>
        </div><!-- login-container -->
    </div><!-- login-wrapper -->

</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';

    export default {
        name: 'LoginPage',
        components: {
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        data() {
            return {
                isLoggingIn: false,
                isLoadingAvailableLocations: false,
                isLoadingCurrentLocation: false,
                isValidDomain: true,
                errors: {},
                user: {
                    email: '',
                    password: '',
                },
                passwordFieldType: 'password',
                requiresLocationSelection: false,
                availableLocations: null,
                currentLocation: null,
                selectedLocation: null,
                showLocationDropdown: false,
                isUpdatingLocation: false,
                loginButtonMessage: 'Connect',
                updatingLocationMessage: 'Logging in...',
                keepUserSignedIn: false,
                accountLogo: null,
            }
        },
        created() {
            // let url = new URL(this.$store.state.baseUrl + 'accounts/is-valid-domain');
            //
            // this.$http.get(url.href).then((data) => {
            //     if (data.data.code === 100) {
            //         this.isValidDomain = data.data.data;
            //     } else {
            //         this.isValidDomain = false;
            //     }
            // });

            this.getAccountLogo();
        },
        mounted() {
            this.$windowEventManager.addEventListener('keydown', (event) => {
                if (event.keyCode === 13) {
                    this.login();
                }
            });
        },
        methods: {
            login() {
                this.isLoggingIn = true;
                this.errors = {};

                if (!this.user.email || !this.user.password) {
                    this.errors.global = 'Email or password incorrect, please try again';
                    this.isLoggingIn = false;
                    return false;
                }

                let url = new URL(this.$store.state.baseUrl + 'users/authenticate');
                let params = new URLSearchParams();
                params.append('user[email]', this.user.email);
                params.append('user[password]', this.user.password);
                params.append('user[keepUserSignedIn]', this.keepUserSignedIn);

                this.$http.post(
                    url.href,
                    params
                ).then((data) => {
                    this.isLoggingIn = false;

                    if (data.data.code === 100) {
                        let user = data.data.data.user;
                        this.$store.commit('users/setCurrentUser', user);

                        /* Detect if the user has access to multiple locations. */
                        this.isLoadingCurrentLocation = true;
                        this.isLoadingAvailableLocations = true;

                        let getSelectedLocationUrl = this.$store.dispatch('userSettings/getSelectedLocation', module);
                        let getAvailableLocationsUrl = this.$store.dispatch('roleAccess/getAvailableLocations');

                        Promise.all([getSelectedLocationUrl, getAvailableLocationsUrl]).then((data) => {
                            this.isLoadingCurrentLocation = false;
                            this.isLoadingAvailableLocations = false;

                            this.currentLocation = data[0];
                            this.availableLocations = data[1];

                            this.requiresLocationSelection = Object.keys(this.availableLocations).length > 1;

                            /* If the user doesn't have access to any location, unset the currently selected location if there is one. */
                            if (Object.keys(this.availableLocations).length === 0) {
                                if (this.currentLocation) {
                                    this.isUpdatingLocation = true;
                                    this.updatingLocationMessage = 'Logging in without a selected location...';
                                    let url = new URL(this.$store.state.baseUrl + 'user-settings/' + this.currentLocation.id);

                                    this.$http.delete(url.href).then(() => {
                                        this.$store.commit('userSettings/clear');

                                        this.$router.push({
                                            name: 'ModuleSelect'
                                        });
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'ModuleSelect'
                                    });
                                }
                            }
                            /* If the user has access to only 1 location, set it as the selected location for the user. */
                            else if (Object.keys(this.availableLocations).length === 1) {
                                if (!this.currentLocation || this.currentLocation.value != Object.keys(this.availableLocations)[0]) {
                                    this.selectedLocation = {
                                        id: Object.keys(this.availableLocations)[0],
                                        label: Object.values(this.availableLocations)[0].name
                                    };
                                    this.selectLocation();
                                } else {
                                    this.$router.push({
                                        name: 'ModuleSelect'
                                    });
                                }
                            }
                        });
                    } else {
                        this.errors.global = data.data.message;
                    }
                });
            },
            togglePasswordShow() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            },
            selectLocation() {
                this.showLocationDropdown = false;
                this.isUpdatingLocation = true;
                this.updatingLocationMessage = 'Connecting to ' + this.selectedLocation.label;

                let url;
                let method;
                let params = new URLSearchParams();

                params.append('TYPE', 'SelectedLocation');
                params.append('NAME', 'all-modules');
                params.append('VALUE', this.selectedLocation.id);

                if (this.currentLocation) {
                    url = new URL(this.$store.state.baseUrl + 'user-settings/' + this.currentLocation.id);
                    method = this.$http.put(url.href, params);
                } else {
                    url = new URL(this.$store.state.baseUrl + 'user-settings');
                    method = this.$http.post(url.href, params);
                }

                method.then((data) => {
                    this.isUpdatingLocation = false;

                    if (typeof data.response !== 'undefined' && data.response.status == 422) {
                        this.errors = {};

                        for (let obj in data.response.data) {
                            this.errors[data.response.data[obj].field] = data.response.data[obj].message;
                        }
                    } else {
                        this.$store.commit('userSettings/setSelectedLocation', {
                            id: data.data.ID,
                            value: data.data.VALUE
                        });

                        this.$router.push({
                            name: 'ModuleSelect'
                        });
                    }
                });
            },
            getAccountLogo() {
                let url = new URL(this.$store.state.baseUrl + 'accounts/get-logo');

                this.$http.get(url.href).then((data) => {
                    if (data.data.code === 100) {
                        this.accountLogo = data.data.data;
                    }
                });
            },
        },
        watch: {
            selectedLocation(value) {
                if (value && value.label) {
                    this.loginButtonMessage = 'Connect to ' + value.label;
                } else {
                    this.loginButtonMessage = 'Connect';
                }
            }
        }
    }
</script>

<style scoped>
    .account-logo {
        max-width: 100%;
        max-height: 200px;
    }
</style>
