<template>
    <div>
        <HeaderBar title="Manifest"></HeaderBar>

        <main class="main">

            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <table class="table-first-level">
                                <thead>
                                    <tr>
                                        <th>Merchant</th>
                                        <th>Vendor</th>
                                        <th>Vendor entity</th>
                                        <th>Destination</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table-second-level">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ merchantAccountName }}
                                                        </th>

                                                        <th>
                                                            <template v-if="!isLoadingVendors">
                                                                <v-select v-model="selectedVendor"
                                                                          :filterable="true"
                                                                          :searchable="true"
                                                                          :clearable="false"
                                                                          :options="vendors"
                                                                          label="RTN_OZN_NAME"
                                                                >
                                                                    <template slot="option" slot-scope="option">
                                                                        <div class="d-flex justify-content-between">
                                                                            <span>
                                                                                {{ option.RTN_OZN_NAME }}
                                                                            </span>

                                                                            <span class="option-description ml-1" v-if="openWorkOrderCountPerVendor[option.RTN_OZN_ID]">
                                                                                ({{ openWorkOrderCountPerVendor[option.RTN_OZN_ID] }})
                                                                            </span>
                                                                        </div>
                                                                    </template>

                                                                    <template slot="selected-option" slot-scope="option">
                                                                        {{ option.RTN_OZN_NAME }}
                                                                    </template>
                                                                </v-select>
                                                            </template>
                                                        </th>

                                                        <th>
                                                            <template v-if="selectedVendor && !isLoadingVendors">
                                                                <v-select v-model="selectedVendorEntity"
                                                                          :filterable="true"
                                                                          :searchable="true"
                                                                          :clearable="false"
                                                                          :options="selectedVendor.entities"
                                                                          label="RTN_OZE_NAME"
                                                                >
                                                                    <template slot="option" slot-scope="option">
                                                                        <div class="d-flex justify-content-between">
                                                                            <span>
                                                                                {{ option.RTN_OZE_NAME }}
                                                                            </span>

                                                                            <span class="option-description ml-1" v-if="openWorkOrderCountPerVendorEntity[option.RTN_OZE_ID]">
                                                                                ({{ openWorkOrderCountPerVendorEntity[option.RTN_OZE_ID] }})
                                                                            </span>
                                                                        </div>
                                                                    </template>

                                                                    <template slot="selected-option" slot-scope="option">
                                                                        {{ option.RTN_OZE_NAME }}
                                                                    </template>
                                                                </v-select>
                                                            </template>
                                                        </th>

                                                        <th>
                                                            <template v-if="selectedVendor && selectedVendorEntity && !isLoadingShippingDestinations">
                                                                <v-select v-model="selectedShippingDestinationId"
                                                                          :filterable="true"
                                                                          :searchable="true"
                                                                          :clearable="false"
                                                                          :options="vendorEntityLocations"
                                                                          label="RTN_LTN_NAME"
                                                                          :reduce="option => option.RTN_LTN_ID"
                                                                ></v-select>
                                                            </template>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <template v-if="isLoadingWorkOrders">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                Loading work orders to ship...
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else-if="!workOrders || workOrders.length === 0">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                No work orders ready to ship.
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else>
                                                        <tr>
                                                            <td colspan="4">
                                                                <input type="text" placeholder="Scan work order number" class="form-control lg" ref="scanWorkOrderInput" v-model="workOrderId">
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <div class="d-flex justify-content-between">
                                                                    <span>
                                                                        <template v-if="workOrderId || showSelectedWorkOrdersOnly">
                                                                            Showing {{ filteredWorkOrders.length }} of {{ workOrders.length }} work orders
                                                                        </template>

                                                                        <template v-else>
                                                                            Showing all work orders
                                                                        </template>
                                                                    </span>

                                                                    <label class="checkbox-container mr-3">
                                                                        <input type="checkbox" class="form-control" v-model="showSelectedWorkOrdersOnly">
                                                                        <span class="checkmark"></span>

                                                                        Only show selected work orders
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="py-0">
                                                                <InputFieldError attribute="shipment" :errors="errors"/>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="6" class="pt-5 pb-3">
                                                                <label class="checkbox-container mb-0 ml-4" @click.prevent="toggleAllCheckboxes()">
                                                                    Select all

                                                                    <input type="checkbox" v-model="selectedAll" :disabled="isCreatingShipment">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <table class="table-third-level">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="w-20"></th>
                                                                            <th class="w-auto">ID</th>
                                                                            <th class="w-auto">Reference</th>
                                                                            <th class="w-auto">External reference</th>
                                                                            <th class="w-auto">Vendor entity name</th>
                                                                            <th class="w-auto">Assets</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="workOrder in filteredWorkOrders" :key="workOrder.ID">
                                                                            <td class="w-20 pr-0">
                                                                                <label class="checkbox-container mb-20 mr-1">
                                                                                    <input type="checkbox" v-model="selectedWorkOrdersIds[workOrder.ID]" :disabled="isCreatingShipment">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ workOrder.ID }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ workOrder.REFERENCE }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ workOrder.EXTERNAL_REFERENCE }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ workOrder.VENDOR_ENTITY_NAME }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ workOrder.assets.length }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <div v-if="isCreatingShipment">
                                    Creating shipment...
                                </div>

                                <div v-else-if="isGeneratingDocument">
                                    Generating shipment document...
                                </div>

                                <button type="button" class="btn btn-primary" @click="createShipment()" :disabled="!Object.keys(selectedWorkOrdersIds).length || !selectedShippingDestinationId || isCreatingShipment">
                                    Create shipment
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        data() {
            return {
                errors: {},
                isLoadingWorkOrders: false,
                isLoadingMerchantAccounts: false,
                isLoadingShippingDestinations: false,
                isLoadingVendors: false,
                isCreatingShipment: false,
                isGeneratingDocument: false,
                selectedVendor: null,
                selectedVendorEntity: null,
                workOrders: null,
                workOrderId: null,
                selectedWorkOrdersIds: {},
                showSelectedWorkOrdersOnly: false,
                merchantAccounts: [],
                selectedShippingDestinationId: null,
                moduleAccess: {},
                canShipShipments: false,
                openWorkOrderCountPerVendor: {},
                openWorkOrderCountPerVendorEntity: {},
            };
        },
        mounted() {
            this.$root.$on('loadedRoleModuleAccess', () => {
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['return']?.['outbound'];
                this.canShipShipments = typeof this.moduleAccess['ship-shipments'] !== 'undefined' && this.moduleAccess['ship-shipments'].ACCESS;
            });
        },
        created() {
            this.getMerchantAccounts();
            this.getVendors(this.$route.query.merchant);
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'Outbound',
                });
            },

            getWorkOrders(vendorEntityId) {
                this.isLoadingWorkOrders = true;
                const url = new URL(this.$store.state.baseUrl + 'work-order/get-all-by-vendor-entity');
                url.searchParams.set('vendorEntityId', vendorEntityId);

                this.workOrders = null;

                this.$http.get(url.href).then((data) => {
                    this.isLoadingWorkOrders = false;
                    this.workOrders = data.data;

                    this.$nextTick(() => {
                        if (this.$refs.scanManifestInput) {
                            this.$refs.scanManifestInput.focus();
                        }
                    });
                });
            },

            getShippingDestinations(vendorEntityId) {
                this.selectedShippingDestinationId = null;
                this.isLoadingShippingDestinations = true;

                this.$store.dispatch('vendors/getVendorEntityLocations', vendorEntityId).then(() => {
                    this.isLoadingShippingDestinations = false;

                    if (this.vendorEntityLocations?.length === 1) {
                        this.selectedShippingDestinationId = this.vendorEntityLocations[0].RTN_LTN_ID;
                    }
                });
            },

            getMerchantAccounts() {
                this.isLoadingMerchantAccounts = true;

                this.$store.dispatch('merchantAccounts/getMerchantAccounts').then((data) => {
                    this.isLoadingMerchantAccounts = false;

                    if (data) {
                        for (let i in data) {
                            this.merchantAccounts.push({
                                id: data[i].RTN_ACT_ID,
                                origin: 1,
                                name: data[i].RTN_ACT_NAME
                            });
                        }
                    }
                });
            },

            getVendors(merchantId) {
                this.isLoadingVendors = true;

                this.$store.dispatch('vendors/getVendors', merchantId).then(() => {
                    this.isLoadingVendors = false;

                    if (this.vendors?.length === 1) {
                        this.selectedVendor = this.vendors[0];
                    }

                    this.getOpenWorkOrderCountForVendorEntities();
                });
            },

            getOpenWorkOrderCountForVendorEntities() {
                if (!this.allVendorEntityIds?.length) {
                    return;
                }

                const url = new URL(this.$store.state.baseUrl + 'work-order/get-open-work-order-count-for-vendor-entities');
                url.searchParams.set('vendorEntityIds', this.allVendorEntityIds);

                this.$http.get(url.href).then((data) => {
                    this.openWorkOrderCountPerVendorEntity = {};

                    if (data?.data) {
                        for (const count of data.data) {
                            this.openWorkOrderCountPerVendorEntity[count.VENDOR_ENTITY_ID] = count.COUNT;
                        }
                    }

                    this.calculateOpenWorkOrderCountForVendors();
                });
            },

            calculateOpenWorkOrderCountForVendors() {
                if (this.vendors?.length) {
                    for (const vendor of this.vendors) {
                        let count = 0;

                        if (vendor?.entities) {
                            for (const entity of vendor.entities) {
                                count += parseInt(this.openWorkOrderCountPerVendorEntity[entity.RTN_OZE_ID]);
                            }
                        }

                        this.openWorkOrderCountPerVendor[vendor.RTN_OZN_ID] = count;
                    }
                }
            },

            createShipment() {
                this.errors = {};

                if (this.selectedWorkOrders.length === 0 || !this.selectedShippingDestinationId) {
                    return;
                }

                let selectedIds = [];

                for (let i in this.selectedWorkOrders) {
                    selectedIds.push(this.selectedWorkOrders[i].ID);
                }

                this.isCreatingShipment = true;

                const url = new URL(this.$store.state.baseUrl + 'work-order/create-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[WORK_ORDER_IDS]', selectedIds);
                params.append('Shipment[DESTINATION_ID]', this.selectedShippingDestinationId);

                this.$http.post(url.href, params).then((data) => {
                    this.isCreatingShipment = false;

                    if (data?.data?.code === 422) {
                        if (data.data.errors) {
                            this.$set(this.errors, 'shipment', data.data.errors);
                        } else {
                            this.$set(this.errors, 'shipment', data.data.message);
                        }
                    } else if (data?.data?.code === 100 && data?.data?.data) {
                        if (this.canShipShipments) {
                            let query = {
                                reference: data.data.data
                            };

                            if (this.manifestsType === 'sorted') {
                                query.origin = 'shipSorted';
                                query.merchant = this.$route.query.merchant;
                            } else if (this.manifestsType === 'unsorted') {
                                query.origin = 'shipUnsorted';
                            }

                            this.$router.push({
                                name: 'ShipShipment',
                                query: query
                            });
                        } else {
                            this.selectedWorkOrdersIds = {};
                            this.selectedShippingDestinationId = this.vendorEntityLocations?.length === 1 ? this.vendorEntityLocations[0].RTN_LTN_ID : null;

                            if (this.selectedVendorEntity?.RTN_OZE_ID) {
                                this.getWorkOrders(this.selectedVendorEntity.RTN_OZE_ID);
                            }

                            this.getOpenWorkOrderCountForVendorEntities();
                        }
                    }
                });
            },

            toggleAllCheckboxes() {
                if (this.isCreatingShipment) {
                    return;
                }

                if (this.filteredWorkOrders) {
                    const isSelected = this.selectedAll;

                    for (let i = 0; i < Object.keys(this.filteredWorkOrders).length; i++) {
                        const key = this.filteredWorkOrders[i].ID;
                        this.$set(this.selectedWorkOrdersIds, key, !isSelected);
                    }
                }
            }
        },
        computed: {
            filteredWorkOrders() {
                if (!this.workOrders || this.workOrders.length === 0) {
                    return [];
                }

                let result = [];

                for (let i in this.workOrders) {
                    const workOrder = this.workOrders[i];

                    if (this.showSelectedWorkOrdersOnly && !this.selectedWorkOrders.includes(workOrder)) {
                        continue;
                    }

                    if (this.workOrderId && !workOrder.ID.includes(this.workOrderId)) {
                        continue;
                    }

                    result.push(workOrder);
                }

                return result;
            },
            selectedWorkOrders() {
                if (Object.keys(this.selectedWorkOrdersIds).length === 0) {
                    return [];
                }

                let result = [];

                for (let i = 0; i < Object.keys(this.selectedWorkOrdersIds).length; i++) {
                    const key = Object.keys(this.selectedWorkOrdersIds)[i];
                    const value = Object.values(this.selectedWorkOrdersIds)[i];

                    if (value) {
                        const workOrder = this.workOrders.find(workOrder => workOrder.ID == key);

                        if (workOrder) {
                            result.push(workOrder);
                        }
                    }
                }

                return result;
            },
            merchantAccountName() {
                let result = '';

                if (this.$route.query.merchant && this.merchantAccounts.length > 0) {
                    const merchantAccount = this.merchantAccounts.find(account => account.id == this.$route.query.merchant);
                    result = merchantAccount.name;
                }

                return result;
            },
            selectedAll() {
                return this.filteredWorkOrders.length > 0 && this.filteredWorkOrders.length === this.selectedWorkOrders.length;
            },
            vendors() {
                if (this.$store.state.vendors?.vendors?.[this.$route.query.merchant]) {
                    return this.$store.state.vendors.vendors[this.$route.query.merchant];
                }

                return [];
            },
            allVendorEntityIds() {
                if (!this.$store.state.vendors?.vendors?.[this.$route.query.merchant]) {
                    return [];
                }

                let result = [];

                for (const vendor of this.$store.state.vendors.vendors[this.$route.query.merchant]) {
                    if (vendor?.entities) {
                        for (const entity of vendor.entities) {
                            result.push(entity.RTN_OZE_ID);
                        }
                    }
                }

                return result;
            },
            vendorEntityLocations() {
                if (this.$store.state.vendors?.vendorEntityLocations?.[this.selectedVendorEntity?.RTN_OZE_ID]) {
                    return this.$store.state.vendors.vendorEntityLocations[this.selectedVendorEntity.RTN_OZE_ID];
                }

                return [];
            }
        },
        watch: {
            selectedVendor(selectedVendor) {
                this.selectedVendorEntity = null;
                this.workOrders = null;
                this.selectedShippingDestinationId = null;

                if (selectedVendor?.entities?.length === 1) {
                    this.selectedVendorEntity = selectedVendor.entities[0];
                }
            },
            selectedVendorEntity(selectedVendorEntity) {
                if (selectedVendorEntity?.RTN_OZE_ID) {
                    this.getShippingDestinations(selectedVendorEntity.RTN_OZE_ID);
                    this.getWorkOrders(selectedVendorEntity.RTN_OZE_ID);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .option-description {
        color: grey;
    }
</style>
