<template>
    <div>
        <div class="row col-12">
            <div class="col-10">
                <div v-if="isSaving">
                    Saving password...
                </div>

                <template v-else>
                    <div class="row">
                        <div class="col-md-6 my-3">
                            <label for="password">
                                Password:
                            </label>

                            <input type="password" class="form-control" v-model="user.password" id="password">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 my-3">
                            <label for="repeat-password">
                                Repeat password:
                            </label>

                            <input type="password" class="form-control" v-model="user.repeatpassword" id="repeat-password">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 my-3">
                            <InputFieldError attribute="PASSWORD" :errors="errors"/>
                        </div>
                    </div>
                </template>

                <button type="button" name="button" v-if="!isSaving" v-on:click="save()" class="btn md btn-primary">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                user: {
                    password: '',
                    repeatpassword: ''
                },
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {

        },
        methods: {
            save() {
                if (this.user.password !== this.user.repeatpassword) {
                    this.errors = {PASSWORD: 'Passwords do not match.'};
                    return false;
                }

                this.errors = {};
                this.isSaving = true;

                let url = new URL(this.$store.state.baseUrl + 'users/set-password');
                let params = new URLSearchParams();
                params.append('user[PASSWORD]', this.user.password);
                params.append('user[REPEAT_PASSWORD]', this.user.repeatpassword);
                params.append('user[ID]', this.$route.query.userId);
                params.append('user[REQUIRE_EMAIL_CONFIRMATION_TOKEN]', this.$route.query.token);

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else {
                        let user = data.data.data.user;

                        this.$store.commit('users/setCurrentUser', user);

                        this.$router.push({
                            name: 'Login'
                        });
                    }
                });
            }
        }
    }
</script>
