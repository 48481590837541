<template>
    <div>
        <HeaderBar title="Location"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <RoleMenu activeCategory="Access" activeItem="Modules"
                          v-bind:roleId="$route.query.roleId"></RoleMenu>

                <div class="col-10">
                    <template v-if="!initLoaded">
                        loading modules / role accesses ...
                    </template>

                    <template v-else>
                        <div class="col-12">
                            <InputFieldError attribute="attributes" :errors="errors"/>
                        </div>

                        <div v-if="isSaving">
                            saving access...
                        </div>

                        <template v-else>
                            <div class="mb-4" v-for="[section, modules] of Object.entries(modules)" :key="section">
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <h2>{{ section | capitalize }}</h2>
                                    </div>
                                </div>

                                <div v-for="module in getOrdered(modules)" :key="module.name">
                                    <template v-if="module.name === 'settings'">
                                        <div class="row">
                                            <div class="col-12">
                                                <h4>{{ module.name | capitalize }}</h4>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 my-3">
                                                <label class="checkbox-container mr-3">
                                                    <input type="checkbox" class="form-control"
                                                           :checked="typeof moduleAccess?.[section]?.[module.name]?.['settings'] !== 'undefined' && moduleAccess[section][module.name]['settings'].ACCESS"
                                                           @click="toggle(section, module.name, 'settings')"
                                                           :disabled="$store.state.users.currentUser.ROLE_ID == $route.query.roleId"
                                                    >

                                                    <span class="checkmark" :class="{'checkmark-disabled': $store.state.users.currentUser.ROLE_ID == $route.query.roleId}"></span>

                                                    Settings
                                                </label>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="module.name === 'control tower'">
                                        <div class="row">
                                            <div class="col-12">
                                                <h4>{{ module.name | capitalize }}</h4>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 my-3">
                                                <label class="checkbox-container mr-3">
                                                    <input type="checkbox" class="form-control"
                                                           :checked="typeof moduleAccess?.[section]?.[module.name]?.['control-tower'] !== 'undefined' && moduleAccess[section][module.name]['control-tower'].ACCESS"
                                                           @click="toggle(section, module.name, 'control-tower')"
                                                    >

                                                    <span class="checkmark"></span>

                                                    Control tower
                                                </label>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="module.submodules && Object.keys(module.submodules).length > 0">
                                        <!--
                                        Only show modules with submodules for now. This is temporary, because only toggling
                                        access to submodules is implemented at the moment. Remove this <template> once toggling
                                        access to modules has been implemented as well.
                                        -->

                                        <div class="row">
                                            <div class="col-12">
                                                <h4>{{ module.name | capitalize }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" v-for="(label, key) in module.submodules" :key="key">
                                            <div class="col-md-6 my-3">
                                                <label class="checkbox-container mr-3">
                                                    <input type="checkbox" :id="key" class="form-control"
                                                           :checked="typeof moduleAccess?.[section]?.[module.name]?.[key] !== 'undefined' && moduleAccess[section][module.name][key].ACCESS"
                                                           @click="toggle(section, module.name, key)"
                                                    >

                                                    <span class="checkmark"></span>

                                                    {{ label }}
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <button type="button"
                                name="button"
                                class="btn md btn-primary mb-4"
                                v-if="!isSaving"
                                @click="save()"
                        >
                            save
                        </button>
                    </template>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import RoleMenu from '@/components/_RoleMenu.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'RoleMenu': RoleMenu
        },
        data() {
            return {
                modules: {},
                moduleAccess: {},
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            let rolesPromise = this.$store.dispatch('roles/getAll');
            let modulesPromise = this.$store.dispatch('modules/getAvailableModules');
            let moduleAccessPromise = this.$store.dispatch('roleAccess/getByType', {
                roleId: this.$route.query.roleId,
                type: 'role-module-access'
            });
            let userPromise = this.$store.dispatch('users/getCurrentUser');

            Promise.all([rolesPromise, modulesPromise, moduleAccessPromise, userPromise]).then(() => {
                this.initLoaded = true;
                this.modules = this.$store.state.modules.availableModules;
                this.moduleAccess = this.$store.state.roleAccess['role-module-access'][this.$route.query.roleId];
            });
        },
        methods: {
            toggle(section, module, submodule) {
                if (typeof this.moduleAccess?.[section]?.[module]?.[submodule] !== 'undefined') {
                    let model = this.moduleAccess[section][module][submodule];
                    model.ACCESS = model.ACCESS === 1 ? 0 : 1;

                    this.moduleAccess[section][module][submodule] = model;
                } else {
                    if (!this.moduleAccess[section]) {
                        this.moduleAccess[section] = {};
                    }

                    if (!this.moduleAccess[section][module]) {
                        this.moduleAccess[section][module] = {}
                    }

                    this.moduleAccess[section][module][submodule] = {
                        'ACCESS': 1,
                        'SECTION': section,
                        'MODULE': module,
                        'SUBMODULE': submodule,
                    };
                }
            },
            save() {
                /* Retrieve the total count of submodules for which the access will be saved. */
                let moduleAccessLength = Object.values(this.moduleAccess).flatMap(modules => Object.values(modules).flatMap(submodules => Object.keys(submodules))).length;
                let current = 0;

                if (moduleAccessLength > 0) {
                    this.isSaving = true;

                    for (const [section, modules] of Object.entries(this.moduleAccess)) {
                        for (const submodules of Object.values(modules)) {
                            for (const submoduleModel of Object.values(submodules)) {
                                let url;
                                let method;
                                let params = new URLSearchParams();

                                params.append('ID', submoduleModel.ID);
                                params.append('ACCESS', submoduleModel.ACCESS);
                                params.append('SECTION', section);
                                params.append('MODULE', submoduleModel.MODULE);
                                params.append('SUBMODULE', submoduleModel.SUBMODULE);

                                if (submoduleModel.ID) {
                                    url = new URL(this.$store.state.baseUrl + 'role-module-accesses/' + submoduleModel.ID);
                                    method = this.$http.put(url.href, params);
                                } else {
                                    url = new URL(this.$store.state.baseUrl + 'role-module-accesses');
                                    params.append('ROLE_ID', this.$route.query.roleId);
                                    method = this.$http.post(url.href, params);
                                }

                                method.then((data) => {
                                    /* Detect if this is the last response. */
                                    if (++current === moduleAccessLength) {
                                        this.isSaving = false;
                                    }

                                    if (typeof data.response !== 'undefined' && data.response.status === 422) {
                                        this.errors = {};

                                        for (let obj in data.response.data) {
                                            this.errors[data.response.data[obj].field] = data.response.data[obj].message;
                                        }
                                    } else {
                                        if (!this.moduleAccess[section][submoduleModel.MODULE][submoduleModel.SUBMODULE].ID) {
                                            /* Retrieve the ID if this was a new record. */
                                            this.moduleAccess[section][submoduleModel.MODULE][submoduleModel.SUBMODULE].ID = data.data.ID;
                                        }

                                        this.$store.commit('roleAccess/setByType', {
                                            roleId: this.$route.query.roleId,
                                            type: 'role-module-access',
                                            data: this.moduleAccess
                                        });
                                    }
                                });
                            }
                        }
                    }
                }
            },
            getOrdered(items) {
                return Object.values(items).sort((a, b) => a.displayOrder - b.displayOrder);
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>
