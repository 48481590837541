import axios from '@/classes/Axios';

const containerStore = {
    namespaced: true,
    state: {
        containerTypes: null,
    },
    mutations: {
        setContainerTypes(state, containerTypes) {
            state.containerTypes = containerTypes;
        },
        clear(state) {
            state.containerTypes = null;
        }
    },
    actions: {
        getContainerTypes(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.containerTypes) {
                    resolve(storeArgObj.state.containerTypes);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'containers/get-container-types');

                    axios.get(url.href).then((data) => {
                        let containerTypes = data.data;

                        if (!containerTypes) {
                            return false;
                        }

                        storeArgObj.commit('setContainerTypes', containerTypes);
                        resolve(containerTypes);
                    });
                }
            });
        },
    }
};

export default containerStore;
