import { render, staticRenderFns } from "./_RoleMenu.vue?vue&type=template&id=43c212ef"
import script from "./_RoleMenu.vue?vue&type=script&lang=js"
export * from "./_RoleMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports