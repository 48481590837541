<template>
    <div class="scan-manisfest-card">
        <div
            class="scan-manisfest-card-header"
            :class="colorClass"
        >
            <h4>
                {{ label }}
            </h4>

            <span class="badge" v-if="typeof count !== 'undefined'">
                {{ count }}
            </span>
        </div>

        <div
            class="scan-manisfest-card-body"
            ref="cardBody"
            :style="{'height': cardBodyHeight}"
        >
            <div v-for="(item, key) in items" :key="key" :class="key > 0 ? 'mt-4' : ''">
                <span class="label">
                    {{ item.label }}
                </span>

                <span class="content">
                    {{ item.value }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'label',
        'count',
        'items',
        'colorClass',
        'cardBodyHeight'
    ],
    methods: {
        getCardBodyHeight() {
            return this.$refs?.cardBody?.scrollHeight ?? 0;
        }
    }
}
</script>