<template>
    <div>
        <HeaderBar title="Asset"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <template v-if="isLoadingAsset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        Loading asset...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="!asset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        Unable to retrieve asset data.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="isSavingAsset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        Saving asset...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content">
                            <div class="container">
                                <h3 class="section-title-sm">
                                    Rework asset
                                </h3>

                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr ref="header-titles">
                                                <th>SKU</th>
                                                <th>Description</th>
                                                <th>Origin country</th>
                                                <th>License plate</th>
                                                <th>Storage</th>
                                                <th>Container</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="6">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr ref="header-contents">
                                                                <th class="border-bottom-none text-break">
                                                                    {{ asset.SKU }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="asset.DESCRIPTION">
                                                                        {{ asset.DESCRIPTION }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="asset.COUNTRY_OF_ORIGIN">
                                                                        {{ asset.COUNTRY_OF_ORIGIN }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none text-break">
                                                                    {{ asset.LICENSE_PLATE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ asset.STORAGE_TYPE }}
                                                                </th>

                                                                <th class="border-bottom-none text-break">
                                                                    <template v-if="asset.container">
                                                                        {{ asset.container.LICENSE_PLATE }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="6" class="p-0 border-radius-none border-top-td">
                                                                    <table class="w-100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-right-td border-radius-none vertical-top">
                                                                                    <div class="row pt-4">
                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-4">
                                                                                                <label class="label">
                                                                                                    Received grade
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.GRADE">
                                                                                                        {{ asset.GRADE }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-4">
                                                                                                <label class="label">
                                                                                                    Disposition type
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.DISPOSITION_TYPE">
                                                                                                        {{ asset.DISPOSITION_TYPE | capitalize }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="grade-separator mb-4"></div>

                                                                                    <template v-if="isLoadingDisplayCustomAttributes">
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <p>
                                                                                                    Loading attributes...
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>
                                                                                    
                                                                                    <template v-else>
                                                                                        <div class="row" v-if="displayCustomAttributes['receive'] && Object.keys(displayCustomAttributes['receive']).length > 0">
                                                                                            <div class="col-12">
                                                                                                <CustomAttributesDisplay :customAttributes="displayCustomAttributes['receive']"/>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row" v-if="(displayCustomAttributes['grade'] && Object.keys(displayCustomAttributes['grade']).length > 0) || (assetDispositionImages && assetDispositionImages['assetGrade'])">
                                                                                            <template v-if="displayCustomAttributes['grade'] && Object.keys(displayCustomAttributes['grade']).length > 0">
                                                                                                <div class="col-12 col-lg-6">
                                                                                                    <CustomAttributesDisplay :customAttributes="displayCustomAttributes['grade']"/>
                                                                                                </div>
                                                                                            </template>

                                                                                            <div class="col-12 col-lg-6">
                                                                                                <ImageGallery :images="assetDispositionImages['assetGrade']"
                                                                                                              idAttribute="ID"
                                                                                                              urlAttribute="URL"
                                                                                                              class="sm">
                                                                                                </ImageGallery>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top">
                                                                                    <div class="form-group pt-4">
                                                                                        <div class="row">
                                                                                            <div class="col-10">
                                                                                                <label class="label">
                                                                                                    Rework instruction
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="reworkInstructionMessage">
                                                                                                        {{ reworkInstructionMessage }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>

                                                                                            <div class="col-2 text-right">
                                                                                                <template v-if="reworkInstructionImages && reworkInstructionImages.length > 0">
                                                                                                    <ImageGallery :images="reworkInstructionImages"
                                                                                                                  :onlyShowFirstImage="true"
                                                                                                                  idAttribute="RTN_WFE_ID"
                                                                                                                  urlAttribute="RTN_WFE_URL"
                                                                                                                  class="sm">
                                                                                                    </ImageGallery>
                                                                                                </template>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <template v-if="isLoadingCustomAttributes">
                                                                                        <p>
                                                                                            Loading form...
                                                                                        </p>
                                                                                    </template>

                                                                                    <CustomAttributesForm :customAttributes="customAttributes" :errors="errors" ref="customAttrForm" :class="isAddingPictures ? 'd-none' : 'd-block'"/>

                                                                                    <Webcam model="Asset"
                                                                                            :modelPk="assetId"
                                                                                            imageCountLimit="5"
                                                                                            fileUploadCategory="assetRework"
                                                                                            @startedAddingPictures="isAddingPictures = true"
                                                                                            @stoppedAddingPictures="isAddingPictures = false"
                                                                                            :shouldStopAddingPictures="shouldStopAddingPictures"
                                                                                    >
                                                                                    </Webcam>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="save()" :disabled="isSavingAsset" v-if="!isLoadingAsset && asset">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import ImageGallery from '@/components/_ImageGallery.vue';
import Webcam from '@/components/_Webcam.vue';
import CustomAttributesForm from '@/components/_CustomAttributesForm.vue';
import CustomAttributesDisplay from '@/components/_CustomAttributesDisplay.vue';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'ImageGallery': ImageGallery,
        'Webcam': Webcam,
        'CustomAttributesForm': CustomAttributesForm,
        'CustomAttributesDisplay': CustomAttributesDisplay,
    },
    data() {
        return {
            errors: {},
            isLoadingAsset: false,
            isLoadingAssetDispositionImages: false,
            isLoadingCustomAttributes: false,
            isLoadingDisplayCustomAttributes: false,
            isLoadingInstructions: false,
            isUploadingAssetDispositionImages: false,
            isUploadingScreenshots: false,
            isSavingAsset: false,
            origin: this.$route.query.origin,
            containerId: this.$route.query.containerId,
            assetId: this.$route.query.id,
            asset: null,
            assetDispositionImages: [],
            imagesBeingDeleted: [],
            screenshots: [],
            isUsingCameraUpload: true,
            customAttributes: null,
            displayCustomAttributes: {},
            reworkInstructionMessage: null,
            reworkInstructionImages: null,
            imageCountLimit: 5,
            isAddingPictures: false,
            shouldStopAddingPictures: false,
        };
    },
    created() {
        this.getAsset();
        this.getCustomAttributes();
        this.getDisplayCustomAttributes();
        this.getUploadedImages();
    },
    methods: {
        previous() {
            if (this.origin && this.origin === 'assetDetail') {
                this.$router.push({
                    name: 'Asset',
                    query: {id: this.assetId, containerId: this.containerId}
                });
            } else {
                this.$router.push({
                    name: 'ContainerAssetOverview',
                    query: {id: this.containerId}
                });
            }
        },
        next() {
            this.$router.push({
                name: 'AssetRevalue',
                query: {id: this.assetId, containerId: this.containerId, origin: this.origin}
            });
        },
        getAsset() {
            this.isLoadingAsset = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/find-by-id');
            url.searchParams.set('assetId', this.assetId);
            url.searchParams.set('withContainer', true);

            this.$http.get(url.href).then((data) => {
                this.isLoadingAsset = false;
                this.asset = data.data;

                this.getReworkInstructions();

                this.$nextTick(() => {
                    this.fixHeaderWidths();
                });
            });
        },
        getCustomAttributes() {
            this.isLoadingCustomAttributes = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-custom-attributes');
            url.searchParams.append('assetId', this.assetId);
            url.searchParams.append('displayOption', 'RETURN_DOCK_REWORK_ASSET');

            this.$http.get(url.href).then((data) => {
                this.isLoadingCustomAttributes = false;
                this.customAttributes = data.data;
            });
        },
        getDisplayCustomAttributes() {
            this.isLoadingDisplayCustomAttributes = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-custom-attribute-display-values');
            url.searchParams.append('assetId', this.assetId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingDisplayCustomAttributes = false;

                if (data.data) {
                    for (const [displayOption, customAttributes] of Object.entries(data.data)) {
                        for (const [key, customAttribute] of Object.entries(customAttributes)) {
                            if (customAttribute.value) {
                                if (!this.displayCustomAttributes[displayOption]) {
                                    this.displayCustomAttributes[displayOption] = {};
                                }

                                this.displayCustomAttributes[displayOption][key] = customAttribute;
                            }
                        }
                    }
                }
            });
        },
        getReworkInstructions() {
            this.isLoadingInstructions = true;

            this.$store.dispatch('products/getReworkInstructions', [this.asset.PRODUCT_ID, this.asset.SKU]).then((data) => {
                this.isLoadingInstructions = false;

                if (data) {
                    this.reworkInstructionMessage = data.message;
                    this.reworkInstructionImages = data.images;
                }
            });
        },
        getUploadedImages() {
            this.isLoadingAssetDispositionImages = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-asset-disposition-images');
            url.searchParams.set('assetId', this.assetId);
            url.searchParams.set('category', ['assetGrade', 'assetRework']);

            this.$http.get(url.href).then((data) => {
                this.isLoadingAssetDispositionImages = false;

                if (data.data.code === 100) {
                    this.assetDispositionImages = data.data.data;
                } else if (data.data.code === 422) {
                    this.errors = {fileUpload: data.data.errors};
                }
            });
        },
        save() {
            if (this.isAddingPictures) {
                /* Close the Webcam component without continuing.
                 * This is done to prevent the operator from accidentally ending the Rework process too soon. */
                this.shouldStopAddingPictures = true;

                this.$nextTick(() => {
                    this.shouldStopAddingPictures = false;
                });

                return false;
            }

            if (!this.validateFields()) {
                return false;
            }

            this.errors = {};
            this.isSavingAsset = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/save');
            url.searchParams.set('assetId', this.assetId);

            let params = new URLSearchParams();
            params.append('Asset[CUSTOM_ATTRIBUTE_OPTION]', 'RETURN_DOCK_REWORK_ASSET');
            let customAttributesData = this.$refs.customAttrForm.getData();

            for (const [key, value] of Object.entries(customAttributesData)) {
                if (value !== null) {
                    params.append('Asset[' + key + ']', value);
                }
            }

            this.$http.post(url.href, params).then((data) => {
                this.isSavingAsset = false;

                if (data.data.code === 100) {
                    this.next();
                } else if (data.data.code === 422) {
                    this.errors = data.data.errors;
                }
            });
        },
        validateFields() {
            this.errors = {};

            if (this.screenshots.length > 0) {
                this.errors['fileUpload'] = 'There are some screenshots which have not yet been uploaded. Please upload or delete them to continue.';
            }

            return !Object.keys(this.errors).length > 0;
        },
        selectedFile() {
            this.errors = {};

            if (this.getSelectedImageCount() > (this.imageCountLimit - this.assetDispositionImages.length)) {
                this.errors['fileUpload'] = 'You can only add 5 images.';

                const fileUpload = document.getElementById('fileUpload');

                if (fileUpload) {
                    fileUpload.value = '';
                }
            }
        },
        fixHeaderWidths() {
            if (!this.$refs['header-contents'] || !this.$refs['header-titles']) {
                return;
            }

            const headerContents = this.$refs['header-contents'].children;
            const headerTitles = this.$refs['header-titles'].children;
            let index = 0;

            for (const content of headerContents) {
                let width = content.clientWidth + 'px';

                content.style.width = width;
                headerTitles[index].style.width = width;

                index++;
            }
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        datepickerFormatter: function (date) {
            let d = new Date(date);
            return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
        },
    },
}
</script>

<style scoped lang="scss">
    h5 {
        margin-bottom: 40px;
        font-weight: 600;
    }

    .table-second-level tbody tr td {
        padding-top: 0;
    }

    .table-second-level thead tr th {
        width: auto;
        min-width: 110px;
    }

    .grade-separator {
        margin-left: -20px;
        margin-right: -20px;
        border-bottom: 1px solid #d9d7e4;
    }

    .scrollable-content {
        padding: 30px 0;
    }
</style>
