import axios from '@/classes/Axios';
import ArrayStoreFormatter from '@/classes/ArrayStoreFormatter';

const locationStore = {
    namespaced: true,
    state: {
        availableLocations: null,
        rolelocationaccess: {},
        rolelocationaccountaccess: {},
        'role-module-access': {},
    },
    mutations: {
        setByType(state, settingsObj) {
            if (!state[settingsObj.type]) {
                state[settingsObj.type] = {};
            }

            if (!state[settingsObj.type][settingsObj.roleId]) {
                state[settingsObj.type][settingsObj.roleId] = {};
            }

            state[settingsObj.type][settingsObj.roleId] = settingsObj.data;
        },
        setAvailableLocations(state, availableLocations) {
            state.availableLocations = availableLocations;
        },
        clear(state) {
            state.availableLocations = null;
            state.rolelocationaccess = {};
            state.rolelocationaccountaccess = {};
            state['role-module-access'] = {};
        }
    },
    actions: {
        getByType(storeArgObj, settingsObj) {
            let type = settingsObj.type;
            let roleId = settingsObj.roleId;

            return new Promise((resolve) => {
                if (storeArgObj.state[type] && storeArgObj.state[type][roleId]) {
                    resolve(storeArgObj.state[type][roleId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + type);
                    url.searchParams.set('roleId', roleId);

                    axios.get(url.href).then((data) => {
                        let accessObjects;

                        switch (type) {
                            case 'rolelocationaccountaccess':
                                accessObjects = ArrayStoreFormatter.format(data.data, 'LOCATION_ACCESS_TO_ACCOUNT_ID');
                                break;

                            case 'rolelocationaccess':
                                accessObjects = ArrayStoreFormatter.format(data.data, 'LOCATION_ID');
                                break;

                            case 'role-module-access':
                                accessObjects = {};

                                data.data.forEach((row) => {
                                    if (!accessObjects[row.SECTION]) {
                                        accessObjects[row.SECTION] = {};
                                    }

                                    if (!accessObjects[row.SECTION][row.MODULE]) {
                                        accessObjects[row.SECTION][row.MODULE] = {};
                                    }

                                    accessObjects[row.SECTION][row.MODULE][row.SUBMODULE] = row;
                                })
                                break;
                        }

                        settingsObj.data = accessObjects;
                        storeArgObj.commit('setByType', settingsObj);
                        resolve(accessObjects);
                    });
                }
            });
        },
        getAvailableLocations(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.availableLocations) {
                    resolve(storeArgObj.state.availableLocations);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'rolelocationaccess/get-available-locations');

                    axios.get(url.href).then((data) => {
                        let availableLocations = data.data.data.availableLocations;
                        storeArgObj.commit('setAvailableLocations', availableLocations);
                        resolve(availableLocations);
                    });
                }
            });
        }
    }
};

export default locationStore;
