<template>
    <div>
        <HeaderBar title="Update profile"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="Profile" activeItem="Update"></SettingsMenu>

                <div class="col-10">
                    <div v-if="isLoadingProfile">
                        Loading profile...
                    </div>

                    <div v-else-if="isSavingProfile">
                        Saving profile...
                    </div>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-6 my-3">

                                <!-- TODO: Add logo upload field -->

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="description">
                                    Description
                                </label>

                                <textarea class="form-control" v-model="profile.DESCRIPTION" id="description" rows="10"></textarea>

                                <InputFieldError attribute="DESCRIPTION" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div v-if="Object.keys(accessibilities).length > 0">
                                    <label for="user-type">
                                        Accessibility
                                    </label>

                                    <model-list-select
                                            :list="accessibilities"
                                            option-value="value"
                                            option-text="label"
                                            v-model="profile.ACCESSIBILITY"
                                            placeholder="Select accessibility"
                                            id="user-type"
                                            required
                                    >
                                    </model-list-select>

                                    <InputFieldError attribute="ACCESSIBILITY" :errors="errors"/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <button type="button" name="button" v-if="!isLoadingProfile && !isSavingProfile" v-on:click="save()"
                            class="btn md btn-primary mt-3">
                        Save
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'SettingsMenu': SettingsMenu,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                isSavingProfile: false,
                isLoadingProfile: false,
                errors: {},
                accessibilities: [
                    {value: 'private', label: 'Private'},
                    {value: 'public', label: 'Public'}
                ],
                profile: {
                    LOGO: null,
                    DESCRIPTION: null,
                    ACCESSIBILITY: null,
                },
            };
        },
        created() {
            this.getProfileData();
        },
        methods: {
            save() {
                let url = new URL(this.$store.state.baseUrl + 'profile/update-profile');
                let params = new URLSearchParams();

                if (this.profile.LOGO) {
                    params.append('Profile[LOGO]', this.profile.LOGO);
                }

                if (this.profile.DESCRIPTION) {
                    params.append('Profile[DESCRIPTION]', this.profile.DESCRIPTION);
                }

                if (this.profile.ACCESSIBILITY) {
                    params.append('Profile[ACCESSIBILITY]', this.profile.ACCESSIBILITY);
                }

                this.isSavingProfile = true;

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingProfile = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            getProfileData() {
                this.isLoadingProfile = true;

                this.$store.dispatch('profiles/getProfile').then((data) => {
                    this.isLoadingProfile = false;

                    if (data) {
                        this.profile = data;
                    }
                });
            }
        }
    }
</script>
