import axios from '@/classes/Axios';

const returnStore = {
    namespaced: true,
    state: {
        receivedReturn: null,
        expectedProducts: {},
        unexpectedProducts: {},
    },
    mutations: {
        setReceivedReturn(state, receivedReturn) {
            state.receivedReturn = receivedReturn;
        },
        setExpectedProducts(state, [returnId, expectedProducts]) {
            state.expectedProducts[returnId] = expectedProducts;
        },
        setUnexpectedProducts(state, [returnId, unexpectedProducts]) {
            state.unexpectedProducts[returnId] = unexpectedProducts;
        },
        setUnexpectedProduct(state, [returnId, unexpectedProduct]) {
            /* Only add the unexpected product if the list of unexpected products has been loaded.
             * Otherwise a situation might occur where the dropdown menu only shows the newly added unexpected product,
             * because the list of unexpected products won't be loaded. */
            if (state.unexpectedProducts[returnId]) {
                state.unexpectedProducts[returnId].push(unexpectedProduct);
            }
        },
        clearReceivedReturn(state) {
            state.receivedReturn = null;
        },
        clearUnexpectedProducts(state) {
            state.unexpectedProducts = {};
        },
        clear(state) {
            state.receivedReturn = null;
            state.expectedProducts = {};
            state.unexpectedProducts = {};
        }
    },
    actions: {
        getReceivedReturn(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.receivedReturn && storeArgObj.state.receivedReturn.RTN_RTN_ID === returnId) {
                    resolve(storeArgObj.state.receivedReturn);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'returns/find-by-id');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let returnModel = data.data;

                        storeArgObj.commit('setReceivedReturn', returnModel);
                        resolve(returnModel);
                    });
                }
            });
        },
        getExpectedProducts(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.expectedProducts && storeArgObj.state.expectedProducts[returnId]) {
                    resolve(storeArgObj.state.expectedProducts[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'returns/get-expected-products');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let expectedProducts = data.data;

                        storeArgObj.commit('setExpectedProducts', [returnId, expectedProducts]);
                        resolve(expectedProducts);
                    });
                }
            });
        },
        getUnexpectedProducts(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.unexpectedProducts && storeArgObj.state.unexpectedProducts[returnId]) {
                    resolve(storeArgObj.state.unexpectedProducts[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'returns/get-unexpected-products');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let unexpectedProducts = data.data;

                        storeArgObj.commit('setUnexpectedProducts', [returnId, unexpectedProducts]);
                        resolve(unexpectedProducts);
                    });
                }
            });
        },
    }
};

export default returnStore;
