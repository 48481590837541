<template>
    <div>
        <div class="confirmation-overlay">
            <div class="overlay-background"></div>

            <div class="modal-wrapper">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <template v-if="isLoading">
                            <h5 class="py-4 my-3" v-html="loadingMessage"></h5>
                        </template>

                        <template v-else>
                            <h5 class="py-4 my-3" v-html="message"></h5>

                            <slot name="additional-content"></slot>
                        </template>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click.prevent="deny()" :disabled="isLoading">
                            No
                        </button>

                        <button type="button" class="btn btn-success" @click.prevent="confirm()" :disabled="isLoading">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {

        },
        props: [
            'message',
            'loadingMessage',
            'isLoading',
        ],
        data() {
            return {

            }
        },
        methods: {
            confirm() {
                this.$emit('confirm');
            },
            deny() {
                this.$emit('deny');
            }
        },
        created() {

        }
    }
</script>


<style>

</style>
