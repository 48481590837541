<template>
    <div>
        <HeaderBar title="Asset"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <template v-if="isLoadingAsset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        Loading asset...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="!asset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        Unable to retrieve asset data.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="isSavingAsset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        Saving asset...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content">
                            <div class="container">
                                <h3 class="section-title-sm">
                                    Revalue asset
                                </h3>

                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr ref="header-titles">
                                                <th>SKU</th>
                                                <th>Description</th>
                                                <th>Origin country</th>
                                                <th>License plate</th>
                                                <th>Storage</th>
                                                <th>Container</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="6">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr ref="header-contents">
                                                                <th class="border-bottom-none text-break">
                                                                    {{ asset.SKU }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="asset.DESCRIPTION">
                                                                        {{ asset.DESCRIPTION }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="asset.COUNTRY_OF_ORIGIN">
                                                                        {{ asset.COUNTRY_OF_ORIGIN }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none text-break">
                                                                    {{ asset.LICENSE_PLATE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ asset.STORAGE_TYPE }}
                                                                </th>

                                                                <th class="border-bottom-none text-break">
                                                                    <template v-if="asset.container">
                                                                        {{ asset.container.LICENSE_PLATE }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="6" class="p-0 border-radius-none border-top-td">
                                                                    <table class="w-100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-right-td border-radius-none vertical-top">
                                                                                    <div class="row pt-4">
                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-4">
                                                                                                <label class="label">
                                                                                                    Received grade
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.GRADE">
                                                                                                        {{ asset.GRADE }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-4">
                                                                                                <label class="label">
                                                                                                    Disposition type
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.DISPOSITION_TYPE">
                                                                                                        {{ asset.DISPOSITION_TYPE | capitalize }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="grade-separator mb-4"></div>

                                                                                    <template v-if="isLoadingDisplayCustomAttributes">
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <p>
                                                                                                    Loading attributes...
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        <div class="row" v-if="displayCustomAttributes['receive'] && Object.keys(displayCustomAttributes['receive']).length > 0">
                                                                                            <div class="col-12">
                                                                                                <CustomAttributesDisplay :customAttributes="displayCustomAttributes['receive']"/>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row" v-if="(displayCustomAttributes['grade'] && Object.keys(displayCustomAttributes['grade']).length > 0) || (assetDispositionImages && assetDispositionImages['assetGrade'])">
                                                                                            <template v-if="displayCustomAttributes['grade'] && Object.keys(displayCustomAttributes['grade']).length > 0">
                                                                                                <div class="col-12 col-lg-6">
                                                                                                    <CustomAttributesDisplay :customAttributes="displayCustomAttributes['grade']"/>
                                                                                                </div>
                                                                                            </template>

                                                                                            <div class="col-12 col-lg-6">
                                                                                                <ImageGallery :images="assetDispositionImages['assetGrade']"
                                                                                                              idAttribute="ID"
                                                                                                              urlAttribute="URL"
                                                                                                              class="sm">
                                                                                                </ImageGallery>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row" v-if="(displayCustomAttributes['rework'] && Object.keys(displayCustomAttributes['rework']).length > 0) || (assetDispositionImages && assetDispositionImages['assetrework'])">
                                                                                            <template v-if="displayCustomAttributes['rework'] && Object.keys(displayCustomAttributes['rework']).length > 0">
                                                                                                <div class="col-12 col-lg-6">
                                                                                                    <CustomAttributesDisplay :customAttributes="displayCustomAttributes['rework']"/>
                                                                                                </div>
                                                                                            </template>

                                                                                            <div class="col-12 col-lg-6">
                                                                                                <ImageGallery :images="assetDispositionImages['assetRework']"
                                                                                                              idAttribute="ID"
                                                                                                              urlAttribute="URL"
                                                                                                              class="sm">
                                                                                                </ImageGallery>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top">
                                                                                    <template v-if="isGrading">
                                                                                        <div class="form-group pt-4">
                                                                                            <div class="row">
                                                                                                <div class="col-10">
                                                                                                    <label class="label">
                                                                                                        Revalue instruction
                                                                                                    </label>

                                                                                                    <p>
                                                                                                        <template v-if="revalueInstructionMessage">
                                                                                                            {{ revalueInstructionMessage }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div class="col-2 text-right">
                                                                                                    <template v-if="revalueInstructionImages && revalueInstructionImages.length > 0">
                                                                                                        <ImageGallery :images="revalueInstructionImages"
                                                                                                                      :onlyShowFirstImage="true"
                                                                                                                      idAttribute="RTN_WFE_ID"
                                                                                                                      urlAttribute="RTN_WFE_URL"
                                                                                                                      class="sm">
                                                                                                        </ImageGallery>
                                                                                                    </template>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <template v-if="isLoadingGradingSet">
                                                                                            <p class="mb-5">
                                                                                                Loading grading set...
                                                                                            </p>
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            <div class="row">
                                                                                                <div class="col-12">
                                                                                                    <label class="label">
                                                                                                        Revalued grade
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="row my-3 align-items-center light-grading--grades" v-for="grade in grades" :key="grade.RTN_GRL_ID">
                                                                                                <div class="col-12">
                                                                                                    <button type="button" class="btn btn--grade" :class="'grade--' + grade.RTN_GRL_GRADE" @click.prevent="setRevalueGrade(grade.RTN_GRL_GRADE)">
                                                                                                        {{ grade.RTN_GRL_GRADE }}
                                                                                                    </button>

                                                                                                    <span class="ml-4">
                                                                                                        {{ grade.RTN_GRL_LABEL }}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </template>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        <div class="form-group" v-if="!isAddingPictures">
                                                                                            <div class="row pt-4">
                                                                                                <div class="col-12 col-lg-8">
                                                                                                    <span class="grading-result-title mb-4">
                                                                                                        Revalued grade
                                                                                                    </span>

                                                                                                    <div class="row mt-5 pt-2">
                                                                                                        <div class="col-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="label">
                                                                                                                    Disposition resolution
                                                                                                                </label>

                                                                                                                <p>
                                                                                                                    {{ asset.DISPOSITION_RESOLUTION|capitalize }}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div class="col-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="label">
                                                                                                                    Storage
                                                                                                                </label>

                                                                                                                <p>
                                                                                                                    AFS
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-12 col-lg-4 text-center text-lg-right">
                                                                                                    <span class="result m-3" :class="'grade--' + asset.REWORKED_GRADE">
                                                                                                        <strong>
                                                                                                            {{ asset.REWORKED_GRADE }}
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <template v-if="isLoadingCustomAttributes">
                                                                                            <p>
                                                                                                Loading form...
                                                                                            </p>
                                                                                        </template>

                                                                                        <CustomAttributesForm :customAttributes="customAttributes" :errors="errors" ref="customAttrForm" :class="isAddingPictures ? 'd-none' : 'd-block'"/>

                                                                                        <Webcam model="Asset"
                                                                                                :modelPk="assetId"
                                                                                                imageCountLimit="5"
                                                                                                fileUploadCategory="assetRevalue"
                                                                                                @startedAddingPictures="isAddingPictures = true"
                                                                                                @stoppedAddingPictures="isAddingPictures = false"
                                                                                                :shouldStopAddingPictures="shouldStopAddingPictures"
                                                                                                class="mt-4"
                                                                                        >
                                                                                        </Webcam>

                                                                                        <div class="d-flex justify-content-between mt-4" v-if="!isAddingPictures">
                                                                                            <div class="d-block w-100">
                                                                                                <label class="label">
                                                                                                    Container
                                                                                                </label>

                                                                                                <template v-if="isLoadingContainers">
                                                                                                    <p>
                                                                                                        Loading containers...
                                                                                                    </p>
                                                                                                </template>

                                                                                                <template v-else>
                                                                                                    <model-list-select
                                                                                                            :list="filteredContainers"
                                                                                                            option-value="value"
                                                                                                            option-text="label"
                                                                                                            v-model="asset.CONTAINER_ID"
                                                                                                            required
                                                                                                    >
                                                                                                    </model-list-select>
                                                                                                </template>
                                                                                            </div>

                                                                                            <div class="d-block mx-3 text-center">
                                                                                                <label class="label">
                                                                                                    Full
                                                                                                </label>

                                                                                                <label class="checkbox-container container-full m-0 p-0 d-flex justify-content-center align-items-center">
                                                                                                    <input type="checkbox" v-model="containerIsFull">
                                                                                                    <span class="checkmark"></span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div class="d-block text-center">
                                                                                                <label class="label">
                                                                                                    New
                                                                                                </label>

                                                                                                <button type="button" class="btn btn-primary btn-create-container" @click.prevent="showCreateContainerModal = true">
                                                                                                    +
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>

                                                                                        <InputFieldError attribute="CONTAINER_ID" :errors="errors && !isAddingPictures"/>
                                                                                    </template>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="save()" :disabled="isSavingAsset" v-if="!isLoadingAsset && asset">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>

        <div class="confirmation-overlay" v-if="showCreateContainerModal">
            <div class="overlay-background"></div>

            <div class="modal-wrapper">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="mb-0">Create container</h5>
                    </div>

                    <div class="modal-body">
                        <template v-if="isCreatingContainer">
                            Creating container {{ newContainer.licensePlate }}...
                        </template>

                        <template v-else>
                            <div class="form-group">
                                <label class="label">
                                    License plate
                                </label>

                                <input type="text" class="form-control" v-model="newContainer.licensePlate">
                                <InputFieldError attribute="LICENSE_PLATE" :errors="errors"/>
                            </div>

                            <div class="form-group">
                                <label class="label">
                                    Container type
                                </label>

                                <template v-if="isLoadingContainerTypes">
                                    <p>Loading container types...</p>
                                </template>

                                <template v-else>
                                    <v-select v-model="newContainer.type" :filterable="true" :searchable="true" :clearable="false"
                                              :options="containerTypes"></v-select>

                                    <InputFieldError attribute="TYPE" :errors="errors"/>
                                </template>
                            </div>
                        </template>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="showCreateContainerModal = false" :disabled="isCreatingContainer">
                            Cancel
                        </button>

                        <button type="button" class="btn btn-success" @click="createContainer()" :disabled="isCreatingContainer">
                            Create container
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import ImageGallery from '@/components/_ImageGallery.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import Webcam from '@/components/_Webcam.vue';
import CustomAttributesForm from '@/components/_CustomAttributesForm.vue';
import CustomAttributesDisplay from '@/components/_CustomAttributesDisplay.vue';
import {ModelListSelect} from 'vue-search-select';
import vSelect from 'vue-select';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'ImageGallery': ImageGallery,
        'InputFieldError': InputFieldError,
        'Webcam': Webcam,
        'CustomAttributesForm': CustomAttributesForm,
        'CustomAttributesDisplay': CustomAttributesDisplay,
        'ModelListSelect': ModelListSelect,
        'v-select': vSelect,
    },
    data() {
        return {
            errors: {},
            isLoadingAsset: false,
            isLoadingAssetDispositionImages: false,
            isLoadingCustomAttributes: false,
            isLoadingDisplayCustomAttributes: false,
            isLoadingInstructions: false,
            isLoadingGradingSet: false,
            isLoadingContainers: false,
            isLoadingContainerTypes: false,
            isUploadingAssetDispositionImages: false,
            isUploadingScreenshots: false,
            isSavingAsset: false,
            isCreatingContainer: false,
            isGrading: true, /* Default true to always start with the grading process. */
            origin: this.$route.query.origin,
            containerId: this.$route.query.containerId,
            assetId: this.$route.query.id,
            asset: null,
            assetDispositionImages: [],
            imagesBeingDeleted: [],
            screenshots: [],
            isUsingCameraUpload: true,
            customAttributes: null,
            displayCustomAttributes: {},
            revalueInstructionMessage: null,
            revalueInstructionImages: null,
            grades: null,
            containers: null,
            filteredContainers: null,
            containerIsFull: false,
            showCreateContainerModal: false,
            newContainer: {
                licensePlate: null,
                type: null
            },
            containerTypes: null,
            imageCountLimit: 5,
            isAddingPictures: false,
            shouldStopAddingPictures: false,
        };
    },
    created() {
        this.getAsset();
        this.getCustomAttributes();
        this.getDisplayCustomAttributes();
        this.getUploadedImages();
        this.getGradingSet();
        this.getContainerTypes();
    },
    methods: {
        previous() {
            this.$router.push({
                name: 'AssetRework',
                query: {id: this.assetId, containerId: this.containerId, origin: this.origin}
            });
        },
        next() {
            if (this.origin && this.origin === 'assetDetail') {
                this.$router.push({
                    name: 'Asset',
                    query: {id: this.assetId, containerId: this.containerId}
                });
            } else {
                this.$router.push({
                    name: 'ContainerAssetOverview',
                    query: {id: this.containerId}
                });
            }
        },
        getAsset() {
            this.isLoadingAsset = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/find-by-id');
            url.searchParams.set('assetId', this.assetId);
            url.searchParams.set('withContainer', true);

            this.$http.get(url.href).then((data) => {
                this.isLoadingAsset = false;
                this.asset = data.data;
                this.asset.CONTAINER_ID = null;

                this.getRevalueInstructions();
                this.getContainers();

                this.$nextTick(() => {
                    this.fixHeaderWidths();
                });
            });
        },
        getCustomAttributes() {
            this.isLoadingCustomAttributes = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-custom-attributes');
            url.searchParams.append('assetId', this.assetId);
            url.searchParams.append('displayOption', 'RETURN_DOCK_REVALUE_ASSET');

            this.$http.get(url.href).then((data) => {
                this.isLoadingCustomAttributes = false;
                this.customAttributes = data.data;
            });
        },
        getDisplayCustomAttributes() {
            this.isLoadingDisplayCustomAttributes = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-custom-attribute-display-values');
            url.searchParams.append('assetId', this.assetId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingDisplayCustomAttributes = false;

                if (data.data) {
                    for (const [displayOption, customAttributes] of Object.entries(data.data)) {
                        for (const [key, customAttribute] of Object.entries(customAttributes)) {
                            if (customAttribute.value) {
                                if (!this.displayCustomAttributes[displayOption]) {
                                    this.displayCustomAttributes[displayOption] = {};
                                }

                                this.displayCustomAttributes[displayOption][key] = customAttribute;
                            }
                        }
                    }
                }
            });
        },
        getRevalueInstructions() {
            this.isLoadingInstructions = true;

            this.$store.dispatch('products/getRevalueInstructions', [this.asset.PRODUCT_ID, this.asset.SKU]).then((data) => {
                this.isLoadingInstructions = false;

                if (data) {
                    this.revalueInstructionMessage = data.message;
                    this.revalueInstructionImages = data.images;
                }
            });
        },
        getGradingSet() {
            this.isLoadingGradingSet = true;

            this.$store.dispatch('assets/getGradingLabels', this.assetId).then((data) => {
                this.isLoadingGradingSet = false;
                this.grades = data;
            });
        },
        getContainers() {
            this.isLoadingContainers = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/get-all');
            url.searchParams.append('storageType', 'AFS');
            url.searchParams.append('storageTypeIncludeNull', true);

            this.$http.get(url.href).then((data) => {
                this.isLoadingContainers = false;
                this.containers = data.data;
                this.filterContainers();
            });
        },
        filterContainers() {
            /* Retrieve containers which are not full and have the same disposition type and return bird account ID
               as the asset, or do not have a disposition type or return bird account ID yet */
            this.filteredContainers = [];

            if (this.containers && this.containers.length > 0) {
                this.containers.forEach((container) => {
                    if (container.IS_FULL == 0 && (container.DISPOSITION_TYPE == this.asset.DISPOSITION_TYPE || container.DISPOSITION_TYPE == null) && (container.RETURNBIRD_ACCOUNT_ID == this.asset.RETURNBIRD_ACCOUNT_ID || container.RETURNBIRD_ACCOUNT_ID == null)) {
                        this.filteredContainers.push({value: parseInt(container.ID), label: container.LICENSE_PLATE});
                    }
                });
            }
        },
        getContainerTypes() {
            this.isLoadingContainerTypes = true;

            this.$store.dispatch('containers/getContainerTypes').then((data) => {
                this.isLoadingContainerTypes = false;
                this.containerTypes = data;
            });
        },
        createContainer() {
            if (!this.newContainer.licensePlate) {
                this.errors = {LICENSE_PLATE: 'License plate is required'};
                return false;
            }

            if (!this.newContainer.type) {
                this.errors = {TYPE: 'Container type is required'};
                return false;
            }

            this.isCreatingContainer = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/create-container');
            let params = new URLSearchParams();
            params.append('Container[LICENSE_PLATE]', this.newContainer.licensePlate);
            params.append('Container[TYPE]', this.newContainer.type);

            this.$http.post(url, params).then((data) => {
                this.isCreatingContainer = false;

                if (data.data.code === 100) {
                    this.newContainer = {
                        licensePlate: null,
                        type: null
                    };

                    this.containers.push(data.data.data);

                    this.filterContainers();
                    this.showCreateContainerModal = false;
                    this.asset.CONTAINER_ID = data.data.data.ID;
                } else {
                    this.errors = data.data.errors;
                }
            });
        },
        uploadImages() {
            if (this.getSelectedImageCount() === 0) {
                this.errors = {fileUpload: 'No images selected to upload.'};
                return false;
            }

            this.errors = {};
            this.isUploadingAssetDispositionImages = true;

            let formData = new FormData();
            formData.append('FileModel[CATEGORY]', 'assetRevalue');

            for (let i in this.$refs.file.files) {
                formData.append('File[' + i + ']', this.$refs.file.files[i]);
            }

            let url = new URL(this.$store.state.baseUrl + 'assets/upload-asset-disposition-images');
            url.searchParams.set('assetId', this.assetId);

            this.$http.post(url.href, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((data) => {
                this.isUploadingAssetDispositionImages = false;

                if (data.data === undefined) {
                    this.errors = {fileUpload: 'Something went wrong while trying to upload the image(s).'};
                } else if (data.data.code === 100) {
                    for (let i in data.data.data) {
                        this.assetDispositionImages.push(data.data.data[i]);
                    }
                } else if (data.data.code === 422) {
                    if (typeof data.data.errors === 'object') {
                        this.errors = data.data.errors;
                    } else {
                        this.errors = {fileUpload: data.data.errors};
                    }

                    if (data.data.data) {
                        for (let i in data.data.data) {
                            this.assetDispositionImages.push(data.data.data[i]);
                        }
                    }
                }
            });
        },
        getUploadedImages() {
            this.isLoadingAssetDispositionImages = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-asset-disposition-images');
            url.searchParams.set('assetId', this.assetId);
            url.searchParams.set('category', ['assetGrade', 'assetRework', 'assetRework']);

            this.$http.get(url.href).then((data) => {
                this.isLoadingAssetDispositionImages = false;

                if (data.data.code === 100) {
                    this.assetDispositionImages = data.data.data;
                } else if (data.data.code === 422) {
                    this.errors = {fileUpload: data.data.errors};
                }
            });
        },
        deleteImage(id) {
            this.errors = {};

            this.imagesBeingDeleted.push(id);

            const url = new URL(this.$store.state.baseUrl + 'assets/delete-asset-disposition-image');
            const params = new URLSearchParams();
            params.append('File[ASSET_ID]', this.assetId);
            params.append('File[FILE_ID]', id);

            this.$http.post(url.href, params).then((data) => {
                const imagesBeingDeletedIndex = this.imagesBeingDeleted.indexOf(id);

                if (imagesBeingDeletedIndex > -1) {
                    this.imagesBeingDeleted.splice(imagesBeingDeletedIndex, 1);
                }

                if (data.data === undefined) {
                    this.errors = {fileUpload: 'Something went wrong while trying to delete the image.'};
                } else if (data.data.code === 100) {
                    const index = this.assetDispositionImages.findIndex(image => image.ID === id);

                    if (index > -1) {
                        this.assetDispositionImages.splice(index, 1);
                    }
                } else if (data.data.code === 422) {
                    this.errors = {fileUpload: data.data.errors};
                }
            });
        },
        addScreenshot(screenshot) {
            this.screenshots.push(screenshot);
        },
        deleteScreenshot(id) {
            const index = this.screenshots.findIndex(screenShot => screenShot.id === id);

            if (index > -1) {
                this.screenshots.splice(index, 1);
            }
        },
        uploadScreenshots() {
            if (!this.screenshots || this.screenshots.length === 0) {
                this.errors = {fileUpload: 'No images selected to upload.'};
                return false;
            }

            this.errors = {};
            this.isUploadingScreenshots = true;

            let formData = new FormData();
            formData.append('FileModel[CATEGORY]', 'assetRevalue');

            for (let i in this.screenshots) {
                formData.append('File[' + i + ']', this.screenshots[i].file);
            }

            let url = new URL(this.$store.state.baseUrl + 'assets/upload-asset-disposition-images');
            url.searchParams.set('assetId', this.assetId);

            this.$http.post(url.href, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((data) => {
                this.isUploadingScreenshots = false;

                if (data.data === undefined) {
                    this.errors = {fileUpload: 'Something went wrong while trying to upload the screenshot(s).'};
                } else if (data.data.code === 100) {
                    for (let i in data.data.data) {
                        this.assetDispositionImages.push(data.data.data[i]);
                    }

                    this.screenshots = [];
                } else if (data.data.code === 422) {
                    if (typeof data.data.errors === 'object') {
                        this.errors = data.data.errors;
                    } else {
                        this.errors = {fileUpload: data.data.errors};
                    }

                    if (data.data.data) {
                        for (let i in data.data.data) {
                            this.assetDispositionImages.push(data.data.data[i]);
                        }
                    }
                }
            });
        },
        switchUploadType() {
            this.isUsingCameraUpload = !this.isUsingCameraUpload;
        },
        getSelectedImageCount() {
            const fileUpload = document.getElementById('fileUpload');

            if (!fileUpload) {
                return 0;
            }

            return fileUpload.files.length;
        },
        getRemainingAvailableImageCount() {
            return this.imageCountLimit - this.assetDispositionImages.length;
        },
        save() {
            if (this.isAddingPictures) {
                /* Close the Webcam component without continuing.
                 * This is done to prevent the operator from accidentally ending the Revalue process too soon. */
                this.shouldStopAddingPictures = true;

                this.$nextTick(() => {
                    this.shouldStopAddingPictures = false;
                });

                return false;
            }

            if (!this.validateFields()) {
                return false;
            }

            this.errors = {};
            this.isSavingAsset = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/save');
            url.searchParams.set('assetId', this.assetId);

            let params = new URLSearchParams();
            params.append('Asset[CUSTOM_ATTRIBUTE_OPTION]', 'RETURN_DOCK_REVALUE_ASSET');
            params.append('Asset[REWORKED_GRADE]', this.asset.REWORKED_GRADE);
            params.append('Asset[CONTAINER_ID]', this.asset.CONTAINER_ID);
            params.append('Asset[STORAGE_TYPE]', 'AFS');

            let customAttributesData = this.$refs.customAttrForm.getData();

            for (const [key, value] of Object.entries(customAttributesData)) {
                if (value !== null) {
                    params.append('Asset[' + key + ']', value);
                }
            }

            this.$http.post(url.href, params).then((data) => {
                this.isSavingAsset = false;

                if (data.data.code === 100) {
                    this.next();
                } else if (data.data.code === 422) {
                    this.errors = data.data.errors;
                }
            });
        },
        validateFields() {
            this.errors = {};

            if (!this.asset.REWORKED_GRADE) {
                this.errors['REWORKED_GRADE'] = 'Please select the new grade of the revalued asset.';
            }

            if (!this.asset.CONTAINER_ID) {
                this.errors['CONTAINER_ID'] = 'Please select a container.';
            }

            if (this.screenshots.length > 0) {
                this.errors['fileUpload'] = 'There are some screenshots which have not yet been uploaded. Please upload or delete them to continue.';
            }

            return !Object.keys(this.errors).length > 0;
        },
        selectedFile() {
            this.errors = {};

            if (this.getSelectedImageCount() > (this.imageCountLimit - this.assetDispositionImages.length)) {
                this.errors['fileUpload'] = 'You can only add 5 images.';

                const fileUpload = document.getElementById('fileUpload');

                if (fileUpload) {
                    fileUpload.value = '';
                }
            }
        },
        setRevalueGrade(revaluedGrade) {
            this.asset.REWORKED_GRADE = revaluedGrade;
            this.isGrading = false;
        },
        fixHeaderWidths() {
            if (!this.$refs['header-contents'] || !this.$refs['header-titles']) {
                return;
            }

            const headerContents = this.$refs['header-contents'].children;
            const headerTitles = this.$refs['header-titles'].children;
            let index = 0;

            for (const content of headerContents) {
                let width = content.clientWidth + 'px';

                content.style.width = width;
                headerTitles[index].style.width = width;

                index++;
            }
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        datepickerFormatter: function (date) {
            let d = new Date(date);
            return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
        },
    },
}
</script>

<style scoped lang="scss">
    h5 {
        margin-bottom: 40px;
        font-weight: 600;
    }

    .table-second-level tbody tr td {
        padding-top: 0;
        color: #1d2640;
    }

    .table-second-level thead tr th {
        width: auto;
        min-width: 110px;
    }

    .grade-separator {
        margin-left: -20px;
        margin-right: -20px;
        border-bottom: 1px solid #d9d7e4;
    }

    .btn-create-container {
        padding: 10px 15px;
    }

    .checkbox-container.container-full {
        width: 40px;
        height: 40px;

        .checkmark {
            position: relative;
        }
    }

    .scrollable-content {
        padding: 30px 0;
    }
</style>
