<template>
    <div>
        <div v-for="(ca, key) in customAttributes" :key="key">
            <div class="form-group" v-if="ca.attributeModel.RTN_MCA_ACTIVE == true">
                <label class="label">
                    {{ca.label}}

                    <span v-if="ca.required">*</span>
                </label>

                <template v-if="ca.type === 'boolean'">
                    <div class="d-flex flex-wrap">
                        <label class="radio-container mr-3">
                            Yes

                            <input type="radio" class="form-control" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                                   value="true">
                            <span class="checkmark"></span>
                        </label>

                        <label class="radio-container">
                            No

                            <input type="radio" class="form-control" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                                   value="false">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </template>

                <template v-else-if="ca.type === 'date'">
                    <Datepicker v-model="form[ca.attributeModel.RTN_MCA_NAME]" :format="datepickerFormatter" @selected="formatSelectedDate(ca.attributeModel.RTN_MCA_NAME)"></Datepicker>
                </template>

                <template v-else-if="ca.type === 'input'">
                    <input type="text" class="form-control" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                           :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER" value="ca.value">
                </template>

                <template v-else-if="ca.type === 'select'">
                    <model-list-select
                        :list="getFilteredCustomAttributeOptions(ca.attributeModel.modelCustomAttributeOptions)"
                        option-value="value"
                        option-text="label"
                        v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                        required
                    >
                    </model-list-select>
                </template>

                <template v-else-if="ca.type === 'checkbox'">
                    <div class="d-lg-flex flex-wrap">
                        <label class="checkbox-container mr-3">
                            <input type="checkbox" class="form-control" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                                   :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER" value="ca.value">

                            <span class="checkmark"></span>
                        </label>
                    </div>
                </template>

                <template v-else-if="ca.type === 'text'">
                    <textarea class="form-control" rows="6" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                              :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"></textarea>
                </template>

                <template v-else-if="ca.type === 'url'">
                    <input type="url" class="form-control" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                           :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER" value="ca.value">
                </template>

                <template v-else-if="ca.type === 'radio'">
                    <div class="d-flex flex-wrap mt-3 pl-2">
                        <label class="radio-container mr-3" v-for="option in getFilteredCustomAttributeOptions(ca.attributeModel.modelCustomAttributeOptions)" :key="option.value">
                            {{ option.label }}

                            <input type="radio" class="form-control" v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                                   :value="option.value">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </template>

                <InputFieldError :attribute="ca.attributeModel.RTN_MCA_LABEL" :errors="errors"/>
            </div>
        </div>
    </div>
</template>

<script>
    import InputFieldError from './_InputFieldError.vue';
    import Datepicker from 'vuejs-datepicker';
    import {ModelListSelect} from 'vue-search-select';
    import _ from 'lodash';

    export default {
        components: {
            'InputFieldError': InputFieldError,
            'Datepicker': Datepicker,
            'ModelListSelect': ModelListSelect,
        },
        props: [
            'customAttributes',
            'errors',
        ],
        data() {
            return {
                form: {},
            }
        },
        methods: {
            getData() {
                return this.form;
            },
            datepickerFormatter: function (date) {
                let d = new Date(date);
                return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
            },
            formatSelectedDate: function (date) {
                this.$nextTick(() => {
                    if (this.form[date]) {
                        this.form[date] = this.form[date].getUTCFullYear() + '-' + ("0" + (this.form[date].getUTCMonth() + 1)).slice(-2) + '-' + ("0" + this.form[date].getUTCDate()).slice(-2);
                    }
                });
            },
            getFilteredCustomAttributeOptions(options) {
                let filteredOptions = [];

                _.forOwn(options, (option) => {
                    filteredOptions.push({value: parseInt(option.RTN_MCAO_ID), label: option.RTN_MCAO_LABEL});
                });

                return filteredOptions;
            }
        },
        created() {
            let data = {};

            _.forOwn(this.customAttributes, function (attribute, key) {
                if (attribute.attributeModel.RTN_MCA_ACTIVE == true) {
                    if (attribute.type === 'checkbox' && (attribute.value == false || attribute.value == 'false')) {
                        attribute.value = false;
                    }

                    Object.assign(data, {[key]: attribute.value});
                }
            });

            this.form = data;
        }
    }
</script>


<style>

</style>
