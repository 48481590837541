<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <section class="section-dashboard">
                <div class="container">
                    <div class="d-flex flex-wrap justify-content-between mb-5">
                        <h2 class="section-title">
                            Shipping inbound
                        </h2>
                    </div>

                    <template v-if="isLoadingSubmodules">
                        Loading submodules...
                    </template>

                    <template v-else-if="!canReceiveToManifest && !canReceiveToMasterBox">
                        No submodules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <MenuCardItem
                                :route="{name: 'ShippingReceiveToManifest'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="RECEIVE PARCEL TO MANIFEST"
                                v-if="canReceiveToManifest"
                            />

                            <MenuCardItem
                                :route="{name: 'ShippingReceiveToMasterBox'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="RECEIVE PARCEL TO MASTER BOX"
                                v-if="canReceiveToMasterBox"
                            />
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingModuleSelect'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import MenuCardItem from '@/components/_MenuCardItem.vue';

export default {
    name: 'ShippingInboundDashboardPage',
    components: {
        'HeaderBar': HeaderBar,
        'MenuCardItem': MenuCardItem,
    },
    data() {
        return {
            isLoadingSubmodules: false,
            moduleAccess: {},
            canReceiveToManifest: false,
            canReceiveToMasterBox: false,
        };
    },
    created() {
        this.isLoadingSubmodules = true;
    },
    mounted() {
        this.$root.$on('loadedRoleModuleAccess', () => {
            this.isLoadingSubmodules = false;
            this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['shipping']?.['inbound'];
            this.canReceiveToManifest = typeof this.moduleAccess['receive-to-manifest'] !== 'undefined' && this.moduleAccess['receive-to-manifest'].ACCESS;
            this.canReceiveToMasterBox = typeof this.moduleAccess['receive-to-master-box'] !== 'undefined' && this.moduleAccess['receive-to-master-box'].ACCESS;
        });
    },
    methods: {}
}
</script>
