<template>
    <div>
        <table class="table-third-level" v-if="workOrderContainers && Object.keys(workOrderContainers).length > 0">
            <thead>
                <tr>
                    <th class="w-auto">
                        License plate
                    </th>

                    <th class="w-auto">
                        Account
                    </th>

                    <th class="w-auto">
                        Assets
                    </th>

                    <th class="w-auto"></th>

                    <th class="w-auto"></th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="container in workOrderContainers" :key="container.ID">
                    <template v-if="containersBeingRemoved.includes(container.ID) || containersBeingRemoved.includes(container.ID.toString())">
                        <td colspan="5" class="w-auto">
                            Removing container {{ container.LICENSE_PLATE }} from this shipment...
                        </td>
                    </template>

                    <template v-else>
                        <td class="w-auto">
                            {{ container.LICENSE_PLATE }}
                        </td>

                        <td class="w-auto">
                            {{ container.RETURNBIRD_ACCOUNT_NAME }}
                        </td>

                        <td class="w-auto">
                            <template v-if="container.assets">
                                {{ container.assets.length }}
                            </template>

                            <template v-else>
                                -
                            </template>
                        </td>

                        <td class="w-auto">
                            <template v-if="documentsBeingGenerated && documentsBeingGenerated.includes(container.ID)">
                                Generating container document...
                            </template>

                            <template v-else>
                                <a href="#" @click.prevent="getContainerPdf(container.ID)">
                                    Container document
                                </a>
                            </template>
                        </td>

                        <td class="w-auto text-right">
                            <a href="#" @click.prevent="showRemoveContainerFromWorkOrderModal(container)" class="action-btn" v-if="!workOrder.SHIPMENT_ID">
                                &times;
                            </a>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>

        <div class="p-4" v-else>
            <button type="button" class="btn btn-danger sm" @click.prevent="$emit('delete-work-order')" :disabled="disabled || isAddingContainers">
                Delete
            </button>

            <p class="pt-4 mb-0">
                No assets added to this work order.
            </p>
        </div>

        <table class="table-second-level" v-if="!workOrder.SHIPMENT_ID">
            <tbody>
                <tr>
                    <td colspan="5" class="pb-0">
                        <p class="m-0 action-link" v-b-toggle="'available-models-to-add-dropdown'">
                            <span class="when-open">
                                Hide available containers
                            </span>

                            <span class="when-closed">
                                Add container
                            </span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td colspan="5">
                        <b-collapse id="available-models-to-add-dropdown">
                            <template v-if="isLoadingContainersReadyToShip">
                                Loading available containers...
                            </template>

                            <template v-else-if="filteredContainersReadyToShip.length === 0">
                                There are no available containers to add to this shipment.
                            </template>

                            <template v-else-if="isAddingContainers">
                                Adding containers to this shipment...
                            </template>

                            <template v-else>
                                <table class="table-third-level">
                                    <thead>
                                        <tr>
                                            <th class="w-20 pr-0"></th>

                                            <th class="w-auto pl-0">
                                                License plate
                                            </th>

                                            <th class="w-auto">
                                                Account
                                            </th>

                                            <th class="w-auto">
                                                Assets
                                            </th>

                                            <th class="w-auto"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="container in filteredContainersReadyToShip" :key="container.ID">
                                            <td class="w-20 pr-0">
                                                <label class="checkbox-container mb-20 mr-1">
                                                    <input type="checkbox" v-model="selectedContainersToShip[container.ID]" :disabled="disabled || isAddingContainers">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </td>

                                            <td class="w-auto pl-0">
                                                {{ container.LICENSE_PLATE }}
                                            </td>

                                            <td class="w-auto">
                                                {{ container.RETURNBIRD_ACCOUNT_NAME }}
                                            </td>

                                            <td class="w-auto">
                                                {{ container.assets.length }}
                                            </td>

                                            <td class="w-auto">
                                                <template v-if="documentsBeingGenerated && documentsBeingGenerated.includes(container.ID)">
                                                    Generating container document...
                                                </template>

                                                <template v-else>
                                                    <a href="#" @click.prevent="getContainerPdf(container.ID)">
                                                        Container document
                                                    </a>
                                                </template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <button class="btn btn-primary btn-md mt-3" :disabled="disabled || selectedContainers.length === 0" @click.prevent="addContainersToWorkOrder()">
                                    Add containers
                                </button>
                            </template>
                        </b-collapse>
                    </td>
                </tr>
            </tbody>

            <InputFieldError :errors="errors" />
        </table>

        <ConfirmationPopup v-if="isShowingRemoveContainerFromWorkOrderModal"
                           :message="'Are you sure you want to remove container ' + containerToRemove.LICENSE_PLATE + ' from this work order?'"
                           @confirm="removeContainerFromWorkOrder()"
                           @deny="isShowingRemoveContainerFromWorkOrderModal = false"
        />
    </div>
</template>

<script>
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import {BCollapse, VBToggle} from 'bootstrap-vue';

    export default {
        props: [
            'workOrder',
            'disabled'
        ],
        components: {
            'ConfirmationPopup': ConfirmationPopup,
            'InputFieldError': InputFieldError,
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                workOrderContainers: [],
                errors: {},
                isShowingAssetsForContainer: {},
                isShowingRemoveContainerFromWorkOrderModal: false,
                isLoadingContainersReadyToShip: false,
                isAddingContainers: false,
                containerToRemove: null,
                containersBeingRemoved: [],
                documentsBeingGenerated: [],
                containersReadyToShip: [],
                selectedContainersToShip: {},
            }
        },
        created() {
            this.workOrderContainers = this.workOrder.containers ?? [];
            this.getContainersReadyToShip();
        },
        methods: {
            getAssetStatus(asset) {
                if (asset) {
                    const assetFlags = {
                        IS_ARCHIVED: 'Archived',
                        IS_EXPECTED: 'Expected',
                        IS_IN_PROGRESS: 'In progress',
                        IS_RESERVED: 'Reserved',
                        IS_ON_HAND: 'Available',
                    };

                    for (const [attribute, label] of Object.entries(assetFlags)) {
                        if (asset?.[attribute] === 1 || asset?.[attribute] === '1') {
                            return label;
                        }
                    }
                }

                return '-';
            },
            toggleShowAssets(containerId) {
                if (!this.isShowingAssetsForContainer[containerId]) {
                    this.$set(this.isShowingAssetsForContainer, containerId, true);
                    return;
                }

                this.isShowingAssetsForContainer[containerId] = !this.isShowingAssetsForContainer[containerId];
            },
            showRemoveContainerFromWorkOrderModal(container) {
                this.containerToRemove = container;
                this.isShowingRemoveContainerFromWorkOrderModal = true;
            },
            getContainerPdf(containerId) {
                this.documentsBeingGenerated.push(containerId);

                const url = new URL(this.$store.state.baseUrl + 'pdf/container');
                url.searchParams.append('id', containerId);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    let index = this.documentsBeingGenerated.indexOf(containerId);
                    this.documentsBeingGenerated.splice(index, 1);

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            addContainersToWorkOrder() {
                this.isAddingContainers = true;

                let selectedContainerIds = [];

                for (let i in this.selectedContainers) {
                    selectedContainerIds.push(this.selectedContainers[i].ID);
                }

                const url = new URL(this.$store.state.baseUrl + 'work-order/add-containers-to-work-order');
                const params = new URLSearchParams();
                params.append('WorkOrder[ID]', this.workOrder.ID);
                params.append('WorkOrder[CONTAINER_IDS]', selectedContainerIds);

                this.$http.post(url.href, params).then((data) => {
                    this.isAddingContainers = false;

                    if (data?.data?.code === 100) {
                        for (let i in this.selectedContainers) {
                            this.workOrderContainers.push(this.selectedContainers[i]);
                        }

                        this.selectedContainersToShip = [];
                        this.getContainersReadyToShip();
                    } else if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            removeContainerFromWorkOrder() {
                this.errors = {};
                this.isShowingRemoveContainerFromWorkOrderModal = false;
                this.containersBeingRemoved.push(this.containerToRemove.ID.toString());

                const url = new URL(this.$store.state.baseUrl + 'work-order/remove-container-from-work-order');
                const params = new URLSearchParams();
                params.append('WorkOrder[ID]', this.workOrder.ID);
                params.append('WorkOrder[CONTAINER_ID]', this.containerToRemove.ID);

                this.$http.post(url.href, params).then((data) => {
                    if (data?.data?.code === 100) {
                        const index = this.workOrderContainers.findIndex((container) => container.ID.toString() === this.containerToRemove.ID.toString());

                        if (index > -1) {
                            this.workOrderContainers.splice(index, 1);
                        }

                        this.getContainersReadyToShip();
                    } else if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    }

                    const containersBeingRemovedIndex = this.containersBeingRemoved.indexOf(this.containerToRemove.ID);
                    this.containersBeingRemoved.splice(containersBeingRemovedIndex, 1);
                    this.containerToRemove = null;
                });
            },
            getContainersReadyToShip() {
                this.isLoadingContainersReadyToShip = true;
                const url = new URL(this.$store.state.baseUrl + 'containers/get-all');
                url.searchParams.set('includeOnShipment', false);
                url.searchParams.set('includeOnWorkOrder', false);
                url.searchParams.set('closedOnly', true);

                this.containersReadyToShip = null;

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainersReadyToShip = false;
                    this.containersReadyToShip = data.data;
                });
            },
        },
        computed: {
            containerArray() {
                if (!this.containersReadyToShip) {
                    return [];
                }

                let result = [];

                for (let i in this.containersReadyToShip) {
                    const container = this.containersReadyToShip[i];

                    if (container.RETURNBIRD_ACCOUNT_ID.toString() !== this.workOrder.MERCHANT_ACCOUNT_ID.toString()) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            filteredContainersReadyToShip() {
                if (!this.containerArray || this.containerArray.length === 0) {
                    return [];
                }

                let result = [];

                for (let i in this.containerArray) {
                    const container = this.containerArray[i];

                    if (this.showSelectedContainersOnly && !this.selectedContainers.includes(container)) {
                        continue;
                    }

                    if (this.containerLicensePlate && !container.LICENSE_PLATE.toLowerCase().includes(this.containerLicensePlate.toLowerCase()) && !container.ID.includes(this.containerLicensePlate)) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            selectedContainers() {
                if (!this.selectedContainersToShip || Object.keys(this.selectedContainersToShip).length === 0) {
                    return [];
                }

                let result = [];

                for (let i = 0; i < Object.keys(this.selectedContainersToShip).length; i++) {
                    const key = Object.keys(this.selectedContainersToShip)[i];
                    const value = Object.values(this.selectedContainersToShip)[i];

                    if (value) {
                        const container = this.containersReadyToShip.find(container => container.ID == key);

                        if (container) {
                            result.push(container);
                        }
                    }
                }

                return result;
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .sku-row {
        background: #fff;
        border: 1px solid #d9d7e4;
        border-radius: 13px;
    }

    .action-btn {
        padding: 3px 9px;
        border: 1px solid #4a90e2;
        text-decoration: none;
        border-radius: 5px;
    }

    .action-btn:hover {
        color: #fff;
        background-color: #4a90e2;
    }

    .collapsed > .when-open, .not-collapsed > .when-closed {
        display: none;
    }

    .action-link {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }

    .action-link:hover {
        color: #0056b3;
        text-decoration: underline;
    }
</style>
