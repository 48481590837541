<template>
    <div>
        <HeaderBar title="Manifest"></HeaderBar>

        <main class="main">

            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">

                            <div class="table-responsive">
                                <template v-if="isLoadingManifest">
                                    Loading manifest...
                                </template>

                                <template v-else-if="!manifest || Object.keys(manifest).length === 0">
                                    Unable to find return manifest.
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Manifest</th>
                                                <th>Manifest ID</th>
                                                <th>First tier location</th>
                                                <th>Sorting type</th>
                                                <th>Received returns</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ manifest.MANIFEST_CODE }}
                                                                </th>

                                                                <th>
                                                                    {{ manifest.ID }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="manifest.location && manifest.location.NAME">
                                                                        {{ manifest.location.NAME }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    <template v-if="manifest.SORTING_TYPE">
                                                                        {{ manifest.SORTING_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ Object.keys(manifest.receivedReturnManifestRows).length }}

                                                                    of

                                                                    {{ Object.keys(manifest.shippedReturnManifestRows).length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5" v-if="manifest.shippedReturnManifestRows && Object.keys(manifest.shippedReturnManifestRows).length > 0">
                                                                    <table class="table-third-level">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="w-auto"></th>
                                                                                <th class="w-auto">Return</th>
                                                                                <th class="w-auto">External Reference</th>
                                                                                <th class="w-auto">Account</th>
                                                                                <th class="w-auto">Channel</th>
                                                                                <th class="w-auto">Return Policy</th>
                                                                                <th class="w-auto">Country</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(returnManifestRow, key) in manifest.shippedReturnManifestRows" :key="key">
                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RECEIVED_TIMESTAMP || returnManifestRow.TRANSFERRED_TIMESTAMP">
                                                                                        &checkmark;
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_REFERENCE">
                                                                                        {{ returnManifestRow.RETURN_REFERENCE }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        {{ returnManifestRow.SHIPMENT_TRACKING_CODE }}
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_EXTERNAL_REFERENCE">
                                                                                        {{ returnManifestRow.RETURN_EXTERNAL_REFERENCE }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.MERCHANT_ACCOUNT_NAME">
                                                                                        {{ returnManifestRow.MERCHANT_ACCOUNT_NAME }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.PORTAL_NAME">
                                                                                        {{ returnManifestRow.PORTAL_NAME }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.MERCHANT_SETUP">
                                                                                        {{ returnManifestRow.MERCHANT_SETUP }}
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>

                                                                                <td class="w-auto">
                                                                                    <template v-if="returnManifestRow.RETURN_ORIGIN_COUNTRY">
                                                                                        <template v-if="countryList && countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY]">
                                                                                            {{ countryList[returnManifestRow.RETURN_ORIGIN_COUNTRY] }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            {{ returnManifestRow.RETURN_ORIGIN_COUNTRY }}
                                                                                        </template>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        -
                                                                                    </template>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td colspan="5" v-else>
                                                                    There are no unreceived returns on this manifest.
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="5">
                                                                    <div class="d-flex justify-content-between">
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3"
                                                                            @click.prevent="getManifestListPdf()"
                                                                            :disabled="isGeneratingDocument || isClosingManifest"
                                                                        >
                                                                            Manifest list
                                                                        </button>

                                                                        <div v-if="errors && Object.keys(errors).length > 0">
                                                                            <InputFieldError attribute="closingManifest" :errors="errors"/>
                                                                        </div>

                                                                        <div class="align-self-center" v-else-if="isGeneratingDocument">
                                                                            Generating shipping document...
                                                                        </div>

                                                                        <div class="align-self-center" v-else-if="isClosingManifest">
                                                                            Closing manifest...
                                                                        </div>

                                                                        <button type="button" class="btn btn-primary p-3" @click.prevent="showConfirmationModal = true" :disabled="isGeneratingDocument || isClosingManifest">
                                                                            Close manifest
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->

            </form>

        </main>

        <ConfirmationPopup v-if="showConfirmationModal"
                           :message="'Are you sure you want to close manifest ' + manifest.MANIFEST_CODE + '?'"
                           @deny="showConfirmationModal = false" @confirm="closeManifest()"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                errors: {},
                isLoadingManifest: false,
                isLoadingCountryList: false,
                manifestCode: this.$route.query.code,
                manifest: null,
                selectedLocation: null,
                countryList: null,
                isGeneratingDocument: false,
                isClosingManifest: false,
                showConfirmationModal: false,
            };
        },
        created() {
            this.isLoadingManifest = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;
                }

                this.getManifest();
            });

            this.getCountryList();
        },
        methods: {
            getManifest() {
                this.isLoadingManifest = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-incoming');
                url.searchParams.append('manifestCode', this.manifestCode);
                url.searchParams.append('destinationLocationId', this.selectedLocation);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data.data.data;
                });
            },
            previous() {
                this.$router.push({
                    name: 'IncomingManifestOverview'
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
            getManifestListPdf() {
                this.errors = {};
                this.isGeneratingDocument = true;

                const url = new URL(this.$store.state.baseUrl + 'pdf/incoming-manifest');
                url.searchParams.append('id', this.manifest.ID);
                url.searchParams.append('destinationLocationId', this.selectedLocation);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    this.isGeneratingDocument = false;

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            closeManifest() {
                this.errors = {};
                this.isClosingManifest = true;
                this.showConfirmationModal = false;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/close-manifest');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifest.ID);
                params.append('ReturnManifest[destinationLocationId]', this.selectedLocation);

                this.$http.post(url.href, params).then((data) => {
                    this.isClosingManifest = false;

                    if (data.data.code === 100) {
                        this.$router.push({
                            name: 'IncomingManifestOverview'
                        });
                    } else {
                        this.errors = {closingManifest: data.data.errors};
                        return false;
                    }
                });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped>

</style>
