<template>
    <div class="col-2 block-left">
        <ul v-for="(category, categoryKey) in menu.categories" v-bind:key="categoryKey">
            <li>
                <strong>
                    <router-link v-bind:to="category.link">
                        {{ category.name }}
                    </router-link>
                </strong>
            </li>
            <li>
                <ul>
                    <li v-for="(item, itemKey) in category.items" v-bind:key="itemKey">
                        <router-link v-bind:to="item.link" v-bind:class="{ active: categoryKey == menu.activeCategory && itemKey == menu.activeItem }">
                            {{ typeof item.name !== 'undefined' ? item.name : itemKey }}
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>


<script>
    export default {
        /*
            Menu structure expected below:
        */
        /*
            menu: {
                activeItem: 'Create',
                activeCategory: 'Role',
                categories: {
                    Role: {
                        name: 'Role',
                        link: '/roleOverview',
                        items: {
                            Overview: { link: '/roleOverview' },
                            Create: { link: '/roleUpdate' }
                        }
                    },
                    User: {
                        name: 'User',
                        link: '/userOverview',
                        items: {
                            Overview: { link: '/userOverview' },
                            Create: { link: '/userUpdate' },
                        }
                    }
                }
            }
        */


        props: [
            'menu'
        ],
        data() {
            return {

            };
        },
    }
</script>

<style scoped>
    a {
        text-decoration: none !important;
        color: black;
    }

    ul li {
        font-size: 21px;
        color: #0C1334;
        cursor: pointer;
    }

    ul {
        margin-bottom: 20px;
    }

    .active {
        color: blue !important;
    }
</style>
