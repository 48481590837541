import axios from '@/classes/Axios';

const productStore = {
    namespaced: true,
    state: {
        gradingProduct: null,
        unpackInstructions: null,
        inspectInstructions: null,
        reworkInstructions: null,
        revalueInstructions: null,
    },
    mutations: {
        setGradingProduct(state, gradingProduct) {
            state.gradingProduct = gradingProduct;
        },
        clearGradingProduct(state) {
            state.gradingProduct = null;
        },
        setUnpackInstructions(state, [productId, unpackInstructions]) {
            if (!state.unpackInstructions) {
                state.unpackInstructions = {};
            }

            state.unpackInstructions[productId] = unpackInstructions;
        },
        setInspectInstructions(state, [productId, inspectInstructions]) {
            if (!state.inspectInstructions) {
                state.inspectInstructions = {};
            }

            state.inspectInstructions[productId] = inspectInstructions;
        },
        setReworkInstructions(state, [productId, reworkInstructions]) {
            if (!state.reworkInstructions) {
                state.reworkInstructions = {};
            }

            state.reworkInstructions[productId] = reworkInstructions;
        },
        setRevalueInstructions(state, [productId, revalueInstructions]) {
            if (!state.revalueInstructions) {
                state.revalueInstructions = {};
            }

            state.revalueInstructions[productId] = revalueInstructions;
        },
        clear(state) {
            state.gradingProduct = null;
            state.unpackInstructions = null;
            state.inspectInstructions = null;
        }
    },
    actions: {
        getGradingProduct(storeArgObj, productId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.gradingProduct && storeArgObj.state.gradingProduct.RTN_PDT_ID === productId) {
                    resolve(storeArgObj.state.gradingProduct);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'products/find-by-id');
                    url.searchParams.set('productId', productId);

                    axios.get(url.href).then((data) => {
                        let product = data.data;

                        storeArgObj.commit('setGradingProduct', product);
                        resolve(product);
                    });
                }
            });
        },
        getUnpackInstructions(storeArgObj, [productId, productSku]) {
            return new Promise((resolve) => {
                if (storeArgObj.state.unpackInstructions && storeArgObj.state.unpackInstructions[productId]) {
                    resolve(storeArgObj.state.unpackInstructions[productId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'products/get-instructions');
                    url.searchParams.set('sku', productSku);
                    url.searchParams.set('type', 'unpack');

                    axios.get(url.href).then((data) => {
                        let instructions = data.data;

                        storeArgObj.commit('setUnpackInstructions', [productId, instructions]);
                        resolve(instructions);
                    });
                }
            });
        },
        getInspectInstructions(storeArgObj, [productId, productSku]) {
            return new Promise((resolve) => {
                if (storeArgObj.state.inspectInstructions && storeArgObj.state.inspectInstructions[productId]) {
                    resolve(storeArgObj.state.inspectInstructions[productId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'products/get-instructions');
                    url.searchParams.set('sku', productSku);
                    url.searchParams.set('type', 'inspect');

                    axios.get(url.href).then((data) => {
                        let instructions = data.data;

                        storeArgObj.commit('setInspectInstructions', [productId, instructions]);
                        resolve(instructions);
                    });
                }
            });
        },
        getReworkInstructions(storeArgObj, [productId, productSku]) {
            return new Promise((resolve) => {
                if (storeArgObj.state.reworkInstructions && storeArgObj.state.reworkInstructions[productId]) {
                    resolve(storeArgObj.state.reworkInstructions[productId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'products/get-instructions');
                    url.searchParams.set('sku', productSku);
                    url.searchParams.set('type', 'rework');

                    axios.get(url.href).then((data) => {
                        let instructions = data.data;

                        storeArgObj.commit('setReworkInstructions', [productId, instructions]);
                        resolve(instructions);
                    });
                }
            });
        },
        getRevalueInstructions(storeArgObj, [productId, productSku]) {
            return new Promise((resolve) => {
                if (storeArgObj.state.revalueInstructions && storeArgObj.state.revalueInstructions[productId]) {
                    resolve(storeArgObj.state.revalueInstructions[productId]);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'products/get-instructions');
                    url.searchParams.set('sku', productSku);
                    url.searchParams.set('type', 'revalue');

                    axios.get(url.href).then((data) => {
                        let instructions = data.data;

                        storeArgObj.commit('setRevalueInstructions', [productId, instructions]);
                        resolve(instructions);
                    });
                }
            });
        },
    }
};

export default productStore;
