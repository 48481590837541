<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <section class="section-dashboard">
                <div class="container">
                    <div class="d-flex flex-wrap justify-content-between mb-5">
                        <h2 class="section-title">
                            Return outbound
                        </h2>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <InputFieldError attribute="global" :errors="errors"/>
                        </div>
                    </div>

                    <template v-if="isLoadingSubmodules || isLoadingSelectedLocation">
                        Loading submodules...
                    </template>

                    <template v-else-if="(!canShipUnsortedManifests && !canShipSortedManifests && !canShipShipments && !canShipContainers && !canShipWorkOrders) || !selectedLocation">
                        No submodules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="canShipUnsortedManifests">
                                <router-link :to="{name: 'ShipManifest', query: {type: 'unsorted'}}" class="card-item lg">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">SHIP MANIFESTS (UNSORTED)</span>
                                </router-link>
                            </div>

                            <div class="card-item-wrapper" v-if="canShipSortedManifests">
                                <div class="card-item lg">
                                    <router-link :to="{name: 'ShipManifest', query: {type: 'sorted', merchant: selectedAccountId}}" class="card-item--link mb-3" :class="!selectedAccountId ? 'inactive' : ''">
                                        <div class="card-item-img">
                                            <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                                 alt="">
                                        </div>

                                        <span class="d-block pt-4">SHIP MANIFESTS (SORTED)</span>
                                    </router-link>

                                    <template v-if="!isLoadingMerchantAccounts && merchantAccounts.length === 0">
                                        No merchant accounts found
                                    </template>

                                    <model-list-select
                                        :list="merchantAccounts"
                                        option-value="id"
                                        option-text="name"
                                        v-model="selectedAccountId"
                                        placeholder="Select account"
                                        required
                                        v-else
                                    >
                                    </model-list-select>
                                </div>
                            </div>

                            <div class="card-item-wrapper" v-if="canShipShipments">
                                <router-link :to="{name: 'ShipmentOverview'}" class="card-item lg">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">SHIPMENT OVERVIEW</span>
                                </router-link>
                            </div>
                        </div>

                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="canShipContainers">
                                <div class="card-item lg">
                                    <router-link :to="{name: 'ShipContainer', query: {merchant: selectedContainerAccountId}}" class="card-item--link mb-3" :class="!selectedContainerAccountId ? 'inactive' : ''">
                                        <div class="card-item-img">
                                            <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                                 alt="">
                                        </div>

                                        <span class="d-block pt-4">SHIP CONTAINERS</span>
                                    </router-link>

                                    <template v-if="!isLoadingMerchantAccounts && merchantAccounts.length === 0">
                                        No merchant accounts found
                                    </template>

                                    <model-list-select
                                            :list="merchantAccounts"
                                            option-value="id"
                                            option-text="name"
                                            v-model="selectedContainerAccountId"
                                            placeholder="Select account"
                                            required
                                            v-else
                                    >
                                    </model-list-select>
                                </div>
                            </div>

                            <div class="card-item-wrapper" v-if="canShipWorkOrders">
                                <div class="card-item lg">
                                    <router-link :to="{name: 'ShipWorkOrder', query: {merchant: selectedWorkOrderAccountId}}" class="card-item--link mb-3" :class="!selectedWorkOrderAccountId ? 'inactive' : ''">
                                        <div class="card-item-img">
                                            <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                                 alt="">
                                        </div>

                                        <span class="d-block pt-4">SHIP WORK ORDERS</span>
                                    </router-link>

                                    <template v-if="!isLoadingMerchantAccounts && merchantAccounts.length === 0">
                                        No merchant accounts found
                                    </template>

                                    <model-list-select
                                        :list="merchantAccounts"
                                        option-value="id"
                                        option-text="name"
                                        v-model="selectedWorkOrderAccountId"
                                        placeholder="Select account"
                                        required
                                        v-else
                                    >
                                    </model-list-select>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        name: 'ReturnOutboundDashboardPage',
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                isLoadingSubmodules: false,
                isLoadingMerchantAccounts: false,
                isLoadingCarrierLocations: false,
                moduleAccess: {},
                canShipUnsortedManifests: false,
                canShipSortedManifests: false,
                canShipShipments: false,
                canShipContainers: false,
                canShipWorkOrders: false,
                isLoadingSelectedLocation: false,
                selectedLocation: null,
                errors: {},
                merchantAccounts: [],
                selectedAccountId: null,
                selectedContainerAccountId: null,
                selectedWorkOrderAccountId: null,
                carrierLocationOptions: [],
                carrierLocationId: null,
            };
        },
        created() {
            this.isLoadingSubmodules = true;

            this.getSelectedLocation();
            this.getMerchantAccounts();
            this.getCarrierLocations();
        },
        mounted() {
            this.$root.$on('switchedLocation', () => {
                this.errors = {};
                this.getSelectedLocation();
            });

            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingSubmodules = false;
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['return']?.['outbound'];
                this.canShipUnsortedManifests = typeof this.moduleAccess['ship-unsorted-manifests'] !== 'undefined' && this.moduleAccess['ship-unsorted-manifests'].ACCESS;
                this.canShipSortedManifests = typeof this.moduleAccess['ship-sorted-manifests'] !== 'undefined' && this.moduleAccess['ship-sorted-manifests'].ACCESS;
                this.canShipShipments = typeof this.moduleAccess['ship-shipments'] !== 'undefined' && this.moduleAccess['ship-shipments'].ACCESS;
                this.canShipContainers = typeof this.moduleAccess['ship-containers'] !== 'undefined' && this.moduleAccess['ship-containers'].ACCESS;
                this.canShipWorkOrders = typeof this.moduleAccess['ship-work-orders'] !== 'undefined' && this.moduleAccess['ship-work-orders'].ACCESS;
            });
        },
        methods: {
            goToSubmodule(submodule) {
                if (!this.selectedLocation) {
                    this.errors = {global: 'Select a location.'};
                    return false;
                }

                this.$router.push({
                    name: submodule,
                });
            },
            getSelectedLocation() {
                this.isLoadingSelectedLocation = true;

                this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                    this.isLoadingSelectedLocation = false;
                    this.selectedLocation = data;
                });
            },
            previous() {
                this.$router.push({
                    name: 'ReturnModuleSelect',
                });
            },
            getMerchantAccounts() {
                this.isLoadingMerchantAccounts = true;

                this.$store.dispatch('merchantAccounts/getMerchantAccounts').then((data) => {
                    this.isLoadingMerchantAccounts = false;

                    if (data) {
                        for (let i in data) {
                            this.merchantAccounts.push({
                                id: data[i].RTN_ACT_ID,
                                origin: 1,
                                name: data[i].RTN_ACT_NAME
                            });
                        }
                    }
                });
            },
            getCarrierLocations() {
                this.isLoadingCarrierLocations = true;

                const url = new URL(this.$store.state.baseUrl + 'locations/get-all');
                url.searchParams.set('locationType', 'carrier');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingCarrierLocations = false;
                    this.carrierLocationOptions = data?.data ?? [];
                });
            }
        }
    }
</script>

<style scoped>
    .card-item--link.inactive {
        filter: grayscale(60%);
        opacity: 0.3;
        pointer-events: none;
    }

    .card-item--link {
        color: #1d2640;
    }

    .card-item--link:hover {
        text-decoration: none;
        color: #4a90e2;
    }
</style>
