<template>
    <div>
        <HeaderBar />

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form action="" @submit.prevent="scanManifest()">
                            <div class="form-group">
                                <label for="manifest-code" class="label-title">
                                    Scan to view manifest
                                </label>

                                <input
                                    type="text"
                                    placeholder="Scan manifest number"
                                    class="form-control lg"
                                    id="manifest-code"
                                    ref="scanManifestInput"
                                    v-model="manifestCode"
                                    :disabled="isSearchingManifest"
                                >
                            </div>
                        </form>

                        <div class="row">
                            <div class="col-12">
                                <InputFieldError attribute="manifestCode" :errors="errors"/>
                            </div>

                            <div class="col-12" v-if="isSearchingManifest">
                                Loading manifest {{ manifestCode }}...
                            </div>
                        </div>

                        <div class="scan-manisfest-container">
                            <div class="row">
                                <template v-if="isLoadingManifests">
                                    Loading manifests...
                                </template>

                                <template v-else-if="Object.keys(manifests).length === 0">
                                    There are no open manifests.
                                </template>

                                <template v-else>
                                    <div class="col-sm-6 col-lg-3" v-for="(manifest, key) in manifests" :key="key">
                                        <router-link
                                            class="text-decoration-none scan-manifest-card-link"
                                            :to="{name: 'ShippingManifest', query: {code: manifest.ID}}"
                                        >
                                            <OverviewCardItem
                                                :label="manifest.MANIFEST_CODE"
                                                :count="manifest.parcelManifestRows.length"
                                                :items="getCardItems(manifest)"
                                                :color-class="manifest.IS_READY_TO_SHIP == 1 ? manifest.SHIPMENT_REFERENCE ? 'orange' : 'red' : ''"
                                                :card-body-height="manifestCardBodyHeight"
                                                ref="manifestCardItem"
                                            />
                                        </router-link>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingStorageDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import OverviewCardItem from '@/components/_OverviewCardItem.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'OverviewCardItem': OverviewCardItem,
        },
        data() {
            return {
                errors: {},
                isLoadingManifests: false,
                isSearchingManifest: false,
                manifestCode: null,
                manifests: null,
                selectedLocation: null,
                manifestCardBodyHeight: null,
            };
        },
        mounted() {
            /* Focus on the "Scan manifest" input field on page load */
            this.$refs.scanManifestInput.focus();
        },
        created() {
            this.isLoadingManifests = true;

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;
                }

                this.getManifests();
            });
        },
        methods: {
            getManifests() {
                this.isLoadingManifests = true;

                const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/get-all');
                url.searchParams.append('locationId', this.selectedLocation);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifests = false;
                    this.manifests = data.data;

                    this.$nextTick(() => {
                        if (this.$refs.manifestCardItem) {
                            let scrollHeight = 0;

                            for (let i = 0; i < this.$refs.manifestCardItem.length; i++) {
                                if (this.$refs.manifestCardItem[i].getCardBodyHeight() > scrollHeight) {
                                    scrollHeight = this.$refs.manifestCardItem[i].getCardBodyHeight();
                                }
                            }

                            this.manifestCardBodyHeight = scrollHeight + 'px';
                        }
                    });
                });
            },
            scanManifest() {
                this.$router.push({
                    name: 'ShippingManifest',
                    query: {code: this.manifestCode}
                });
            },
            getCardItems(manifest) {
                const items = [
                    {label: 'Manifest ID', value: manifest.ID},
                    {label: 'Destination', value: manifest.carrierDestinationLocation?.NAME ?? '-'}
                ];

                if (manifest.SHIPMENT_REFERENCE) {
                    items.push({label: 'Shipment', value: manifest.SHIPMENT_REFERENCE});
                }

                return items;
            }
        }
    }
</script>

<style scoped>

</style>
