<template>
    <div>
        <HeaderBar title="Location"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="LocationMerchantSettings" activeItem="Update"></SettingsMenu>

                <div class="col-10">
                    <template v-if="isLoading">
                        Loading location merchant settings...
                    </template>

                    <template v-else>
                        <div class="col-12">
                            <InputFieldError attribute="global" :errors="errors"/>
                        </div>

                        <div v-if="isSaving">
                            Saving location merchant settings...
                        </div>

                        <template v-else-if="Object.keys($store.state.locations.locations).length > 0">
                            <div class="my-3" v-for="(location, locationKey) in $store.state.locations.locations" :key="locationKey">
                                <a v-b-toggle="'location-account-access--' + locationKey" class="foldout-link">
                                    <h1 class="foldout-title">
                                        {{ location.NAME }}
                                    </h1>
                                </a>

                                <b-collapse :id="'location-account-access--' + locationKey">
                                    <table class="options-table">
                                        <tr>
                                            <th></th>

                                            <th v-if="inboundAccess.receiveToManifestAccess">
                                                Manifest flow
                                            </th>

                                            <th v-if="inboundAccess.receiveAndGradeAccess">
                                                Receive & Grade flow
                                            </th>
                                        </tr>

                                        <tr v-for="(returnBirdMerchant, returnBirdMerchantKey) in merchantAccounts.returnBird" :key="'1_' + returnBirdMerchantKey">
                                            <td>
                                                {{ returnBirdMerchant.RTN_ACT_NAME }}
                                            </td>

                                            <td v-if="inboundAccess.receiveToManifestAccess">
                                                <model-list-select
                                                        :list="filteredSortingFlows"
                                                        option-value="value"
                                                        option-text="label"
                                                        v-model="selectedLocationMerchantSettings[locationKey][1][returnBirdMerchant.RTN_ACT_ID]['SORTING_FLOW']"
                                                        placeholder="Select sorting flow"
                                                        class="sorting-flow-dropdown"
                                                        required
                                                >
                                                </model-list-select>
                                            </td>

                                            <td v-if="inboundAccess.receiveAndGradeAccess">
                                                <model-list-select
                                                        :list="filteredReceiveAndGradeFlows"
                                                        option-value="value"
                                                        option-text="label"
                                                        v-model="selectedLocationMerchantSettings[locationKey][1][returnBirdMerchant.RTN_ACT_ID]['RECEIVE_AND_GRADE_FLOW']"
                                                        placeholder="Select flow"
                                                        class="sorting-flow-dropdown"
                                                        required
                                                >
                                                </model-list-select>
                                            </td>

                                            <td v-if="!inboundAccess.receiveToManifestAccess && !inboundAccess.receiveAndGradeAccess">
                                                No location merchant settings available.
                                            </td>
                                        </tr>
                                    </table>
                                </b-collapse>
                            </div>
                        </template>

                        <template v-else>
                            There are no locations set up.
                        </template>

                        <button type="button" name="button" class="btn md btn-primary mt-3" @click="save()" v-if="!isLoading && !isSaving">
                            Save
                        </button>
                    </template>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';
    import {BCollapse, VBToggle} from 'bootstrap-vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'SettingsMenu': SettingsMenu,
            'InputFieldError': InputFieldError,
            'b-collapse': BCollapse,
            'ModelListSelect': ModelListSelect,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                isLoading: false,
                isSaving: false,
                errors: {},
                merchantAccounts: {
                    returnBird: [],
                },
                locationMerchantSettings: [],
                selectedLocationMerchantSettings: {},
                inboundAccess: {
                    receiveAndGradeAccess: 0,
                    receiveToManifestAccess: 0
                },
            };
        },
        created() {
            this.isLoading = true;

            this.getAccountAccess();

            const availableReturnBirdAccountsUrl = new URL(this.$store.state.baseUrl + 'return-manifests/get-available-return-bird-accounts');
            const availableReturnBirdAccountsPromise = this.$http.get(availableReturnBirdAccountsUrl.href);

            const locationMerchantSettingsUrl = new URL(this.$store.state.baseUrl + 'location-merchant-settings/get-all');
            const locationMerchantSettingsPromise = this.$http.get(locationMerchantSettingsUrl.href);

            let locationsPromise = this.$store.dispatch('locations/getAll');
            let sortingFlowPromise = this.$store.dispatch('returnManifests/getSortingFlows');
            let receiveAndGradeFlowsPromise = this.$store.dispatch('locationMerchantSettings/getReceiveAndGradeFlows');

            Promise.all([availableReturnBirdAccountsPromise, locationMerchantSettingsPromise, locationsPromise, sortingFlowPromise, receiveAndGradeFlowsPromise]).then((data) => {
                this.merchantAccounts.returnBird = data[0].data.data;
                this.locationMerchantSettings = data[1].data.data;

                /*
                 * Populate the selectedLocationMerchantSettings as following:
                 * selectedLocationMerchantSettings[LOCATION_ID][MERCHANT_ACCOUNT_ORIGIN][MERCHANT_ACCOUNT_ID][SORTING_FLOW]
                 * selectedLocationMerchantSettings[LOCATION_ID][MERCHANT_ACCOUNT_ORIGIN][MERCHANT_ACCOUNT_ID][RECEIVE_AND_GRADE_FLOW]
                 *
                 * Below is for Returnbird accounts (MERCHANT_ACCOUNT_ORIGIN == 1).
                 */
                _.forOwn(this.merchantAccounts.returnBird, (returnBirdAccount) => {
                    _.forOwn(this.$store.state.locations.locations, (location, locationKey) => {
                        const existingSettings = this.locationMerchantSettings.filter(settings => {
                            return settings.MERCHANT_ACCOUNT_ORIGIN === '1' && settings.MERCHANT_ACCOUNT_ID === returnBirdAccount.RTN_ACT_ID && settings.LOCATION_ID === locationKey;
                        });

                        if (!this.selectedLocationMerchantSettings[locationKey]) {
                            this.$set(this.selectedLocationMerchantSettings, locationKey, {});
                        }

                        if (!this.selectedLocationMerchantSettings[locationKey][1]) {
                            this.$set(this.selectedLocationMerchantSettings[locationKey], 1, {});
                        }

                        if (!this.selectedLocationMerchantSettings[locationKey][1][returnBirdAccount.RTN_ACT_ID]) {
                            this.$set(this.selectedLocationMerchantSettings[locationKey][1], returnBirdAccount.RTN_ACT_ID, {});
                        }

                        const sortingFlow = existingSettings.length > 0 && existingSettings[0].SORTING_FLOW !== null ? existingSettings[0].SORTING_FLOW : '1';
                        this.$set(this.selectedLocationMerchantSettings[locationKey][1][returnBirdAccount.RTN_ACT_ID], 'SORTING_FLOW', sortingFlow);

                        const receiveAndGradeFlow = existingSettings.length > 0 && existingSettings[0].RECEIVE_AND_GRADE_FLOW !== null ? existingSettings[0].RECEIVE_AND_GRADE_FLOW : '1';
                        this.$set(this.selectedLocationMerchantSettings[locationKey][1][returnBirdAccount.RTN_ACT_ID], 'RECEIVE_AND_GRADE_FLOW', receiveAndGradeFlow);
                    });
                });

                this.isLoading = false;
            });
        },
        methods: {
            save() {
                this.errors = {};
                this.isSaving = true;

                let url = new URL(this.$store.state.baseUrl + 'location-merchant-settings/save-all');
                let params = new URLSearchParams();

                _.forOwn(this.selectedLocationMerchantSettings, (location, locationKey) => {
                    _.forOwn(location, (account, accountOrigin) => {
                        _.forOwn(account, (setting, accountId) => {
                            _.forOwn(setting, (value, key) => {
                                if (value !== null) {
                                    params.append('LocationMerchantSettings[' + locationKey + '][' + accountOrigin + '][' + accountId + '][' + key + ']', value);
                                }
                            });
                        });
                    });
                });

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    }
                });
            },
            getAccountAccess() {
                this.$store.dispatch('modules/getAvailableModules').then((data) => {
                    if (data) {
                        this.inboundAccess.receiveAndGradeAccess = data?.return?.inbound?.submodules?.['receive-return'] !== undefined;
                        this.inboundAccess.receiveToManifestAccess = data?.return?.inbound?.submodules?.['sort-to-manifest'] !== undefined || data?.return?.inbound?.submodules?.['receive-to-manifest'] !== undefined;
                    }
                });
            },
        },
        computed: {
            filteredSortingFlows() {
                let result = [];

                _.forOwn(this.$store.state.returnManifests.sortingFlows, (value, key) => {
                    result.push({value: key, label: value});
                });

                return result;
            },
            filteredReceiveAndGradeFlows() {
                let result = [];

                _.forOwn(this.$store.state.locationMerchantSettings.receiveAndGradeFlows, (value, key) => {
                    result.push({value: key, label: value});
                });

                return result;
            }
        }
    }
</script>

<style scoped>
    .foldout-link {
        text-decoration: none;
        cursor: pointer;
    }

    .foldout-title {
        font-size: 22px;
    }

    .sorting-flow-dropdown {
        width: 200px !important;
        display: inline-block !important;
    }

    .options-table td {
        padding: 10px 0;
    }

    .options-table td:not(:last-of-type) {
        padding-right: 50px;
    }
</style>
